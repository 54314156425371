// ----------------------------------------------------------------------

import { Components } from "@mui/material";
import { CustomTheme } from "../CustomTheme";

export default function Autocomplete(theme: CustomTheme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    } as Components['MuiAutocomplete'],
  };
}
