import { Alert, Box, Paper, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SIGN_IN } from "../../common/paths";
import { useAuth } from "../../hooks/useAuth";
import { LoadingButton } from '@mui/lab';
import { useTranslation } from "react-i18next";
import { AIRLINE, CLIENT_CODE } from "../../common/constants";

function SignIn() {
    const [passwordChangeSession, setPasswordChangeSession] = useState<any>();
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [error, setError] = useState<string>();
    const { clientCode, airline } = useParams();
    const rootPath = `/${clientCode || CLIENT_CODE}/${airline || AIRLINE}`;

    const executeSignIn = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setError(undefined);
        const data = new FormData(event.currentTarget);
        const username = data.get('username')?.toString();
        const password = data.get('password')?.toString();
        if (username && password) {
            const result = await auth.signIn(username, password);
            
            if (result.success) {
                let returnPath = location.state?.pathname;
                if (!returnPath || returnPath === `/${SIGN_IN}`) returnPath = rootPath;
                console.info('successfully logged in... navigating to:', returnPath);
                navigate(returnPath, { replace: true });
            } else if (result.passwordChangeRequired) {
                setPasswordChangeSession(result.session);
            } if(result.insufficientPermissions) {
                setError(result.message);
                await auth.signOut();
            }else {
                setError(result.message);
            }
        }
    }, [location, navigate, auth, rootPath]);

    const executePasswordChange = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setError(undefined);
        const data = new FormData(event.currentTarget);
        const password = data.get('password')?.toString();
        const requiredAttributes = passwordChangeSession.challengeParam?.requiredAttributes?.reduce((acc: {}, attr: string) => {
            const value = data.get(attr)?.toString();
            return { ...acc, [attr]: value };      
        }, {});
        const passwordConfirmation = data.get('password-confirm')?.toString();
        if (password !== passwordConfirmation) {
            setError('Passwords don\'t match!');
        } else if (password) {
            const result = await auth.setNewPassword(passwordChangeSession, password, requiredAttributes);
            if (result.success) {
                let returnPath = location.state?.pathname;
                if (!returnPath || returnPath === `/${SIGN_IN}`) returnPath = rootPath;
                console.info('successfully logged in... navigating to:', returnPath);
                navigate(returnPath, { replace: true });
            } else {
                setError(result.message);
            }
        }
    }, [location, passwordChangeSession, navigate, auth, rootPath]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Paper sx={{ p: 3, m: 3, width: '400px' }} elevation={3}>
                {passwordChangeSession ?
                    <>
                        <Typography component="h1" variant="h5">
                            {t('Complete Profile')}
                        </Typography>
                        <Box component="form" autoComplete="off" onSubmit={executePasswordChange} sx={{ mt: 1 }}>
                            {passwordChangeSession.challengeParam?.requiredAttributes?.map((id: string) => (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name={id}
                                    label={t(`required_attributes_${id}`)}
                                    id={id}
                                    autoComplete={id}
                                />
                            ))}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('New Password')}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password-confirm"
                                label={t('Confirm Password')}
                                type="password"
                                id="password-confirm"
                                autoComplete="new-password"
                            />
                            {error ? <Alert severity="error">{error}</Alert> : null}
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                                loading={auth.isLoading}
                            >
                                {t('Submit')}
                            </LoadingButton>
                        </Box>
                    </> :
                    <>
                        <Typography component="h1" variant="h5">
                            {t('Sign In')}
                        </Typography>
                        <Box component="form" onSubmit={executeSignIn} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label={t('Username')}
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('Password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {error ? <Alert severity="error">{error}</Alert> : null}
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3 }}
                                loading={auth.isLoading}
                            >
                                {t('Sign In')}
                            </LoadingButton>
                        </Box>
                    </>
                }
            </Paper>
        </Box>
    );
};

export default SignIn;