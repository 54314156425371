
import { useCallback, useMemo, useState } from 'react';
import { API } from 'aws-amplify';
import { DataGridPremium, GridColDef, GridRowParams, GridRowModesModel, GridRowModes, GridEventListener, GridCallbackDetails, GridRowEditStopReasons, GridRowModel, useGridApiRef, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Paper, MenuItem, Checkbox, OutlinedInput, ListItemText, Select } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../../icons/checkmark.svg';
import StyledBox from '../../../common/Components/StyledBox'
import BoxHeader from '../../../common/Components/BoxHeader';
import { CatererFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { CatererEntry, CateringRouteLeg, CateringRouteUpdateLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { toast } from "react-toastify";

export interface ICatererContainer {
    currentLeg : CateringRouteLeg;
    catererData : Array<CatererFields & DynamoDbObject>;
    serviceTypes : Array<ServiceTypeFields & DynamoDbObject>;
}

export function CatererContainer(props : ICatererContainer) {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<CatererEntry | undefined>(undefined);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const apiRef = useGridApiRef();

    const serviceTypeList = useMemo(() => {
        let result : any[] = [];

        if(props.currentLeg?.Al !== undefined) {
            // Build unique list of service types for each airline
            let list = new Set(props.serviceTypes.filter(t => { return t.RK.split('#')[0] === props.currentLeg.Al}).map(t => t.Type === 'SSR' ? t.Code : t.Type));
            result = result.concat(...Array.from(list).map(o => { return { label: o, value: o, airline: props.currentLeg.Al }}).sort((a,b) => (a.label ?? '') < (b.label ?? '') ? -1 : 1));
        }
        
        return result;
    }, [props.serviceTypes, props.currentLeg])

    const data = useMemo(() => {
        return props.currentLeg.Caterer?.map((o, i) => { return { ...o, id: i}}) ?? [];
    }, [props.currentLeg.Caterer]);

    const catererList = useMemo(() => {
        return props.catererData.map(o => { return { label: o.Name, value: o.RK } });
    }, [props.catererData])

    const preProcessEditCellProps = async (params: GridPreProcessEditCellProps) => {
        const errorMessage = params.props.value === undefined || params.props.value === '';
        return { ...params.props, error: errorMessage };
    };

    const columns: GridColDef[] = [
        { field: 'CatererId', headerName: 'Caterer', minWidth: 150, type: 'singleSelect', valueOptions: catererList, editable: true, preProcessEditCellProps },
        { field: 'LoadingTypes', headerName: 'Services', minWidth: 250,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {serviceTypeList.filter(o => {
                            return o.airline === props.currentLeg.Al
                        }).map((serviceType) => (
                            <MenuItem key={serviceType.value} value={serviceType.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list : string[] = [].concat(params.row.LoadingTypes) ?? []; //params.row.LoadingTypes;
                                    const idx = list.indexOf(serviceType.value);

                                    if(idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    } else {
                                        list.push(serviceType.value);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={serviceType.value} />
                            </MenuItem>
                        ))}
                        </Select>
                )
            }, editable: true },
        { field: 'actions', type: 'actions', headerName: '', renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row as CatererEntry)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
    };

    const processRowUpdate = (row: GridRowModel) => {
        if(row.CatererId === undefined || row.CatererId === '') {
            toast.error('Please select a caterer and try again');
            return row;
        }

        if(row.CatererId !== undefined && row.CatererId !== '' && data.some(o => o.id !== (row as any).id && o.CatererId === row.CatererId)) {
            toast.error('Caterer already exists, please select another caterer.');
            return row;
        }

        const updatedRow = { ...row, isNew: false, IsLoading: true };
        // if(props.updateAction !== undefined) {
        //     props.updateAction(row).then(() => {
        //         apiRef.current.updateRows([{ id: row.EntryId, IsLoading: false }]);
        //     });
        // } 

        const item = {...row} as CatererEntry;
        delete (item as any).isNew;
        delete (item as any).id;

        const update : CateringRouteUpdateLeg = {
            SetCaterer: [item]
        }

        updateLeg(update).then((success) => {
            apiRef.current.updateRows([{ id: row.id, IsLoading: false, isNew: false }]);
            const updateItem = {...row};
            updateItem.isNew = false;
            props.currentLeg.Caterer = [...data.filter((o : any) => o.id !== row.id), updateItem as CatererEntry];
            if(success) {
                toast.success(`Caterer saved successfully.`);
            }
            else
                toast.error('An error occurred while deleting caterer');
        });
        
        return updatedRow;
    };
    
    const handleSaveClick = (item : CatererEntry) => () => {
        const id = (item as any).id;
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        if(id === undefined)
            return;

        const rowId = parseInt(id);

        if(rowId < 0 || rowId > (props.currentLeg.Caterer?.length ?? 0))
            return;

        const gridRow = apiRef.current.getRow(id);

        if(gridRow !== undefined && gridRow.isNew === true) {
            apiRef.current.updateRows([{ id: id, _action: 'delete' }]);

            const rowList = data.filter((o : any) => o.id !== id); 
            props.currentLeg.Caterer = rowList;
        } else {
            setRowModesModel({
                ...rowModesModel,
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
              });
        }
        // const editedRow = props.currentLeg!.Caterer![rowId];
        // if (editedRow !== undefined && (editedRow as any).isNew) {
        //     const rowList = props.currentLeg.Caterer!.filter((o : any) => o !== editedRow); 
        //     props.currentLeg.Caterer = rowList;
        // } 
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: (deleteItem as any).id, IsLoading: true }]);
            
            if(((deleteItem as any).isNew ?? false) === true) {
                apiRef.current.updateRows([{ id: (deleteItem as any).id, _action: 'delete' }]);
                const rowList = data.filter((o : any) => o.id !== (deleteItem as any).id); 
                props.currentLeg.Caterer = rowList;
                return;
            }
        
            const update : CateringRouteUpdateLeg = {
                RemoveCaterer: [deleteItem.CatererId]
            }

            updateLeg(update).then((success) => {
                apiRef.current.updateRows([{ id: (deleteItem as any).id, IsLoading: false }]);

                if(success) {
                    toast.success(`Caterer deleted successfully.`);
                    apiRef.current.updateRows([{ id: (deleteItem as any).id, _action: 'delete' }]);
                    const rowList = data.filter((o : any) => o.id !== (deleteItem as any).id); 
                    props.currentLeg.Caterer = rowList;
                }
                else
                    toast.error('An error occurred while deleting caterer');
            });
        }
    };

    const updateLeg = useCallback(async (item: CateringRouteLeg) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            // await API.put('api', `/api/cateringroutes/${editData.PK.replaceAll('#', '/')}/byroute/${editData.RK.split('#')[1]}`, init);
            await API.patch('api', `/api/cateringroutes/leg/${props.currentLeg.ClientCode}/${props.currentLeg.Al}/${props.currentLeg.Nr}/${props.currentLeg.FlightDate}/${props.currentLeg.Dep}-${props.currentLeg.Dest}`, init);
            return true;
        } catch (e) {
            console.error(e);
            toast.error(`An error occurred while saving caterer.`);
            return false;
        }
    }, [props.currentLeg]);

    const confirmDeletion = (item : CatererEntry) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const createItem = () => {
        const id = (props.currentLeg.Caterer?.length ?? 0).toString();
        const item : CatererEntry = { CatererId: '', LoadingTypes: [...serviceTypeList.map(o => o.value)], ServiceName: '', ServiceTypes: []};
        const row = { ...item, id: id, isNew: true };
        
        
        props.currentLeg.Caterer = [...props.currentLeg.Caterer!, row];
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'CatererId' },
        }));
    };

    return (
        <Paper sx={{ p: 2, minHeight:'100px' }} elevation={3}>
            <BoxHeader title='Caterer'>
                <Button variant="contained" onClick={() => createItem()}>Create Caterer</Button>
            </BoxHeader>
            <StyledBox>
            <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={data}
            columns={columns}
            // getRowId={(itm) => itm.EntryId}
            editMode="row"
            isCellEditable={(params) => !((params.row.isNew ?? false) === false && params.field === 'CatererId')}
            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
            loading={loading}
            // error={props.error}
            // isCellEditable={(params) => (params.row.Type ?? 0) === BobFreshFoodType.fixed && params.colDef.field === 'Extra' ? false : true }
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter

            rowModesModel={rowModesModel}
            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
        />
        </StyledBox>
        <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
            <DialogTitle id="alert-dialog-title">
                {"Delete Caterer?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Do you want to delete this item? You can't undo this action.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
            <Button onClick={handleDeleteItem} color="error">Delete</Button>
            </DialogActions>
      </Dialog>
        </Paper>
    )
}

export default CatererContainer;