import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Box, SvgIcon, IconButton, CircularProgress } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { DynamoDbObject } from '@aviation/catering-common';
import { CatererFields, UserMembership } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';

export interface ISupplierUserListProps {
    open: boolean;
    parent: CatererFields & DynamoDbObject
    cancelAction: () => void;
}

function rowId(item : any) {
    return `${item.PK}.${item.RK}`;
}

function SupplierUserList(props : ISupplierUserListProps) {
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    
    const rows = useMemo(() => {
        if (props.parent === undefined || props.parent?.Users === undefined)
            return [];

        const result = props.parent.Users.map((entry: any) => {
            return {
                ...entry,
                id: rowId(entry),
                DisableNotification: entry.DisableNotification ?? false,
            }
        });
        return result;
        // eslint-disable-next-line
    }, [props.parent, props.parent.Users])

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            // const rowList = props.parent.Users!.filter((o : any) => o.EntryId !== id) 
            // props.parent.Entries = rowList;
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [props.parent]);

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as UserMembership & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to user membership saved successfully.`);
            else
                toast.error(`An error occurred while saving user membership`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent]);

    const updateItem = async(item : UserMembership & DynamoDbObject) => {
        let payload = item;

        // Ensure to remove unneccessary fields
        delete (payload as any).id;
        payload.CatererName = props.parent.Name;
        
        const init = {
            body: item,
            headers: {}
        };

        try {
            const response = await API.put('api', `/api/masterdata/user/membership`, init) as UserMembership;
            return response;
        } catch(e) {
            console.error(e);
            return undefined;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Username', headerName: 'User', minWidth: 200, type: 'string', editable: false },
        { field: 'DisableNotification', headerName: 'Disable Mail', minWidth: 100, type: 'boolean', editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isInEditMode && !isLoading)
                return []
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Disable notification'}>
                </BoxHeader>
            </Box>
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => true}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
        </Drawer>
    )
}

export default SupplierUserList