import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { CatererFields, CatererPerLegFields, AirlineFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import {toast} from "react-toastify";
import { ulid } from 'ulidx';
import CatererPerLegCreateDialog from './CatererPerLegCreateDialog';

function CatererPerLeg() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<CatererPerLegFields & DynamoDbObject>>(`/api/masterdata/catererperleg/${clientCode}`);
    
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { data: catererData = [] } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer/${clientCode}`); //TODO: handle status
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`);
    const { data: serviceTypeData = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<CatererPerLegFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const rowId = (row : any) : string => {
        return `${row.PK}#${row.RK}`;
    }
    
    const rows = useMemo(() => {
        return data.map(o => { 
            const stations = o.RK.split('#')[1].split('-');
            return {...o, 
                id: rowId(o), 
                Al: o.Al ?? o.RK.split('#')[0],
                Dep: o.Dep ?? stations[0],
                Dest: o.Dest ?? stations[1]
            }
        })
    }, [data]);

    const catererList = useMemo(() => {
        return catererData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [catererData])

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const serviceTypeList = useMemo(() => {
        let result : any[] = [];

        if(airlineData !== undefined) {
            // Build unique list of service types for each airline
            airlineData.forEach(a => { 
                // const list = new Set(serviceTypeData.filter(t => { return t.RK.split('#')[0] === a.RK}).map(t => t.Code));
                const list = new Set(serviceTypeData.filter(t => { return t.RK.split('#')[0] === a.RK}).map(t => t.Type === 'CREW' ? t.Type : t.Code));
                result = result.concat(...Array.from(list).map(o => { return { label: o, value: o, airline: a.RK }}).sort((a,b) => (a.label ?? '') < (b.label ?? '') ? -1 : 1));
            });
        }
        
        return result;
    }, [serviceTypeData, airlineData])

    const handleChange = (event: SelectChangeEvent) => {
        const {
          target: { value },
        } = event;
        
      };

    const columns: GridColDef[] = [
        { field: 'Al', headerName: 'Airline', minWidth: 150, type: 'singleSelect', valueOptions: airlineList },
        { field: 'Dep', headerName: 'Departure', minWidth: 100, editable: false },
        { field: 'Dest', headerName: 'Destination', minWidth: 100, editable: false },
        // { field: 'PK', headerName: 'Leg', minWidth: 100, valueGetter: (params) => params.row.RK.split('#')[1] },
        { field: 'CatererId', headerName: 'Caterer', minWidth: 150, editable: true, 
            type: 'singleSelect', valueOptions: catererList },
        // { field: 'LoadingTypes', headerName: 'Services', minWidth: 250, valueGetter: (params) => params.row.LoadingTypes.join(', ') },
        { field: 'LoadingTypes', headerName: 'Services', minWidth: 250,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {serviceTypeList.filter(o => {
                            return o.airline === params.row.Al
                        }).map((serviceType) => (
                            <MenuItem key={serviceType.value} value={serviceType.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list : string[] = [].concat(params.row.LoadingTypes) ?? []; //params.row.LoadingTypes;
                                    const idx = list.indexOf(serviceType.value);

                                    if(idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    } else {
                                        list.push(serviceType.value);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={serviceType.value} />
                            </MenuItem>
                        ))}
                        </Select>
                )
            }, editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as CatererPerLegFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to item saved successfully.`);
            else
                toast.error(`An error occurred while saving item`);
        })
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };
    
    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                const keys = deleteItem.RK.split('#');
                await API.del('api', `/api/masterdata/catererperleg/${clientCode}/${keys[0]}/${keys[1]}/${keys[2]}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Item deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting an item`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async(item : CatererPerLegFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        const id = rowId(item);

        try {
            const keys = item.RK.split('#');
            await API.put('api', `/api/masterdata/catererperleg/${clientCode}/${keys[0]}/${keys[1]}/${keys[2]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (airline : string, dep : string, dest : string, catererId : string, loadingTypes : string[]) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : CatererPerLegFields & DynamoDbObject = {
            LoadingTypes: loadingTypes,
            CatererId: catererId,
            Dep: dep,
            Dest: dest,
            Al: airline,
            PK: `${clientCode}#CatererPerLeg`,
            RK: `${airline}#${dep}-${dest}#${ulid().toString()}`,
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
        (item as any).airline = airline;
       
        if(await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Airport added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating airport`);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Caterer Per Leg')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            isCellEditable={(params) => true }
                            
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    { serviceTypeList && (<CatererPerLegCreateDialog catererList={catererList} serviceTypeList={serviceTypeList} airlineList={airlineList} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} open={showCreateDialog} />)}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Item?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this caterer assignment? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default CatererPerLeg;