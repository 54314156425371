import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import NavigationItemGroup from './NavigationItemGroup';
import { getNavigationItemGroups, INavigationGroup } from './NavigationItems';
import { useAuth } from '../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { AIRLINE, CLIENT_CODE } from '../common/constants';

export interface INavigationProps {
    drawerOpen: boolean;
    handleDrawerToggle?: () => void;
    drawerWidth: number;
}

function Navigation(props: INavigationProps) {
    const { drawerOpen, handleDrawerToggle, drawerWidth } = props;
    const auth = useAuth();
    const { t } = useTranslation();
    const { clientCode, airline } = useParams();
    const rootPath = `/${clientCode || CLIENT_CODE}/${airline || AIRLINE}`;
    const navigationItemGroups = getNavigationItemGroups(t, rootPath);
    
    const drawer = (
        <>
            <Toolbar />
            <Box sx={{ overflow: 'auto' }} component="nav">
                <List>
                    {navigationItemGroups.filter(o => (o.administration && auth.isAdministrator) || ((auth.isUser || auth.isAdministrator) && !o.administration)).map((item : INavigationGroup) => <NavigationItemGroup key={item.id} {...item} />)}
                </List>
            </Box>
        </>
    );

    if(!auth.isAuthenticated) return null;

    return (
        <>
            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </>
    );
}

export default Navigation;