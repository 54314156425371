import { Box, Paper, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export interface IBoxHeaderProps {
    title: string;
    subtitle?: string;
}

function BoxHeader({ children, title, subtitle}: PropsWithChildren<IBoxHeaderProps>) {
    return (
        <Paper sx={{ paddingBottom: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={0}>
            <Box sx={{ flexGrow: 1, mr: { xs: 0, md: (children ? 2 : 0) }, mb: { xs: (children ? 2 : 0), md: 0 } }}>
                <Typography variant="subtitle1" component="h2">
                    {title}
                </Typography>
                { subtitle ? 
                    <Typography variant="caption">
                        { subtitle }
                    </Typography> : null
                }
            </Box>
            {children ? <Box sx={{ flexGrow: 0 }}>
                {children}
            </Box> : null}
        </Paper>
    );
}

export default BoxHeader;