import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { CircularProgress, IconButton, SvgIcon } from '@mui/material';
import { Status, useFetch } from '../../hooks/useFetch';
import { useCallback, useMemo } from 'react';
import { DynamoDbObject } from '@aviation/catering-common';
import { AuditLogEntryFields } from '@aviation/audit-service-sdk';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import dayjs from 'dayjs'
export interface IDateValueProps {
    date?: Date;
}

export interface ITimeValueProps {
    date?: Date;
}

export interface IAuditLogGridProps {
    dateFrom?: string;
    dateTo?: string;
    tabIndex: number;
}

function AuditLogGrid(props : IAuditLogGridProps) {
    const apiRef = useGridApiRef();
    const { status, data = [] } = useFetch<Array<AuditLogEntryFields & DynamoDbObject>>(`/api/audit/from/${props.dateFrom}/to/${props.dateTo}`, (response) => response);

    const onDownload = useCallback(async (row : any) => {
        apiRef.current.updateRows([{ id: row.id, IsLoading: true }]);

        const init = {
            body: row,
            headers: {}
        };

        try {
            const response = await API.post('api', `/api/audit/download`, init);
            window.location = response;
        } catch (error: any) {
            console.log(error.message, error)
            toast.error('An error occurred while downloading file.')
        } finally {
            apiRef.current.updateRows([{ id: row.id, IsLoading: false }]);
        }
    }, [apiRef])

    const columns: GridColDef[] = [
        { field: 'Date', headerName: 'Date', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => dayjs(params.value).format('DD.MM.YYYY') },
        { field: 'Time', headerName: 'Time', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => dayjs(params.value).format('HH:mm') },
        { field: 'Group', headerName: 'Event', minWidth: 150 },
        { field: 'Description', headerName: 'Description', minWidth: 400 },
        { field: 'Username', headerName: 'Modified by', minWidth: 400 },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const hasFile = row.HasFile ?? false;
            const isLoading = row.IsLoading ?? false;

            if(hasFile && !isLoading) 
                return (
                    <IconButton color="primary" onClick={(e) => onDownload(row)}>
                        <SvgIcon component={DownloadIcon} inheritViewBox />
                    </IconButton>
                )
            else if(!isLoading)
                return (
                    <></>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const filterBy =(tabIndex : number, item : (AuditLogEntryFields & DynamoDbObject)) : Boolean => {
        switch(tabIndex) {
            case 0: // Meal Order 
                return item.Group === 'Meal Order';
            
            case 1: 
                return item.Group === 'BoB Forecast';

            case 2:
                return item.Group === 'Route';

            default:
                return (item.Group !== 'Meal Order' && item.Group !== 'BoB Forecast' && item.Group !== 'Route');
        }

    };

    const aggregatedData = useMemo(() => {
        return data.filter(o => filterBy(props.tabIndex, o)).map(o => {
            return {
                id: `${o.PK}#${o.RK}`,
                Date: o.Timestamp,
                Time: o.Timestamp,
                HasFile: o.Content?.Filename !== undefined,
                Filename: o.Content?.Filename,
                ...o
            }
        }).sort((a,b) => (a.Timestamp ?? Date()) < (b.Timestamp ?? Date()) ? 1 : -1);
    }, [data, props.tabIndex])

    return (

        <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={aggregatedData}
            columns={columns}
            // pageSize={10}
            // rowsPerPageOptions={[10]}
            pagination
            getRowClassName={(params) => { return params.row.Leg === true ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
            loading={status === Status.Fetching || status === Status.Idle}
            // error={error}
            groupingColDef={{
                headerName: ' ',
                valueGetter: (params: GridValueGetterParams) => '',
                width: 50,
                resizable: false,
                hideable: false,                
            }}
        />
    );
}

export default AuditLogGrid;