import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SvgIcon } from '@mui/material';
import { ReactComponent as TuiLogo } from '../tuilogo.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import SettingsMenu from './SettingsMenu';
import { useAuth } from '../hooks/useAuth';

export interface IHeaderProps {
  title: string,
  handleDrawerToggle?: () => void;
}

function Header(props: IHeaderProps) {
  const { handleDrawerToggle, title } = props;
  const auth = useAuth();

  return (
    <AppBar
      position="fixed"
    >
      <Toolbar>
        <SvgIcon component={TuiLogo} inheritViewBox sx={{ display: { xs: 'none', md: 'flex' }, mr: 2, mt: -0.5, width: 60, height: 30 }} />
        <Typography
          noWrap
          component="h1"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            mt: 0.8,
            color: 'inherit',
            textDecoration: 'none',
            flexGrow: 1,
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Box sx={{ flexGrow: 1, ml: -1, display: { xs: 'flex', md: 'none' } }}>
          {auth.isAuthenticated ?
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <SvgIcon component={MenuIcon} inheritViewBox />
            </IconButton> : null}
        </Box>
        <SvgIcon component={TuiLogo} inheritViewBox sx={{ display: { xs: 'flex', md: 'none' }, mr: 2, mt: -1.1, width: 60, height: 30 }} />
        <Typography
          noWrap
          component="h1"
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            color: 'inherit',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>

        <Box sx={{ flexGrow: 0 }}>
          <SettingsMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;