import LinkBehavior from './LinkBehavior';
import { CustomTheme } from '../CustomTheme';
import { Components } from '@mui/material';

// ----------------------------------------------------------------------

export default function Link(theme: CustomTheme) {
  return {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    } as Components['MuiLink'],
  };
}
