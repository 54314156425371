import { useState, useMemo, useCallback, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Drawer, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Box, Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { RouteOverrideFields, RouteOverrideLeg } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { toast } from "react-toastify";
import { Status, useFetch } from '../../hooks/useFetch';
import BoxHeader from '../../common/Components/BoxHeader';

export interface IRouteOverrideDetailsProps {
    open: boolean;
    parent: RouteOverrideFields & DynamoDbObject;
    catererList : {label: string, value: string}[];
    cancelAction: () => void;
}

interface RowType {
    id:string,
                Dep: string,
                Dest: string,
                LoadingStation: string[],
                CatererServiceTypes: string[],
                OutstationCatererServiceTypes: string[],
                // PK: props.parent.PK,
                // RK: props.parent.RK,
                isNew: boolean
}

function RouteOverrideDetails(props: IRouteOverrideDetailsProps) {
    const apiRef = useGridApiRef();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<RouteOverrideLeg & DynamoDbObject>>(`/api/masterdata/routeoverride/${clientCode}/${props.parent.RK.split('#')[1]}/legs`);

    const [rows, setRows] = useState<Array<RowType>>([]);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);

    const rowId = (row : any) => {
        return row.RK
    }

    const serviceTypeList = useMemo(() =>  [
                { label: 'DELU', value: 'DELU'},
                { label: 'COM', value: 'COM'},
                { label: 'ECO', value: 'ECO'},
                { label: 'CREW', value: 'CREW'},
                { label: 'NOM', value: 'NOM'},
            ]
    , []);
    const possibleLoadingStations = useMemo(()=>(props.parent.Route?.split('-')?.slice(0, -1) ?? []).map(x=>({label: x, value: x})), [props.parent])

    useEffect(() => {
        if (!data) return

        const result = data.map((entry: any) => {
            return {
                id: entry.RK,
                Dep: entry.Dep,
                Dest: entry.Dest,
                LoadingStation: entry.LoadingStation?.split(',') ?? [],
                CatererServiceTypes: entry.Caterer?.[props.parent.CatererId ?? '']?.LoadingTypes ?? [],
                OutstationCatererServiceTypes: entry.Caterer?.[props.parent.OutstationCatererId ?? '']?.LoadingTypes ?? [],
                PK: entry.PK,
                RK: entry.RK,
                isNew: entry.isNew ?? false
            }
        })
        setRows(result)
        // eslint-disable-next-line
    }, [props.parent, data])

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    // const confirmDeletion = (item : any) => {
    //     setDeleteItem(item);
    //     setShowDeleteConfirmation(true);
    // };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            const rowList = rows.filter((o : any) => o.EntryId !== id) 
            setRows(rowList)
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [props.parent]);

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: true }]);
            
            // Construct BobConfigurationItem without deleted item
            // let item = {...props.parent};
            // item.Entries = props.parent.Entries!.filter(o => o.EntryId !== deleteItem.EntryId);

            // const item = {

            // }

            // if(await updateRouteOverrideLeg(item)) {
            //     apiRef.current.updateRows([{ id: deleteItem.EntryId, _action: 'delete' }]);
            //     toast.success(`BoB Forecast deleted successfully.`);
            //     props.parent.Entries = item.Entries;
            //     return true;
            // } else {
            //     apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: false }]);
            //     toast.error(`An error occurred while deleting BoB forecast`);
            // }
        }
    };

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as RouteOverrideFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to BoB forecast saved successfully.`);
            else
                toast.error(`An error occurred while saving BoB forecast`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent]);

    const updateItem = async(item : any) => {
        // let entry = {...props.parent.Entries!.find((o : any) => o.EntryId === item.EntryId) as any};

        // if(entry === undefined) {
        //     entry = {
        //         EntryId: item.EntryId,
        //         ProductCode: item.ProductCode,
        //         Count: item.Count,
        //         PercentAm: item.PercentAm,
        //         PercentPm: item.PercentPm,
        //         PercentExtra: item.PercentExtra,
        //         AircraftType: undefined,
        //         ValidFrom: item.ValidFrom,
        //         ValidTo: item.ValidTo,
        //     }
        // } 

        // entry.Type = item.Type;
        // entry.Count = item.Count;
        // entry.ProductCode = item.ProductCode;
        // entry.PercentAm = item.PercentAm;
        // entry.PercentPm = item.PercentPm;
        // entry.PercentExtra = item.PercentExtra;
        // entry.AircraftType = (item.AircraftType as string[])?.join(';');
        // entry.ValidFrom = item.ValidFrom;
        // entry.ValidTo = item.ValidTo;
        // entry.isNew = false;
        
        // props.parent.Entries = [entry!, ...props.parent.Entries!.filter((o : any) => o.EntryId !== (item as any).EntryId)];
        const caterer: {
            [CatererId: string]: {
                LoadingTypes: string[]
            }
        } = {}
        if (props.parent.CatererId)
            caterer[props.parent.CatererId] = {LoadingTypes: item.CatererServiceTypes}
        if (props.parent.OutstationCatererId)
            caterer[props.parent.OutstationCatererId] = {LoadingTypes: item.OutstationCatererServiceTypes}
        const updateItem: RouteOverrideLeg & DynamoDbObject = {
            PK: item.PK,
            RK: item.RK,
            Dep: item.Dep,
            Dest: item.Dest,
            LoadingStation: item.LoadingStation?.join(','),
            Caterer: caterer
        }
        return await updateRouteOverrideLeg(updateItem);
    }

    const updateRouteOverrideLeg = async (item : RouteOverrideLeg & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/routeoverride/${clientCode}/${props.parent.RK.split('#')[1]}/legs/${item.RK.split('#')[2]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Dep', headerName: 'Dep', minWidth: 70, editable: false, type: 'string' },
        { field: 'Dest', headerName: 'Dest', minWidth: 60, editable: false, type: 'string' },
        { field: 'LoadingStation', headerName: 'Loading Stations', minWidth: 100, editable: true, renderEditCell(params) {
            return (
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (selected as any ?? []).join(', ')}
                    //MenuProps={MenuProps}
                    >
                    {possibleLoadingStations.map((serviceType) => (
                        <MenuItem key={serviceType.value} value={serviceType.value}>
                        <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                            if(e !== undefined) {
                                const id = params.id;
                                const field = params.field;

                                const list : string[] = [].concat(params.row.LoadingStation) ?? []; //params.row.LoadingTypes;
                                const idx = list.indexOf(serviceType.value);

                                if(idx !== -1) {
                                    list.splice(idx, 1);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                } else {
                                    list.push(serviceType.value);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                }
                                
                            }
                        }}/>
                        <ListItemText primary={serviceType.value} />
                        </MenuItem>
                    ))}
                    </Select>
            )
        }},
        { field: 'CatererServiceTypes', headerName: 'Caterer', minWidth: 100, editable: true, renderEditCell(params) {
            return (
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (selected as any ?? []).join(', ')}
                    //MenuProps={MenuProps}
                    >
                    {serviceTypeList.map((serviceType) => (
                        <MenuItem key={serviceType.value} value={serviceType.value}>
                        <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                            if(e !== undefined) {
                                const id = params.id;
                                const field = params.field;

                                const list : string[] = [].concat(params.row.CatererServiceTypes) ?? []; //params.row.LoadingTypes;
                                const idx = list.indexOf(serviceType.value);

                                if(idx !== -1) {
                                    list.splice(idx, 1);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                } else {
                                    list.push(serviceType.value);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                }
                                
                            }
                        }}/>
                        <ListItemText primary={serviceType.value} />
                        </MenuItem>
                    ))}
                    </Select>
            ) }},
        { field: 'OutstationCatererServiceTypes', headerName: 'Outstation', minWidth: 100, editable: true, renderEditCell(params) {
            return (
                props.parent.OutstationCatererId ?
                <Select
                    labelId="demo-multiple-checkbox-label2"
                    id="demo-multiple-checkbox2"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag2" />}
                    renderValue={(selected) => (selected as any ?? []).join(', ')}
                    //MenuProps={MenuProps}
                    >
                    {serviceTypeList.map((serviceType) => (
                        <MenuItem key={serviceType.value} value={serviceType.value}>
                        <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                            if(e !== undefined) {
                                const id = params.id;
                                const field = params.field;

                                const list : string[] = [].concat(params.row.OutstationCatererServiceTypes) ?? []; //params.row.LoadingTypes;
                                const idx = list.indexOf(serviceType.value);

                                if(idx !== -1) {
                                    list.splice(idx, 1);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                } else {
                                    list.push(serviceType.value);
                                    apiRef.current.setEditCellValue({id, field, value: list});
                                }
                                
                            }
                        }}/>
                        <ListItemText primary={serviceType.value} />
                        </MenuItem>
                    ))}
                    </Select>
                    : undefined
            ) }},
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return undefined
                // if(row.Parent === true) return [
                //     <IconButton color="error" onClick={() => confirmDeletion(row)}>
                //         <SvgIcon component={TrashIcon} inheritViewBox />
                //     </IconButton>
                // ]
                // else
                //     return ( 
                //         <IconButton color="error" onClick={() => confirmDeletion(row)}>
                //             <SvgIcon component={TrashIcon} inheritViewBox />
                //         </IconButton>
                //     )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    // const createItem = () => {
    //     const id = ulid().toString();
    //     const row = { id: id, EntryId: id, Type: 'fixed', ProductCode: '', PercentAm: undefined, PercentPm: undefined, Count: 0, isNew: true } as BobConfigurationFixedEntry;
        
        
    //     props.parent.Entries = [...props.parent.Entries!, row];
    //     setRowModesModel((oldModel) => ({
    //         ...oldModel,
    //         [id]: { mode: GridRowModes.Edit, fieldToFocus: 'ProductCode' },
    //     }));
    // };

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Exception route'}>
                    {/* <Button variant="contained" onClick={createItem}>Create</Button> */}
                </BoxHeader>
            </Box>
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    loading={status === Status.Fetching || status === Status.Idle}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => true}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Configuration?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this BoB configuration? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default RouteOverrideDetails;