import { useState } from 'react'
import { Stack, TextField } from '@mui/material';
import { Leg } from '@aviation/catering-flightservice-sdk';
import { AirlineFields } from '@aviation/catering-masterdata-sdk';
import { CateringRouteFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import FindFlightDialogGrid from './FindFlightDialogGrid';
import dayjs, { Dayjs } from 'dayjs';


export interface ICreateRouteDialogFlightsProps {
    clientCode: string
    airlineData: Array<AirlineFields & DynamoDbObject>;
    route: CateringRouteFields & DynamoDbObject;
    validationExecuted: (item: CateringRouteFields & DynamoDbObject, valid: boolean) => void;
}

export function CreateRouteDialogFlights(props: ICreateRouteDialogFlightsProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [displayAllFlights, setDisplayAllFlights] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedFlights, setSelectedFlights] = useState<Leg[] | undefined>(undefined);
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(props.route.FlightDate) ?? dayjs(), dayjs(props.route.FlightDate).add(1, 'day') ?? dayjs().add(1, 'day')]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasFlights, setHasFlights] = useState(false);
    const [filterTerm, setFilterTerm] = useState('');

    const onFlightSelected = (list: Leg[]) => {
        setHasFlights(list.length > 0);
        setSelectedFlights(list);

        const legList = list.sort((a,b) => (a.DepDate ?? '') < (b.DepDate ?? '') ? -1 : 1).map(f => {
            const leg : CateringRouteLeg = {
                ClientCode: props.clientCode,
                FlightDate: f.FlightDate,
                DepDate: f.DepDate,
                Al: f.Al,
                Nr: f.No,
                Dep: f.Dep,
                Dest: f.Dest,
                AcType: f.AcType,
                AcReg: f.AcReg,
                AcRegDisp: f.AcRegDisp,
                FlightPurpose: f.FlightPurpose,
                HaulType: props.route.HaulType,
                LoadingStations: '',
                LoadingPlanId: props.route.LoadingPlanId,
                IsDutyFree: props.route.IsDutyFree,
                CateringRouteId: props.route.PK,
                Caterer: [{ CatererId: props.route.DefaultCatererId ?? '', LoadingTypes: [], ServiceName: '', ServiceTypes: []}]
            }
            return leg;
        });

        const item = props.route;
        item.Legs = legList;

        props.validationExecuted(item, legList.length > 0);
    }

    return (
        <Stack direction='column' spacing={2} style={{ padding: 20 }}>
            <Stack direction='row' useFlexGap flexWrap="wrap" spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                    <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                </LocalizationProvider>
                <TextField size='small' id="filterTerm" label="Filter" variant="outlined" onChange={(e) => setFilterTerm(e.target.value)} />
            </Stack>
            <FindFlightDialogGrid filterTerm={filterTerm} airlineList={displayAllFlights ? undefined : props.airlineData?.map(o => o.RK)} dateFrom={dateRange[0]?.format("YYYY-MM-DD") ?? ''} dateTo={dateRange[1]?.format("YYYY-MM-DD") ?? ''} selectionChanged={onFlightSelected} initialSelection={props.route.Legs} />
        </Stack>
    )
}

export default CreateRouteDialogFlights;