
import { TFunction } from 'i18next';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as UserBusinessIcon } from '../icons/user-business.svg';
import { ReactComponent as DeviceTvIcon } from '../icons/device-tv.svg';
// eslint-disable-next-line
import { ReactComponent as DutyFreeIcon } from '../icons/duty-free.svg';
import { ReactComponent as FactoryIcon } from '../icons/factory.svg';
// eslint-disable-next-line
import { ReactComponent as OnBoardMealsIcon } from '../icons/on-board-meals.svg';
import { ReactComponent as WorldGlobeIcon } from '../icons/world-globe.svg';
import { ReactComponent as CodeIcon } from '../icons/code.svg';
import { ReactComponent as AirportExtrasIcon } from '../icons/airport-extras.svg';
import { ReactComponent as AircraftIcon } from '../icons/aircraft.svg';
import { ReactComponent as GolfIcon } from '../icons/golf.svg';
// eslint-disable-next-line
import { AIRCRAFTS, AIRLINES, AIRPORTS, AUDIT_LOG, BOB_FORECAST, CATERING_ROUTES, CATERING_STATION_GROUPS, DASHBOARD, HAUL_TYPES, LOADING_PLANS, SUPPLIER, SERVICE_TYPES, SSR_CODES, USER, CATERER_PER_LEG, LOADING_PLAN_PER_DEST, ROUTE_OVERRIDE, AIRCRAFTGROUPS } from '../common/paths';

export interface INavigationItem {
    id: string,
    icon?: React.ElementType,
    label: string,
    children?: INavigationItem[],
    path: string,
    rootPath: string,
    administration?: boolean
}

export interface INavigationGroup {
    id: string,
    label: string,
    children: INavigationItem[],
    administration: boolean
}

export function getNavigationItemGroups(t: TFunction, rootPath: string): INavigationGroup[] {
    return [
        {
            id: 'general',
            label: t('General'),
            administration: false,
            children: [
                {
                    id: 'dashboard',
                    label: t('Dashboard'),
                    icon: DeviceTvIcon,
                    path: DASHBOARD,
                    rootPath,
                    administration: false,
                },
                {
                    id: 'catering-routes',
                    label: t('Catering Routes'),
                    icon: ShareIcon,
                    path: CATERING_ROUTES,
                    rootPath,
                    administration: false,
                },
            ],
        },
        {
            id: 'rules',
            label: t('Rules'),
            administration: true,
            children: [
                {
                    id: 'caterer-station-groups',
                    label: t('Caterer Station Groups'),
                    icon: GolfIcon,
                    path: CATERING_STATION_GROUPS,
                    rootPath,
                },
                {
                    id: 'caterer-per-leg',
                    label: t('Caterer per Leg'),
                    icon: GolfIcon,
                    path: CATERER_PER_LEG,
                    rootPath,
                },
                {
                    id: 'loading-plan-per-dest',
                    label: t('Loading Plan per Destination'),
                    icon: DutyFreeIcon,
                    path: LOADING_PLAN_PER_DEST,
                    rootPath,                    
                },
                {
                    id: 'bob-forecast',
                    label: t('BoB Forecast'),
                    icon: OnBoardMealsIcon,
                    path: BOB_FORECAST,
                    rootPath,
                },
                {
                    id: 'route-exceptions',
                    label: t('Exception Routes'),
                    icon: GolfIcon,
                    path: ROUTE_OVERRIDE,
                    rootPath,
                },
            ],
        },
        {
            id: 'administration',
            label: t('Administration'),
            administration: true,
            children: [
                {
                    id: 'user',
                    label: t('User'),
                    icon: UserBusinessIcon,
                    path: USER,
                    rootPath,
                },
                {
                    id: 'airports',
                    label: t('Airports'),
                    icon: AirportExtrasIcon,
                    path: AIRPORTS,
                    rootPath,
                },
                {
                    id: 'airlines',
                    label: t('Airlines'),
                    icon: AirportExtrasIcon,
                    path: AIRLINES,
                    rootPath,
                },
                {
                    id: 'aircrafts',
                    label: t('Aircrafts'),
                    icon: AircraftIcon,
                    path: AIRCRAFTS,
                    rootPath,
                },
                {
                    id: 'aircraftgroups',
                    label: t('Aircraft Groups'),
                    icon: AircraftIcon,
                    path: AIRCRAFTGROUPS,
                    rootPath,
                },
                {
                    id: 'supplier',
                    label: t('Supplier'),
                    icon: FactoryIcon,
                    path: SUPPLIER,
                    rootPath,
                },
                {
                    id: 'loading-plans',
                    label: t('Loading Plans'),
                    icon: DutyFreeIcon,
                    path: LOADING_PLANS,
                    rootPath,                    
                },
                {
                    id: 'haul-types',
                    label: t('Haul Types'),
                    icon: WorldGlobeIcon,
                    path: HAUL_TYPES,
                    rootPath,
                },
                {
                    id: 'service-types',
                    label: t('Service Types'),
                    icon: WorldGlobeIcon,
                    path: SERVICE_TYPES,
                    rootPath,
                },
                {
                    id: 'ssr-codes',
                    label: t('Ssr Codes'),
                    icon: WorldGlobeIcon,
                    path: SSR_CODES,
                    rootPath,
                },
            ],
        },
        {
            id: 'analysis',
            label: t('Analysis'),
            administration: true,
            children: [
                {
                    id: 'audit-log',
                    label: t('Audit Log'),
                    icon: CodeIcon,
                    path: AUDIT_LOG,
                    rootPath,
                },
            ],
        },
    ]
}