import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export interface IBobForecastCreatePackagePlanDialog {
    open : boolean;
    cancelAction : () => void;
    createAction : (name : string) => void;
}

function BobForecastCreatePackagePlanDialog(props : IBobForecastCreatePackagePlanDialog) {
    const { t } = useTranslation();
    const [ name, setName] = useState('');
    const [ nameError, setNameError] = useState(true);
    
    const hasErrors = useMemo(() => {
        return nameError;
    }, [nameError]);

    const handleName = (value : string | undefined) => {
        setName(value ?? '');
        setNameError(value === undefined || value === '');
    }   

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create BoB Package Plan</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are going to create a package plan to maintain BoB Fresh Food for each route that is assigned to this plan.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Name')}
                                    onChange={e => handleName(e.target.value)}
                                    value={name}
                                    error={nameError}
                                    helperText={nameError ? 'Please provide a package plan name' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(name) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default BobForecastCreatePackagePlanDialog;