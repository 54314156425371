import { useTranslation } from 'react-i18next';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { IconButton, SvgIcon } from '@mui/material';
import { Status, useFetch } from '../../hooks/useFetch';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CateringRouteFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { CatererFields, LoadingPlanFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { CATERING_ROUTES } from '../../common/paths';
import { AIRLINE, CLIENT_CODE } from '../../common/constants';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import WarningChip from './Components/WarningChip';
import { WARNING_ROUTEIGNORED } from "../../common/constants";

export interface IDateValueProps {
    date?: Date;
}

function DateValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short" }, }) :
                    null
            }
        </>
    )
}

export interface ITimeValueProps {
    date?: Date;
}

function TimeValue({ date }: ITimeValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }, }, }) :
                    null
            }
        </>
    )
}

export enum StatusFilter {
    Completed = 0,
    Incompleted = 1,
    Ignored = 2,
    All = 3
}

export interface ICateringRouteGridProps {
    showIncompleteRoutes: boolean;
    dateFrom?: string;
    dateTo?: string;
    statusFilter: StatusFilter;
}

function CateringRouteGrid(props : ICateringRouteGridProps) {
    const apiRef = useGridApiRef();
    const location = useLocation();
    const { clientCode, airline } = useParams();
    const rootPath = `/${clientCode || CLIENT_CODE}/${airline || AIRLINE}`;
    const navigate = useNavigate();
    
    // const { status, data = [] } = useFetch<Array<CateringRouteFields & DynamoDbObject>>(showIncompleteRoutes ? `/api/cateringroutes/routes/withWarnings` : `/api/cateringroutes/query/${clientCode}/from/${dateFrom}/to/${dateTo}`);
    const { status, data = [] } = useFetch<Array<CateringRouteFields & DynamoDbObject>>(`/api/cateringroutes/query/${clientCode}/from/${props.dateFrom}/to/${props.dateTo}`, (response) => response.Items, 0, true);

    
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { status: catererStatus, data: catererData = [], error: catererError } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer/${clientCode}`); // TODO: handle status
    const { status: loadingPlanStatus, data: loadingPlanData = [], error: loadingPlanError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${clientCode}`);
    
    const navigateTo = (row : any) => {
        let gridState = apiRef.current.exportState();
        localStorage.setItem(`GRID_${location.pathname}`, JSON.stringify(gridState));
        navigate(`${rootPath}/${CATERING_ROUTES}/${encodeURIComponent(row.Path)}`)
    }

    const columns: GridColDef[] = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            width:55,
            renderCell: ({ row }: Partial<GridRowParams>) =>
              (!row.Leg ? 
                  <IconButton color='primary' onClick={() => navigateTo(row)}>
                      <SvgIcon component={EditIcon} inheritViewBox />
                  </IconButton> : null),
          },
        // { field: 'Warnings', headerName: 'Status', minWidth: 100, flex: 1, renderCell: (params: GridRenderCellParams) => params.row.Warnings?.length || params.row.Leg ? <>{params.row.Warnings?.map((warning: string) => <Chip size='small' key={warning} label={warning} color="error" />)}</> : <Chip size='small' label={'Complete'} color="success" /> },
        { field: 'Warnings', headerName: 'Status', minWidth: 100, flex: 1, renderCell: (params: GridRenderCellParams) =>  params.row.Leg ? <></> : (<WarningChip warnings={params.row.Warnings} />)},
        { field: 'DepDate', headerName: 'Date', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => <DateValue date={new Date(params.value!)} /> },
        { field: 'Time', headerName: 'Time', minWidth: 100, valueGetter: (params: GridValueGetterParams) => getDepartureDate(params.row), renderCell: (params: GridRenderCellParams<Date>) => <TimeValue date={new Date(params.value!)} /> },
        // { field: 'Al', headerName: 'Al', minWidth: 40 },
        { field: 'FlightNumbers', headerName: 'Flight No.', minWidth: 250 },
        { field: 'Route', headerName: 'Route', minWidth: 200 },
        { field: 'AcReg', headerName: 'Aircraft', minWidth: 50 },
        { field: 'LoadingStations', headerName: 'Loading', minWidth: 100 },
        { field: 'Caterer', headerName: 'Supplier', minWidth: 120 },
        // { field: 'OutstationCaterer', headerName: 'Outstation Supplier', minWidth: 100 },
        { field: 'LoadingTypes', headerName: 'Loading Type', minWidth: 150 },
        { field: 'LoadingPlanName', headerName: 'Plan Type', minWidth: 120 },
        { field: 'IsDutyFree', headerName: 'Duty Free', minWidth: 100, type: 'boolean' },
        { field: 'HaulType', headerName: 'Level', minWidth: 50 },
    ];

    const getDepartureDate = (row : any) => {
        if(row.Legs !== undefined && row.Legs.length > 0) {
            return row.Legs[0].DepDate;
        }

        return row.DepDate;
    }

    const filterByStatus =(status : StatusFilter, item : any) : Boolean => {

        switch(status) {
            case StatusFilter.Completed: 
                return (item.Warnings?.length ?? 0) === 0;
            
            case StatusFilter.Incompleted: 
                return !item.Warnings?.some((o : string) => o === WARNING_ROUTEIGNORED) && (item.Warnings?.length ?? 0) > 0;

            case StatusFilter.Ignored:
                return item.Warnings?.some((o : string) => o === WARNING_ROUTEIGNORED) ?? false

            default:
                return true;
        }

    };

    const aggregatedData = useMemo(() => {
        if (!data || !catererData || !loadingPlanData) return [];
        let result = (data as any[]).sort((a : any, b: any) => a.DepDate < b.DepDate ? -1 : 1).filter(w => filterByStatus(props.statusFilter, w) && ((w.Legs?.length ?? 0) > 0) && ((props.showIncompleteRoutes && w.Warnings !== undefined && w.Warnings.length > 0) || !props.showIncompleteRoutes)).filter(x=>!x.RK.startsWith("LegIndex")).reduce((a, b) => [
            ...a,
            {
                ...b,
                Id: `${b.PK}#${b.RK}`,
                Path: `${b.PK}`,
                Al: b.Legs !== undefined && b.Legs.length > 0 ? b.Legs[0].Al ?? '' : '-',
                FlightNumbers: [...new Set<string>(b.Legs?.map((x: CateringRouteLeg) => `${x.Al}${x.Nr}${x.FlightPurpose === 'P' || x.FlightPurpose === 'F' ? x.FlightPurpose : ''}`) ?? [])].join(", "),
                Route: b.Route ?? '-',
                AcReg: b.Legs !== undefined && b.Legs.length > 0 ? (Array.from(new Set(b.Legs.map((o : any) => o.AcReg))).join(', ')) : '-', //b.PK.split('#')[2],
                Date: b.PK.split('#')[3],
                DepDate: b.Legs !== undefined && b.Legs.length > 0 ? (b.Legs?.sort((a : any, b : any) => a.DepDate < b.DepDate ? -1 : 1)[0].DepDate) : "-",
                LoadingPlanName: ((loadingPlanData && loadingPlanData.find((d) => d.RK.split('#')[1] === b.LoadingPlanId)?.Name) || b.LoadingPlanId),
                Caterer: ((catererData && catererData.find((d) => d.RK === b.DefaultCatererId)?.Name) || b.DefaultCatererId),
                OutstationCaterer: ((catererData && catererData.find((d) => d.RK === b.OutstationCatererId)?.Name) || b.OutstationCatererId)
            },
            ...b.Legs?.sort((a : any, b : any) => a.DepDate < b.DepDate ? -1 : 1).map((leg: any, i: number) => {
                const caterer = leg.Caterer.findLast((e: any) => !!e);
                const route = `${leg.Dep}-${leg.Dest}`;
                return {
                    Leg: true,
                    Id: `${b.PK}#${b.RK}.${route}${i}`,
                    Al: leg.Al,
                    FlightNumbers: `${leg.Al}${leg.Nr}${leg.FlightPurpose === 'P' || leg.FlightPurpose === 'F' ? leg.FlightPurpose : ''}`,
                    AcReg: b.Legs[i].AcReg,
                    PK: b.PK,
                    RK: route,
                    Route: route,
                    Date: b.Date,
                    DepDate: leg.DepDate,
                    LoadingStations: leg.LoadingStations,
                    LoadingTypes: caterer?.LoadingTypes?.join(', '),
                    IsDutyFree: b.IsDutyFree,
                    Caterer: (catererData && catererData.find((d) => d.RK === caterer?.CatererId)?.Name) || caterer?.Name
                }
            }) ?? []
        ], []).sort((a : any,b : any) => a.DepDate < b.DepDate ? -1 : 1);

        return result
    }, [data, catererData, loadingPlanData, props.showIncompleteRoutes, props.statusFilter])

    const loadGridState = useEffect(() => {
        if(aggregatedData === undefined)
            return;

        let jsonGrid = localStorage.getItem(`GRID_${location.pathname}`);
        if(jsonGrid !== undefined && jsonGrid !== null) {
            let gridState = JSON.parse(jsonGrid);

            if(gridState !== undefined) 
                apiRef.current.restoreState(gridState);
        }
    }, [aggregatedData, apiRef, location.pathname])

    return (

        <DataGridPremium
            apiRef={apiRef}
            treeData
            autoHeight
            rows={aggregatedData}
            columns={columns}
            // pageSize={10}
            // rowsPerPageOptions={[10]}
            pagination
            getRowId={(itm) => itm.Id}
            getTreeDataPath={(row) => row.Id.split('.')}
            getRowClassName={(params) => { return params.row.Leg === true ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
            loading={status === Status.Fetching || status === Status.Idle}
            // error={error}
            groupingColDef={{
                headerName: ' ',
                valueGetter: (params: GridValueGetterParams) => '',
                width: 50,
                resizable: false,
                hideable: false,                
            }}
        />
    );
}

export default CateringRouteGrid;