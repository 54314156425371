import { Alert, AlertTitle } from "@mui/material";
import { Box } from "@mui/system";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Alert severity="error">
        <AlertTitle>Oops!</AlertTitle>
        <p>Sorry, an unexpected error has occurred.</p>
        <i>{error.error?.message || error.statusText || error.message}</i>
      </Alert>
    </Box>
  );
}