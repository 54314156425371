import { useState } from 'react'
import { styled, Button, Stack, SvgIcon, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Checkbox, FormGroup, FormControlLabel, TextField } from '@mui/material';
import { Leg } from '@aviation/catering-flightservice-sdk';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import FindFlightDialogGrid from './FindFlightDialogGrid';
import dayjs, { Dayjs } from 'dayjs';
import { AirlineFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <SvgIcon component={CrossIcon} inheritViewBox />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface ILegInfoRow {
    propertyName: string;
    propertyValue?: string;
}

export interface ICateringRouteLeg {
    Al: string;
    Nr: string;
    Dep: string;
    Dest: string;
    DepDate: string;
}

export interface IFindFlightDialogProps {
    open: boolean;
    dateFrom?: Dayjs;
    dateTo?: Dayjs;
    airlineData?: Array<AirlineFields & DynamoDbObject>;
    cancelAction: () => void;
    createAction: (list: Leg[]) => void;
}

export function FindFlightDialog(props: IFindFlightDialogProps) {
    const [displayAllFlights, setDisplayAllFlights] = useState(false);
    const [selectedFlights, setSelectedFlights] = useState<Leg[] | undefined>(undefined);
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([props.dateFrom ?? dayjs(), props.dateTo ?? dayjs().add(1, 'day')]);
    const [hasFlights, setHasFlights] = useState(false);
    const [filterTerm, setFilterTerm] = useState('');

    const handleClose = () => {
        props.cancelAction();
    }

    const handleCreate = () => {
        if (selectedFlights !== undefined)
            props.createAction(selectedFlights);
    }

    const onFlightSelected = (list: Leg[]) => {
        setHasFlights(list.length > 0);
        setSelectedFlights(list);
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth='lg'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Stack>
                        <p>Add Flight</p>
                        <Stack direction='row' useFlexGap flexWrap="wrap" spacing={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                                <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                            </LocalizationProvider>

                            <TextField size='small' id="filterTerm" label="Filter" variant="outlined" onChange={(e) => setFilterTerm(e.target.value)} />
                        </Stack>

                    </Stack>

                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <FindFlightDialogGrid filterTerm={filterTerm} airlineList={displayAllFlights ? undefined : props.airlineData?.map(o => o.RK)} dateFrom={dateRange[0]?.format("YYYY-MM-DD") ?? ''} dateTo={dateRange[1]?.format("YYYY-MM-DD") ?? ''} selectionChanged={onFlightSelected} />
                </DialogContent>
                <DialogActions>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={displayAllFlights} onChange={() => setDisplayAllFlights(!displayAllFlights)} />} label="All flights" />
                    </FormGroup>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleCreate} disabled={!hasFlights}>
                        Add flight
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default FindFlightDialog;