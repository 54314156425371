
import { Components } from '@mui/material';
import { CustomTheme } from '../CustomTheme';

// ----------------------------------------------------------------------

export default function Tooltip(theme: CustomTheme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[800],
        },
        arrow: {
          color: theme.palette.grey[800],
        },
      },
    } as Components['MuiTooltip'],
  };
}
