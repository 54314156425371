import { API } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import { Chip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Paper, Stack, TextField, Button, IconButton, SvgIcon, CircularProgress, Box, AlertTitle, Alert, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@mui/material';
import { useGridApiRef, DataGridPremium, GridColDef, GridRenderCellParams, GridValueGetterParams, GridRowParams } from '@mui/x-data-grid-premium';
// import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';
import { useNavigate, useParams } from 'react-router-dom';
import { Status, useFetch } from '../../hooks/useFetch';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { LoadingPlanFields, CatererFields, AirlineFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CATERING_ROUTES } from '../../common/paths';
import { AIRLINE, CLIENT_CODE, WARNING_ROUTEIGNORED } from '../../common/constants';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { CateringRouteUpdateFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import {toast} from "react-toastify";
import CrewContainer from './Components/CrewContainer';
import FindFlightDialog from './Components/FindFlightDialog';
import BoxHeader from '../../common/Components/BoxHeader'
import { Leg } from '@aviation/catering-flightservice-sdk';
import dayjs from 'dayjs';
import WarningChip from './Components/WarningChip';

export interface IDateValueProps {
    date?: Date;
}

function DateValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short" }, }) :
                    null
            }
        </>
    )
}

export interface ITimeValueProps {
    date?: Date;
}

function TimeValue({ date }: ITimeValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }, }, }) :
                    null
            }
        </>
    )
}

function CateringRouteDetails() {
    const apiRef = useGridApiRef();
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // TODO: change resolver from (response) => response to (response) => response.Result or however the result is shaped
    const { status, data = { Legs: []}, error } = useFetch<{ [key: string]: any }>(`/api/cateringroutes/byroute/${id}`, (response) => response);
    const [editData, setEditData] = useState<{ [key: string]: any }>(data);
    // const isDirty = useMemo(() => editData !== data, [editData, data]);
    const [isDirty, setIsDirty] = useState(false);
    const [isUpdatingRoute, setIsUpdatingRoute] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteAllWarningsConfirmation, setShowDeleteAllWarningsConfirmation] = useState(false);
    const [showDeleteFlightConfirmation, setShowDeleteFlightConfirmation] = useState(false);
    const [showCreateFlight, setShowCreateFlight] = useState(false);
    const [showIgnoreConfirmation, setShowIgnoreConfirmation] = useState(false);
    const [showAssignToLegConfirmation, setShowAssignToLegConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const [flightDate, setFlightDate] = useState<string | undefined>();
    const [clientCode, setClientCode] = useState<string | undefined>();
    const [airline, setAirline] = useState<string | undefined>();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: loadingPlanStatus, data: loadingPlanData = [], error: loadingPlanError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${clientCode}/${airline}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: haulTypesStatus, data: haulTypesData = [], error: haulTypesError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}/${airline}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: catererStatus, data: catererData = [], error: catererError } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer/${clientCode}`); // TODO: handle status
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`);

    const rootPath = `/${clientCode || CLIENT_CODE}/${airline || AIRLINE}`;

    const columns: GridColDef[] = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            width:90,
            renderCell: ({ row }: Partial<GridRowParams>) => {
                const isLoading = row.IsLoading ?? false;

                if(!isLoading)
                    return [
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color='primary' onClick={() => navigate(`${rootPath}/${CATERING_ROUTES}/${row.Path}`)}>
                            <SvgIcon component={EditIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if(isLoading) 
                    return (
                        <CircularProgress size={19} />
                    )
                }
        },
        { field: 'DepDate', headerName: 'Date', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => <DateValue date={new Date(params.value!)} /> },
        { field: 'Time', headerName: 'Time', minWidth: 140, valueGetter: (params: GridValueGetterParams) => params.row.DepDate, renderCell: (params: GridRenderCellParams<Date>) => <TimeValue date={new Date(params.value!)} /> },
        { field: 'FlightNumber', headerName: 'Flight No.', minWidth: 100 },
        { field: 'Route', headerName: 'Route', minWidth: 150 },
        { field: 'AcReg', headerName: 'Aircraft Reg.', minWidth: 50 },
        { field: 'AcType', headerName: 'Aircraft Type', minWidth: 150 },
        { field: 'LoadingStations', headerName: 'Loading', minWidth: 100 },
        { field: 'Caterer', headerName: 'Supplier', minWidth: 80 },
        { field: 'LoadingTypes', headerName: 'Loading Type', minWidth: 200 },
        { field: 'HaulType', headerName: 'Haul Type', minWidth: 50 },
        
    ];

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteFlightConfirmation(true);
    };

    // const chartData = useMemo(() => {
    //     return [
    //         { name: 'ME09', value: 400 },
    //         { name: 'VGML', value: 300 },
    //         { name: 'CHML', value: 300 },
    //         { name: 'GFML', value: 200 },
    //       ];
    // }, []);

    // const chartColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    // const RADIAN = Math.PI / 180;
    
    // const renderCustomizedLabel = (props: {cx:number, cy:number, innerRadius:number, outerRadius:number, midAngle:number, percent:number, index: number}) => {
    //     const {cx, cy, innerRadius, outerRadius, midAngle, percent} = props;
    //     const radius = (innerRadius as number) + ((outerRadius as number) - (innerRadius as number)) * 0.5;
    //     const x = (cx as number) + radius * Math.cos(-midAngle * RADIAN);
    //     const y = (cy as number) + radius * Math.sin(-midAngle * RADIAN);

    //     return (
    //         <text x={x} y={y} fill="white" textAnchor={x > (cx as number) ? 'start' : 'end'} dominantBaseline="central">
    //         {`${(percent! * 100).toFixed(0)}`}
    //         </text>
    //     );
    // };

    const updateField = useCallback(function <T>(fieldName: string, value?: T) {
        if (editData && editData[fieldName] !== value) {
            const newData = { ...editData, [fieldName]: value };
            if (data !== newData && Object.keys(newData).every(k => data[k] === newData[k])) {
                setEditData(data);
            } else {
                setIsDirty(true);
                setEditData(newData);
            }
        }
    }, [data, editData]);

    const updateRoute = useCallback(async (item : CateringRouteUpdateFields) =>  {
        const init = {
            body: item,
            headers: {}
        };

        try {
            // await API.put('api', `/api/cateringroutes/${editData.PK.replaceAll('#', '/')}/byroute/${editData.RK.split('#')[1]}`, init);
            await API.patch('api', `/api/cateringroutes/byroute/${editData.PK}`, init);
            toast.success(`Catering route saved successfully.`);
        } catch(e) {
            console.error(e);
            toast.error(`An error occurred while saving catering route.`);
        }
    }, [editData]);

    const isIgnored = useMemo(() => {
        return editData.Warnings?.some((o : string) => o === WARNING_ROUTEIGNORED) ?? false;
    }, [editData])

    const updateLeg = useCallback(async (item: CateringRouteLeg) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/cateringroutes/leg/${editData.ClientCode}/${item.Al}/${item.Nr}/${item.FlightDate}/${item.Dep}-${item.Dest}`, init);
        } catch (e) {
            console.error(e);
        }
    }, [editData]);

    const submitForm = useCallback((event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault();
        setIsUpdatingRoute(true);
        
        const item : CateringRouteUpdateFields = {
            LoadingPlanId: editData.LoadingPlanId,
            HaulType: editData.HaulType,
            IsDutyFree: editData.IsDutyFree,
            Comment: editData.Comment ?? ' '
        }

        if(data.HaulType !== item.HaulType && (editData.Legs?.length ?? 0) > 0)
            for(var i = 0; i < editData.Legs.length; i++) 
                if(editData.Legs[i].HaulType !== editData.HaulType)
                    setShowAssignToLegConfirmation(true);

        updateRoute(item).then(() => { setIsDirty(false)}).finally(() => {setIsUpdatingRoute(false)});
    }, [editData, updateRoute, data])

    // Execute code, when data is loaded. We have to display first departure date e.g.
// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
        if(status === Status.Fetched && editData.Legs.length === 0) {
            setEditData(data);
        }

        const legs : any[] = data.Legs;

        if(legs !== undefined && legs.length > 0) {
            const date = new Date(legs[0].DepDate);
            setFlightDate(date.toLocaleString('nl-NL'));

            setClientCode(legs[0].ClientCode);
            setAirline(legs[0].Al);
        }
        
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [data]);

    const legList = useMemo(() => {
        return (data.Legs as any[]).map((item, idx) => {
            return {
                id: idx,
                PK: item.PK,
                RK: item.RK,
                Path: `${data.PK}/${item.Dep}-${item.Dest}`,
                Al: item.Al,
                No: item.Nr,
                FlightDate: item.FlightDate,
                FlightNumber: `${item.Al}${item.Nr}`,
                Route: `${item.Dep}-${item.Dest}`,
                Dep: item.Dep,
                Dest: item.Dest,
                AcReg: item.AcReg,
                AcType: item.AcType,
                DepDate: item.DepDate,
                LoadingStations: item.LoadingStations,
                HaulType: item.HaulType ?? 'Unkown',
                Caterer: (item as CateringRouteLeg).Caterer?.map(c => { return catererData.find(o => o.RK === c.CatererId)?.Name ?? 'Unkown'}).join(', '),
                LoadingTypes: (item as CateringRouteLeg).Caterer?.map(c => { return c.LoadingTypes?.join(', ')}).join(', ')
            }
        }).sort((a,b) => a.DepDate < b.DepDate ? -1 : 1);
    }, [data, catererData]);

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
        setShowDeleteFlightConfirmation(false);
        setShowIgnoreConfirmation(false);
        setShowAssignToLegConfirmation(false);
        setShowDeleteAllWarningsConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            const item = {
                RemoveWarnings: [deleteItem as string]
            }
            const init = {
                body: item,
                headers: {}
            };
    
            try {
                await API.patch('api', `/api/cateringroutes/byroute/${data.PK}`, init);
                toast.success(`Warning has been removed`);
                const warnings = editData.Warnings as string[];
                const idx = warnings.findIndex(o => o === deleteItem);
                warnings.splice(idx, 1);
                setEditData({ ...editData, Warnings: warnings});
                return true;
            } catch(e) {
                console.error(e);
                toast.error(`An error occurred while removing warning.`);
                return false;
            }
        }
    };

    const handleDeleteAllWarnings = async () => {
        setShowDeleteAllWarningsConfirmation(false);

        const warnings = editData.Warnings as string[];

        if(warnings !== undefined) {
            const item = {
                RemoveWarnings: warnings
            }
            const init = {
                body: item,
                headers: {}
            };
    
            try {
                await API.patch('api', `/api/cateringroutes/byroute/${data.PK}`, init);
                toast.success(`All warnings have been removed`);
                setEditData({ ...editData, Warnings: []});
                return true;
            } catch(e) {
                console.error(e);
                toast.error(`An error occurred while removing all warnings.`);
                return false;
            }
        }
    };

    const handleIgnoreRoute = async() => {
        setShowIgnoreConfirmation(false);
        setIsUpdatingRoute(true);

        const item = {
            AddWarnings: [WARNING_ROUTEIGNORED]
        }
        const init = {
            body: item,
            headers: {}
        }

        try {
            await API.patch('api', `/api/cateringroutes/byroute/${data.PK}`, init);
            toast.success(`Route will get ignored.`);
            const warnings = editData.Warnings as string[];
            warnings.push(WARNING_ROUTEIGNORED);
            setEditData({ ...editData, Warnings: warnings});
            return true;
        } catch(e) {
            console.error(e);
            toast.error(`An error occurred while updating catering route.`);
            return false;
        } finally {
            setIsUpdatingRoute(false);
        }
    };

    const handleAssignToLegs = async() => {
        setShowAssignToLegConfirmation(false);
        setIsUpdatingRoute(true);

        if((editData.Legs?.length ?? 0) > 0) {
            for(var i = 0; i < editData.Legs.length; i++) {
                editData.Legs[i].HaulType = editData.HaulType;
                await updateLeg(editData.Legs[i]);
            }
        }

        toast.success('Haul type of flights has been updated successfully.');
        setIsUpdatingRoute(false);
        window.location.reload();
    };

    const handleDeleteFlightItem = async () => {
        setShowDeleteFlightConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.id, IsLoading: true }]);
            
            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/cateringroutes/leg/${clientCode}/${deleteItem.Al}/${deleteItem.No}/${deleteItem.FlightDate}/${deleteItem.Dep}-${deleteItem.Dest}`, init);
                toast.success(`Flight has been deleted successfully.`);
                apiRef.current.updateRows([{ id: deleteItem.id, _action: 'delete' }]);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: deleteItem.id, IsLoading: false }]);
                toast.error(`An error occurred while deleting flight.`);
                return false;
            }
        }
    };

    const addFlights = async (list : Leg[]) => {
        setShowCreateFlight(false);
        setLoading(true);


        // if(data.Legs !== undefined)
        let caterer = (data.Legs?.length ?? 0) > 0 ? data.Legs[0].Caterer : [];
        for(var i = 0; i < list.length; i++) {
            const flight = list[i];
            let updateItem : CateringRouteLeg = {
                CateringRouteId: data.PK,
                FlightDate: flight.FlightDate,
                ClientCode: clientCode,
                Al: flight.Al,
                Dep: flight.Dep,
                Dest: flight.Dest,
                Caterer: caterer,
                LoadingStations: undefined,
                DepDate: flight.DepDate,
                Nr: flight.No,
                AcReg: flight.AcReg,
                AcRegDisp: flight.AcRegDisp,
                FlightPurpose: flight.FlightPurpose,
                AcType: flight.AcType,
                AcVerCode: flight.AcVerCode,
                DepLocalDate: flight.DepLocalDate,
                LoadingPlanId: data.LoadingPlanId,
                HaulType: data.HaulType,
                GSI1PK: `${clientCode}#${flight.FlightDate!.substring(0, 7)}`,
                GSI1RK: flight.FlightDate!.substring(0,10)
            }

            if(!await addFlight(updateItem)) {
                toast.error('An error occurred while adding flight');
                return;
            }
        }

        await addWarning(['NoCaterer', 'NoLoadingPlan', 'UndeterminableHaulType'])
        window.location.reload();
    }

    const addWarning = async(warnings : string[]) => {
        try {
            const updateItem : CateringRouteUpdateFields = {
                AddWarnings: warnings
            }

            const init = {
                body: updateItem,
                headers: {}
            };

            await API.patch('api', `/api/cateringroutes/byroute/${data.PK}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    }

    const addFlight = async (flight : CateringRouteLeg) => {
        const init = {
            body: flight,
            headers: {}
        };

        try {
            await API.put('api', `/api/cateringroutes/leg/${clientCode}/${flight.Al}/${flight.Nr}/${flight.FlightDate}/${flight.Dep}-${flight.Dest}`, init);
            // apiRef.current.updateRows([{ id: deleteItem.id, _action: 'add' }]);
            return true;
        } catch(e) {
            console.error(e);
            // apiRef.current.updateRows([{ id: deleteItem.id, IsLoading: false }]);
            return false;
        }
    }

    return (
        <Grid container spacing={3}>
            {status !== Status.Fetched || loading ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: 'calc(100vh - 104px)',
                        width: '100%',
                    }}
                >
                    {status === Status.Error ?
                        <Alert severity="error">
                            <AlertTitle>{t('Oops!')}</AlertTitle>
                            <p>{t('Sorry, an unexpected error has occurred.')}</p>
                            <i>{error}</i>
                        </Alert>
                        :
                        <CircularProgress />
                    }
                </Box>
                :
                <>
                    <Grid item xs={12}>
                        <PageHeader title={data.Route} subtitle={flightDate}>
                        {/* {editData.Warnings?.length ? <Chip size='small' key='incomplete' label='Incomplete' color="error" /> : <Chip size='small' label={'Complete'} color="success" />} */}
                            <Stack direction='row' spacing={2}>
                                { data.IsLocked === true && <Chip size='small' key='edited' label='Edited' color="primary" /> }
                                <WarningChip warnings={editData.Warnings} />
                            </Stack>
                            
                        </PageHeader>
                    </Grid>
                    <Grid item xs={12} md={editData.Warnings?.length ? 8 : 12}>
                        <Paper sx={{ p: 2 }} elevation={3}>
                        <Box>
                            <Typography variant="subtitle1" component="h2">
                                Catering Route Properties
                            </Typography>
                        </Box>
                        <Box>
                            <form onSubmit={submitForm}>
                                <Stack spacing={5} direction="row" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                                    <FormControl sx={{minWidth: 200 }} size="small">
                                        <InputLabel id="plan-type-label">Plan Type</InputLabel>
                                        <Select
                                            labelId="plan-type-label"
                                            id="plan-type-select"
                                            value={editData.LoadingPlanId ?? ''}
                                            label="Plan Type"
                                            onChange={(e) => { updateField('LoadingPlanId', e.target.value)}}
                                        >
                                            { loadingPlanData.map((type)=> (
                                                <MenuItem  key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ minWidth: 150 }} size="small">
                                        <InputLabel id="duty-free-label">Duty free</InputLabel>
                                        <Select
                                            labelId="duty-free-label"
                                            id="duty-free-select"
                                            value={editData.IsDutyFree ?? false}
                                            onChange={(e) => { updateField('IsDutyFree', e.target.value === 'true')}}
                                            label="Duty free"
                                            itemScope
                                        >
                                            <MenuItem key='false' value={'false'}>No duty free</MenuItem>
                                            <MenuItem key='true' value={'true'}>Duty free</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                                        <InputLabel id="haul-type-label">Haul type</InputLabel>
                                        <Select
                                            labelId="haul-type-label"
                                            id="haul-type-select"
                                            value={editData.HaulType ?? ''}
                                            onChange={(e) => { updateField('HaulType', e.target.value)}}
                                            label="Haul Type"
                                            itemScope
                                        >
                                            { haulTypesData.map((type)=> (
                                                <MenuItem  key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack> 
                                <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Comment')}
                                    onChange={e => updateField('Comment', e.target.value)}
                                    value={editData.Comment}
                                    fullWidth
                                    multiline
                                />
                                <Box sx={{marginTop:2}}>
                                    {!isUpdatingRoute && !isIgnored && <Button variant="contained" onClick={() => setShowIgnoreConfirmation(true)} color='warning'>Ignore</Button> }
                                    {isUpdatingRoute && (<CircularProgress style={{float:'right'}} size={25} />)}
                                    {!isUpdatingRoute && (<Button style={{float:'right'}} variant="contained" type="submit" disabled={!isDirty}>Save</Button>)}
                                    <div style={{clear:'both'}} />
                                </Box>
                            </form>
                            </Box>
                        </Paper>
                    </Grid>
                    { editData.Warnings?.length !== 0 && <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 2 }} elevation={3} style={{minHeight:260}}>
                            <BoxHeader title='Warnings'>
                                <Button variant="contained" onClick={() => setShowDeleteAllWarningsConfirmation(true) }>Remove All</Button>
                            </BoxHeader>
                            <Box >
                                <Stack spacing={2}>
                                    { editData.Warnings?.map((warning : string) => <Alert key={warning} severity="error" onClose={() => { setDeleteItem(warning); setShowDeleteConfirmation(true); }}>
                                        <AlertTitle>{t('Title-' + warning)}</AlertTitle>
                                        {t(warning)}</Alert>)}
                                </Stack>
                            </Box>
                            
                        </Paper>
                    </Grid>}
                    { showCreateFlight && (<FindFlightDialog open={showCreateFlight} airlineData={airlineData} dateFrom={dayjs(data.FlightDate)} dateTo={dayjs(data.FlightDate).add(1, 'days')} createAction={addFlights} cancelAction={() => setShowCreateFlight(false)} />) }
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Remove warning?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please ensure, that you fixed everything before removing a warning. Do you want to remove the warning {t('Title-' + deleteItem)}?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                        <Button onClick={handleDeleteItem} color="error">Remove</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showDeleteAllWarningsConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Remove all warning?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are going to delete all warnings. Please ensure, that you fixed everything before removing all warnings. Do you want to remove all warnings?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                        <Button onClick={handleDeleteAllWarnings} color="error">Remove all</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showDeleteFlightConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete flight?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, that you want to delete the flight {deleteItem?.FlightNumber} from {deleteItem?.Dep} to {deleteItem?.Dest}? You can't undo this action!
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                        <Button onClick={handleDeleteFlightItem} color="error">Remove</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showIgnoreConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Ignore Route?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, that you want to ignore this route?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                        <Button onClick={handleIgnoreRoute} color="error">Ignore</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={showAssignToLegConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Assign haul type to all legs?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           The haul type has been set to {editData.HaulType}. At least one flight has another haul type. Do you want to assign the haul type to all flights?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog} autoFocus>No</Button>
                        <Button onClick={handleAssignToLegs} color="error">Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2 }} elevation={3}>
                            {/* <Box sx={{marginBottom:2}}>
                                <Typography variant="subtitle1" component="h2">
                                    Flights
                                </Typography>
                            </Box> */}
                            <BoxHeader title='Flights'>
                                <Button variant="contained" onClick={() => setShowCreateFlight(true) }>Add Flight</Button>
                            </BoxHeader>
                            <Box>
                                <DataGridPremium
                                    apiRef={apiRef}
                                    autoHeight 
                                    rows={legList}
                                    columns={columns}
                                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                                    sx={{marginRight:-2, marginLeft:-2}}
                                />
                            </Box>
                            
                        </Paper>
                    </Grid>
                    { data && (<CrewContainer cateringRouteId={data.PK} />)}
                </>
            }
        </Grid >
    );
}

export default CateringRouteDetails;