import { useState, useMemo } from 'react'
import { API } from 'aws-amplify';
import { Paper, Chip, Tooltip, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, } from '@mui/material';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams, DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { UserFields, ResetPasswordRequest, CatererFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as LockIcon } from '../../icons/lock-close.svg';
import { ReactComponent as BuildingIcon } from '../../icons/building.svg';
import {toast} from "react-toastify";
import UserCreateDialog from './UserCreateDialog';
import UserResetPassDialog from './UserResetPassDialog';
import { useParams } from 'react-router-dom';
import UserMembershipDialog from './UserMembershipDialog';
import { DynamoDbObject } from '@aviation/catering-common';

export interface IDateValueProps {
    date?: Date;
}

function DateTimeValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    `${t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short", }, })} ${t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }, }, })}` :
                    null
            }
        </>
    )
}

export class UserGroup {
    GroupName?: string;
    Precedence?: number;
}

function User() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<UserFields[]>(`/api/masterdata/user`);
    const { data: groupData = [] } = useFetch<UserGroup[]>(`/api/masterdata/group`, o => o);
    const { data: supplierData = [] } = useFetch<(CatererFields & DynamoDbObject)[]>(`/api/masterdata/caterer/${clientCode}`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<UserFields | undefined>(undefined);
    const [resetItem, setResetItem] = useState<UserFields | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showResetDialog, setShowResetDialog] = useState(false);
    const [showMembershipDialog, setShowMembershipDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const rows = useMemo(() => {
        return data.map(o => { return {...o, id: o.Username }});
    }, [data]);

    const groupList = useMemo(() => {
        return groupData?.sort((a,b) => (a.Precedence ?? 0) < (b.Precedence ?? 0) ? -1 : 1).map(o => { return {label: o.GroupName ?? '', value: o.GroupName ?? '' } });
    }, [groupData])

    const supplierList = useMemo(() => {
        return supplierData?.sort((a,b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1);
    }, [supplierData]);

    const rowId = (row : any) => {
        return `${row.Username}`;
    }

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: deleteItem,
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/user`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`User deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting the user`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as UserFields).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to user saved successfully.`);
            else
                toast.error(`An error occurred while saving airline`);
        })
        
        return updatedRow;
    };

    const updateItem = async(item : UserFields) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            const response = await API.put('api', `/api/masterdata/user`, init) as UserFields;
            return response;
        } catch(e) {
            console.error(e);
            return undefined;
        } 
    }

    const onCreateItem = async (givenName : string, familyName : string, email : string, groupName : string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : UserFields = {
            Email: email,
            GivenName: givenName,
            FamilyName: familyName,
            Username: email,
            Group: groupName,
            Enabled: true,
            UserCreateDate: new Date().toISOString()
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
       
        let createdItem = await updateItem(item);
        
        if(createdItem !== undefined) {
            (createdItem as any).id = rowId(createdItem);
            setLoading(false);
            apiRef.current.updateRows([createdItem]);
            toast.success(`User added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating the user`);
        }
    }

    const onResetPassword = async(user : UserFields, password: string) => {
        const request : ResetPasswordRequest = {
            Username: user.Username,
            TemporaryPassword: password
        }

        const init = {
            body: request,
            headers: {}
        };

        try {
            setShowResetDialog(false);
            apiRef.current.updateRows([{ id: rowId(user), IsLoading: true }]);

            const response = await API.post('api', `/api/masterdata/user/resetpassword`, init);
            toast.success(`Password has been set successfully`);
            
            setResetItem(undefined);
            return response;
        } catch(e) {
            console.error(e);
            toast.error(`An error occurred while setting password`);
            return undefined;
        } finally {
            apiRef.current.updateRows([{ id: rowId(user), IsLoading: false }]);
            
        }
    }

    const onUpdateMemberships = async (user : UserFields) => {
        setShowMembershipDialog(false);
        apiRef.current.updateRows([{ id: rowId(user), IsLoading: true }]);

        const success = await updateItem(user)
            apiRef.current.updateRows([{ id: rowId(user), IsLoading: false }]);
        
        if(success) 
            toast.success(`Changes to user saved successfully.`);
        else
            toast.error(`An error occurred while saving airline`);
    };
    
    const columns: GridColDef[] = [
        { field: 'Username', headerName: 'Username', minWidth: 250, editable: false },
        { field: 'GivenName', headerName: 'Firstname', minWidth: 100, editable: true },
        { field: 'FamilyName', headerName: 'Lastname', minWidth: 100, editable: true },
        { field: 'Group', headerName: 'Group', minWidth: 150, editable: true, type: 'singleSelect', valueOptions: groupList },
        { field: 'Enabled', headerName: 'Enabled', minWidth: 120, type: 'boolean', editable: true },
        { field: 'UserStatus', headerName: 'Status', minWidth: 150, editable: false, 
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Tooltip title={params.value.replaceAll('_', ' ')}>
                        <Chip size="small" color={params.value === 'CONFIRMED' ? 'success' : 'warning'} label={params.value.replaceAll('_', ' ')}></Chip>
                    </Tooltip>
                )
            } },
        { field: 'UserCreateDate', headerName: 'Created', minWidth: 200, editable: false, renderCell: (params: GridRenderCellParams<Date>) => <DateTimeValue date={new Date(params.value!)} /> },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return [
                    row.Group === 'Caterer' && <Tooltip title="Set supplier membership">
                        <IconButton color="primary" onClick={() => { setResetItem(row); setShowMembershipDialog(true);}}>
                            <SvgIcon component={BuildingIcon} inheritViewBox />
                        </IconButton>
                    </Tooltip>,
                    <Tooltip title="Set user password">
                        <IconButton color="primary" onClick={() => { setResetItem(row); setShowResetDialog(true);}}>
                            <SvgIcon component={LockIcon} inheritViewBox />
                        </IconButton>
                    </Tooltip>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                    
                ]
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Users')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    { groupList && (<UserCreateDialog open={showCreateDialog} groupList={groupList} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} />)}
                    { supplierList && (<UserMembershipDialog open={showMembershipDialog} user={resetItem} supplierList={supplierList} cancelAction={() => setShowMembershipDialog(false)} createAction={onUpdateMemberships}/>)}
                    <UserResetPassDialog open={showResetDialog} user={resetItem} cancelAction={() => setShowResetDialog(false)} createAction={onResetPassword} />
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete user?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are going to delete user {deleteItem?.Username ?? ''}? If you delete it, the user won't have access anymore. Do you want to delete the user?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default User;