import { AuthOptions } from '@aws-amplify/auth/lib/types/Auth';

export const AmplifyConfigAuth: AuthOptions = {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
        //Don't set domain to get hostOnly cookie.
        //Setting the domain will result in a leading . cookie domain which applies for all subdomains.
        path: '/',
        expires: 365,
        sameSite: 'strict',
        secure: process.env.NODE_ENV !== 'development',
    },
    authenticationFlowType: 'USER_SRP_AUTH',
};