import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import { Paper, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { AirlineFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import AirlineCreateDialog from './AirlineCreateDialog';
import {toast} from "react-toastify";

function Airlines() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<AirlineFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const rowId = (row : any) => {
        return `${row.PK}#${row.RK}`;
    }

    const rows = useMemo(()=> {
        return data.map(o => { return {...o, id: rowId(o)}})
    }, [data]);

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/airline/${clientCode}/${deleteItem.RK}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Airline deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting an airline`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const columns: GridColDef[] = [
        { field: 'RK', headerName: 'Airline', minWidth: 100 },
        { field: 'Description', headerName: 'Description', minWidth: 200, editable: true },
        { field: 'MorningFlightEnd', headerName: 'Morning end', minWidth: 150, editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const onCreateItem = async (airlineCode : string, airlineName : string, morningEnd : string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : any = {
            MorningFlightEnd: morningEnd,
            Description: airlineName,
            PK: `${clientCode}#Airline`,
            RK: `${airlineCode}`,
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
       
        if(await updateItem(item as AirlineFields & DynamoDbObject)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Airline added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating airline`);
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as AirlineFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to airline saved successfully.`);
            else
                toast.error(`An error occurred while saving airline`);
        })
        
        return updatedRow;
    };

    const updateItem = async(item : AirlineFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/airline/${clientCode}/${item.RK}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Airlines')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            isCellEditable={(params) => true }
                            
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    <AirlineCreateDialog open={showCreateDialog} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} />
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Airline?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this airline? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Airlines;