import { Components } from "@mui/material";
import { CustomTheme } from "../CustomTheme";

// ----------------------------------------------------------------------

export default function Drawer(theme: CustomTheme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
            border: 'none'
        }
      },
    } as Components['MuiDrawer'],
  };
}
