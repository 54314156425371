
import { useState, useMemo} from 'react';
import { Status } from '../../../hooks/useFetch';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../../icons/checkmark.svg';
import { ServiceEntry } from '@aviation/catering-paxmealservice-sdk';

import { SvgIcon, IconButton, CircularProgress } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef, GridValueGetterParams, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';


export interface ILoadingsGridProps {
    aggregatedData : any[];
    status : Status;
    error? : any;
    updateAction? : (code : string, entry : ServiceEntry) => Promise<boolean>;
}

function LoadingsGrid(props : ILoadingsGridProps) {
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    
    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.Id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const columns: GridColDef[] = [
        { field: 'SsrCode', headerName: 'SSR', minWidth: 100 },
        { field: 'AdultCount', type:'number', headerName: 'Adults', minWidth: 70 },
        { field: 'ChildCount', type:'number', headerName: 'Children', minWidth: 70 },
        { field: 'ManualCount', type:'number', headerName: 'Add', minWidth: 70, editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.Id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.Id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <></>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const data = useMemo(() => props.aggregatedData, [props.aggregatedData]);

    const getTreeDataPath = (row : any) => {
        return row.Id.split('.');
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        const parentRowId = row.Id.split('.')[0];

        const parentRow = apiRef.current.getRow(parentRowId);
        
        if(props.updateAction !== undefined)
            props.updateAction(parentRow.Id, row as ServiceEntry).then(success => {
                apiRef.current.updateRows([{ Id: row.Id, IsLoading: false }]);

                const childRows = apiRef.current.getAllRowIds().filter(o => (o as string).startsWith(parentRowId + '.'));

                let manualCount = 0;
                for(var i = 0; i < childRows.length; i++)
                    manualCount += childRows[i].toString().split('.')[1] !== 'NOM' ? (apiRef.current.getRow(childRows[i]) as ServiceEntry).ManualCount : 0;

                apiRef.current.updateRows([{ ...parentRow, Id: parentRowId, ManualCount: manualCount }])
            })

        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    return (
        <DataGridPremium
            apiRef={apiRef}
            treeData
            autoHeight
            rows={data}
            columns={columns}
            editMode='row'
            
            getRowId={(itm) => itm.Id}
            getTreeDataPath={getTreeDataPath}
            defaultGroupingExpansionDepth = {-1}
            getRowClassName={(params) => { return params.row.IsServiceType === true ? `tui-grid-service-type-row` : 'tui-grid-route-row'}}
            loading={props.status === Status.Fetching || props.status === Status.Idle}
            //error={props.error}
            isCellEditable={(params) => params.row.IsServiceType === false}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter

            groupingColDef={{
                headerName: ' ',
                valueGetter: (params: GridValueGetterParams) => '',
                width: 50,
                resizable: false,
                hideable: false,
            }}
        />
    )
}

export default LoadingsGrid;