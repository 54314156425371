import { Components } from "@mui/material";
import { CustomTheme } from "../CustomTheme";

// ----------------------------------------------------------------------

export default function Toolbar(theme: CustomTheme) {
  return {
    MuiToolbar: {
      defaultProps: {
        sx: {
          height: {
            xs: '56px',
            sm: '64px',
          }
        },
      },
      styleOverrides: {
        root: {
          '&.MuiToolbar-gutters': {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
      },
    } as Components['MuiToolbar'],
  };
}
