
import ListSubheader from '@mui/material/ListSubheader';
import NavigationItem from './NavigationItem';
import { INavigationGroup } from './NavigationItems';

export interface INavigationGroupProps extends INavigationGroup {
}

function NavigationItemGroup(props: INavigationGroupProps) {
    const { label, children } = props;

    return (
        <>
            <ListSubheader>{label}</ListSubheader>
            {children.map((item) => <NavigationItem key={item.id} {...item} />)}
        </>
    );
}

export default NavigationItemGroup;