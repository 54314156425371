import App from '../App';
import ErrorPage from '../ErrorPage';
import Dashboard from './Dashboard';
import CateringRoutes from './CateringRoutes';
import CatererStationGroups from './CatererStationGroups';
import LoadingPlans from './LoadingPlans';
import BoBForecast from './BoBForecast';
import User from './User';
import Supplier from './Supplier';
import HaulTypes from './HaulTypes';
import AuditLog from './AuditLog';
import SignIn from './SignIn';
import { AIRCRAFTGROUPS, AIRCRAFTS, AIRLINES, AIRPORTS, AUDIT_LOG, BOB_FORECAST, CATERER_PER_LEG, CATERING_ROUTES, CATERING_STATION_GROUPS, DASHBOARD, HAUL_TYPES, HOME, LOADING_PLANS, LOADING_PLAN_PER_DEST, ROUTE_OVERRIDE, SERVICE_TYPES, SIGN_IN, SSR_CODES, SUPPLIER, USER } from '../common/paths';
import Airports from './Airports';
import Aircrafts from './Aircrafts';
import Airlines from './Airlines';
import ServiceTypes from './ServiceTypes';
import SsrCodes from './SsrCodes';
import CatererPerLeg from './CatererPerLeg';
import CateringRouteDetails from './CateringRoutes/CateringRouteDetails';
import CateringRouteLegDetails from './CateringRoutes/CateringRouteLegDetails';
import LoadingPlanPerDestination from './LoadingPlanPerDestination';
import RouteOverride from './RouteOverride';
import AircraftGroups from './AircraftGroups';

const routes = [
  {
    path: '',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: HOME,
        children: [
          {
            path: DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: CATERING_ROUTES,
            children: [
              {
                path: '',             
                element: <CateringRoutes />,
              },              
              {
                path: ':id',             
                element: <CateringRouteDetails />,
              },            
              {
                path: ':id/:leg',             
                element: <CateringRouteLegDetails />,
              }
            ]
          },
          {
            path: CATERING_STATION_GROUPS,
            element: <CatererStationGroups />,
          },
          {
            path: LOADING_PLANS,
            element: <LoadingPlans showDefault/>,
          },
          {
            path: LOADING_PLAN_PER_DEST,
            element: <LoadingPlanPerDestination />,
          },
          {
            path: BOB_FORECAST,
            element: <BoBForecast />,
          },
          {
            path: USER,
            element: <User />,
          },
          {
            path: AIRPORTS,
            element: <Airports />,
          },
          {
            path: AIRLINES,
            element: <Airlines />,
          },
          {
            path: SERVICE_TYPES,
            element: <ServiceTypes />,
          },
          {
            path: SSR_CODES,
            element: <SsrCodes />,
          },
          {
            path: AIRCRAFTS,
            element: <Aircrafts />,
          },
          {
            path: AIRCRAFTGROUPS,
            element: <AircraftGroups />,
          },
          {
            path: CATERER_PER_LEG,
            element: <CatererPerLeg />,
          },
          {
            path: ROUTE_OVERRIDE,
            element: <RouteOverride />,
          },
          {
            path: SUPPLIER,
            element: <Supplier />,
          },
          {
            path: HAUL_TYPES,
            element: <HaulTypes />,
          },
          {
            path: AUDIT_LOG,
            element: <AuditLog />,
          },
        ],
      },      
      {
        path: SIGN_IN,
        element: <SignIn />
      },
    ],
  },
];

export default routes;