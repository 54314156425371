import { useState, useCallback, useMemo } from 'react'
import { Stack, Button, Box, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useFetch } from '../../hooks/useFetch';
import { BoBPackagePlan } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';

export interface IBobForecastPackagePlanCopyDialog {
    open : boolean;
    clientCode : string;
    cancelAction : () => void;
    copyAction : (validFrom : Dayjs | undefined, validTo : Dayjs | undefined, packagePlan : (BoBPackagePlan & DynamoDbObject) | undefined) => void;
    updateAction : (validFrom : Dayjs | undefined, validTo : Dayjs | undefined) => void;
}

function BobForecastForecastDetailsCopyDialog(props : IBobForecastPackagePlanCopyDialog) {
    const [validFrom, setValidFrom] = useState<dayjs.Dayjs | undefined>(undefined);
    const [validTo, setValidTo] = useState<dayjs.Dayjs | undefined>(undefined);
    const [packagePlanId, setPackagePlanId] = useState<string | undefined>(undefined);
    const [packagePlan, setPackagePlan] = useState<(BoBPackagePlan & DynamoDbObject) | undefined>(undefined);
    const [copyDisable, setCopyDisable] = useState<boolean>(true);
    const { data = [] } = useFetch<Array<BoBPackagePlan & DynamoDbObject>>(`/api/masterdata/bobpackageplan/${props.clientCode}`,(response) => response.Items);
    
    const planList = useMemo(() => {
        const list = data.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id, entries: o.Entries } }).sort((a,b) => a.label < b.label ? -1 : 1);
        const defaultItems = [{ label: '(None)', value: undefined, entries: []}];
        return defaultItems.concat(list as any[]);
    }, [data])
    
    const updateField = useCallback((field: string, value: any) => {
        if((field === 'ValidFrom' || field === 'ValidTo') && value === 'Invalid Date')
            value = undefined;

        if(value !== undefined) {
            value = dayjs(value);
        }

        switch(field) {
            case 'ValidFrom':
                setValidFrom(value);
            break;

            case 'ValidTo':
                setValidTo(value);
            break;

            default:

            break;
        }
    }, []);

    const changePackagePlan = (event : SelectChangeEvent<string>) => {
        setPackagePlanId(event.target.value);
        setCopyDisable(event.target.value === undefined);

        if(event.target.value === undefined) {
            setPackagePlan(undefined);
        } else {
            const plan = data.find(o => o.Id === event.target.value);
            setPackagePlan(plan);
        }
    }

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Bulk Edit</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can set new valid from/ to dates on selected items or copy them with new from/ to dates by clicking on 'Copy'.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DatePicker
                            value={validFrom}
                            label="Valid from"
                            onChange={(e) => { setValidFrom(e ?? dayjs()); updateField('ValidFrom', e?.format('YYYY-MM-DD')); }}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DatePicker
                            value={validTo}
                            label="Valid to"
                            onChange={(e) => { setValidTo(e ?? dayjs()); updateField('ValidTo', e?.format('YYYY-MM-DD')); }}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="packageplan-label">Package Plan</InputLabel>
                        <Select
                            labelId="packageplan-label"
                            id="packageplan-select"
                            value={packagePlanId}
                            label="Type"
                            onChange={changePackagePlan}
                            >
                            { 
                                planList.map(p => (
                                    <MenuItem  key={p.value} value={p.value}>{p.label}</MenuItem>
                                ))
                            }
                            
                        </Select>
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={copyDisable} onClick={(e) => { props.copyAction(validFrom, validTo, packagePlan)} }>Copy</Button>
                <Button onClick={(e) => { props.updateAction(validFrom, validTo)} }>Save</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default BobForecastForecastDetailsCopyDialog