
import { useMemo, useState } from 'react';
import { DataGridPremium, GridColDef, GridRowParams, GridRowModesModel, GridRowModes, GridEventListener, GridCallbackDetails, GridRowEditStopReasons, GridRowModel, useGridApiRef, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { BobFreshFoodFields, BobFreshFoodType, BobFreshFoodLegInfo } from '@aviation/catering-bobfreshfoodservice-sdk';
import { SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../../icons/checkmark.svg';
import StyledBox from '../../../common/Components/StyledBox'

export interface ILoadingsGridProps {
    legInfo : BobFreshFoodLegInfo;
    aggregatedData : BobFreshFoodFields[];
    productList : {label: string, value: string}[];
    loading : boolean;
    error? : any;
    updateAction? : (item :BobFreshFoodFields) => Promise<boolean>;
    deleteAction? : (item :BobFreshFoodFields) => Promise<boolean>;
}

// const StyledBox = styled(Box)(({ theme }) => ({
//     height: 400,
//     width: '100%',
//     '& .MuiDataGrid-cell--editable': {  
//       '& .MuiInputBase-root': {
//         height: '100%',
//       },
//     },
//     '& .Mui-error': {
//       backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
//       color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
//     },
//   }));

function BobFreshFoodGrid(props : ILoadingsGridProps) {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<BobFreshFoodFields | undefined>(undefined);
    const apiRef = useGridApiRef();

    const preProcessEditCellProps = async (params: GridPreProcessEditCellProps) => {
        const errorMessage = (params.props.value?.toString() ?? '') === '' ? 'Please provide some value' : '';
        return { ...params.props, error: errorMessage };
    };

    const columns: GridColDef[] = [
        { field: 'Type', headerName: 'Type', minWidth: 100, valueGetter: (params) => params.row.Type === BobFreshFoodType.relative ? 'Relative' : 'Fixed', editable: false },
        { field: 'ProductCode', headerName: 'Product', minWidth: 140, editable: true, preProcessEditCellProps, type:'singleSelect', valueOptions: props.productList },
        { field: 'Amount', headerName: 'Amount', minWidth: 70, type: 'number', editable: true, 
          renderCell: (params) => {
            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted}${params.row.Type === BobFreshFoodType.relative ? '%' : ''}`;
          },
          valueSetter: (params) => {
            if(params.row.Type === BobFreshFoodType.relative) {
                const productCount = Math.ceil((params.value / 100.0) * params.row.PaxCount) + (params.row.Extra ?? 0);
                return {...params.row, ProductCount: productCount, Amount: params.value};
            } else {
                return {...params.row, ProductCount: params.value, Amount: params.value};
            }
          },
        },
        { field: 'Extra', headerName: 'Extra', minWidth: 70, editable: true, type: 'number',
          valueSetter: (params) => {
            
            if(params.row.Type === BobFreshFoodType.relative) {
                const productCount = Math.ceil((params.row.Amount / 100.0) * params.row.PaxCount) + (params.value ?? 0);
                return {...params.row, ProductCount: productCount, Extra: params.value};
            } else {
                return params.row;
            }
          }
        },
        { field: 'ProductCount', headerName: 'Forecast', minWidth: 70, editable: false, type: 'number', },
        { field: 'actions', type: 'actions', headerName: '', renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.EntryId]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.EntryId)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row as BobFreshFoodFields)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const data = useMemo(() => props.aggregatedData, [props.aggregatedData]);

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        if(props.updateAction !== undefined) {
            props.updateAction(row).then(() => {
                apiRef.current.updateRows([{ id: row.EntryId, IsLoading: false }]);
            });
        } 
        
        return updatedRow;
    };
    
    const handleSaveClick = (item : BobFreshFoodFields) => () => {
        setRowModesModel({...rowModesModel, [item.EntryId ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined && props.deleteAction !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: true }]);
            props.deleteAction(deleteItem).then(success => {
                if(success)
                    apiRef.current.updateRows([{ id: deleteItem.EntryId, _action: 'delete' }]);
                else
                    apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: false }]);
            });
        }
    };

    const confirmDeletion = (item : BobFreshFoodFields) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    return (
        <div>
        <StyledBox>
            <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={data}
            columns={columns}
            // getRowId={(itm) => itm.EntryId}
            editMode="row"
            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
            loading={props.loading}
            // error={props.error}
            isCellEditable={(params) => (params.row.Type ?? 0) === BobFreshFoodType.fixed && params.colDef.field === 'Extra' ? false : true }
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter

            rowModesModel={rowModesModel}
            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
        />
        </StyledBox>
        <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
            <DialogTitle id="alert-dialog-title">
                {"Delete Bob Fresh Food?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Do you want to delete this item? You can't undo this action.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
            <Button onClick={handleDeleteItem} color="error">Delete</Button>
            </DialogActions>
      </Dialog>
        </div>
    )
}

export default BobFreshFoodGrid;