import { alpha, Components } from '@mui/material/styles';
import { CustomTheme } from '../CustomTheme';
import LinkBehavior from './LinkBehavior';

// ----------------------------------------------------------------------

export default function Button(theme: CustomTheme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    } as Components['MuiButtonBase'],
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    } as Components['MuiButton'],
  };
}
