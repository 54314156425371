import { useMemo, useState } from 'react'
import { API } from 'aws-amplify'
import { Paper, SvgIcon, IconButton, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { HaulTypeFields } from '@aviation/catering-masterdata-sdk';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { AirlineFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import {toast} from "react-toastify";

function HaulTypes() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<HaulTypeFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}`);
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`); 
    const { data: serviceTypeData = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}`);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    
    const rowId = (row : any) => {
        return `${row.PK}#${row.RK}`;
    }

    const rows = useMemo(() => {
        return data.sort((a, b) => (a.SortIndex ?? 0) < (b.SortIndex ?? 0) ? -1 : 1).map(o => {
            return {...o, id: rowId(o), airline: o.RK.split('#')[0]};
        });
    }, [data]);

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const serviceTypeList = useMemo(() => {
        let result : any[] = [];

        if(airlineData !== undefined) {
            // Build unique list of service types for each airline
            airlineData.forEach(a => { 
                const list = new Set(serviceTypeData.filter(t => { return t.RK.split('#')[0] === a.RK && t.Type === 'SSR' }).map(t => t.Code));
                result = result.concat(...Array.from(list).map(o => { return { label: o, value: o, airline: a.RK }}).sort((a,b) => (a.label ?? '') < (b.label ?? '') ? -1 : 1));
            });
        }
        
        return result;
    }, [serviceTypeData, airlineData])

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as HaulTypeFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to airport saved successfully.`);
            else
                toast.error(`An error occurred while saving airport`);
        })
        
        return updatedRow;
    };

    const updateItem = async(item : HaulTypeFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/haultype/${clientCode}/${item.RK.replace('#', '/')}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'airline', headerName: 'Airline', minWidth: 150, editable: false, 
            type: 'singleSelect', valueOptions: airlineList },
        { field: 'Name', headerName: 'Name', minWidth: 200, editable: true },
        { field: 'Description', headerName: 'Description', minWidth: 200, editable: true },
        { field: 'SortIndex', headerName: 'SortIndex', minWidth: 200, editable: true },
        { field: 'DefaultServiceType', headerName: 'Default Service', minWidth: 200, editable: true,
            type: 'singleSelect', valueOptions: (params => {
                // Filter on airline
                return serviceTypeList.filter(o => o.airline === params.row.airline);
            }) },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if(isInEditMode) 
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if(isLoading) 
                    return (
                        <CircularProgress size={19} />
                    )
                }
            },
            { field: 'SsrListForEvaluation', headerName: 'Evaluate SSRs', minWidth: 150, editable: true },
        ];

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PageHeader title={t('Haul Types')} />
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle}
                            isCellEditable={(params) => true }
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}

                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
}

export default HaulTypes;