import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { DynamoDbObject } from '@aviation/catering-common';
import { BobConfigurationFields } from '@aviation/catering-masterdata-sdk';

export interface IBobForecastCopyDialog {
    open : boolean;
    parent: BobConfigurationFields & DynamoDbObject;
    cancelAction : () => void;
    copyAction : (airline : string, dep : string, dest : string, packingPlan: string) => void;
}

function BobForecastCopyDialog(props : IBobForecastCopyDialog) {
    const { t } = useTranslation();
    
    const [ dep, setDep] = useState('');
    const [ dest, setDest] = useState('');
    const [ packingPlan, setPackingPlan] = useState(props.parent.PackingPlan ?? '');
    
    const [ depError, setDepError] = useState(true);
    const [ destError, setDestError] = useState(true);

    const hasErrors = useMemo(() => {
        return depError || destError;
    }, [depError, destError]);

    const handleDep = (value : string | undefined) => {
        setDep(value ?? '');
        setDepError(!/^[A-Z]{3}$/g.test(value ?? ''));
    }   

    const handleDest = (value : string | undefined) => {
        setDest(value ?? '');
        setDestError(!/^[A-Z]{3}(,[A-Z]{3})*$/g.test(value ?? ''));
    }   

    const handlePackingPlan = (value : string | undefined) => {
        setPackingPlan(value ?? '');
    }  

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Copy BoB Forecast</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are going to copy BoB configuration from the selected entry
                    <b>${props.parent.RK.split('#')[0]} ${props.parent.RK.split('#')[1]}</b>
                    to all targets you configure here
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={props.parent.RK.split('#')[0]}
                            label="Type"
                            disabled={true}>
                            <MenuItem  key={props.parent.RK.split('#')[0]} value={props.parent.RK.split('#')[0]}>{props.parent.RK.split('#')[0]}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Departure')}
                                    onChange={e => handleDep(e.target.value)}
                                    value={dep}
                                    error={depError}
                                    helperText={depError ? 'Please departure station' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Destination')}
                                    onChange={e => handleDest(e.target.value)}
                                    value={dest}
                                    error={destError}
                                    helperText={destError ? 'Comma separated list of at least one destination, eg: MUC,HAM' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Packing Plan')}
                                    onChange={e => handlePackingPlan(e.target.value)}
                                    value={packingPlan}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.copyAction(props.parent.RK.split('#')[0], dep, dest, packingPlan) }>COPY!</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default BobForecastCopyDialog;