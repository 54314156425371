import { useState } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';

export interface IBobFreshFoodCreateDialog {
    open : boolean;
    productList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (type : BobFreshFoodType, productCode : string, amount : number, extra : number) => void;
}

function LoadingsCreateDialog(props : IBobFreshFoodCreateDialog) {
    const { t } = useTranslation();
    
    const [ productCode, setProductCode] = useState('');
    const [ bobType, setBobType] = useState(BobFreshFoodType.fixed);
    const [ amount, setAmount] = useState<number | undefined>(0);
    const [ extra, setExtra] = useState(0);

    const [ amountError, setAmountError] = useState(true);
    const [ productError, setProductError] = useState(true);

    const handleAmountChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;

        if(value === undefined || value === '') {
            setAmount(undefined);
            setAmountError(true);
        }
        else {
            const intValue = parseInt(value);
            setAmount(intValue);

            setAmountError(intValue === 0);
        }
    }

    const handleProductChange = (value : string) => {
        setProductCode(value);
        setProductError(value === undefined || value === '');
    }

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Bob Fresh Food</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select the type (relative or fixed) to apply additional Bob Fresh Food loadings.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="servicetype-label">Type</InputLabel>
                        <Select
                            labelId="servicetype-label"
                            id="servicetype-select"
                            value={bobType.toString()}
                            label="Type"
                            onChange={(e) => { setBobType(parseInt(e.target.value)) }}>
                                <MenuItem  key='0' value='0'>Fixed</MenuItem>
                                <MenuItem  key='1' value='1'>Relative</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Product Code')}
                                    onChange={e => handleProductChange(e)}
                                    value={productCode}
                                    error={productError}
                                    helperText={productError ? 'Please enter a product code' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl> */}
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="product-label">Product</InputLabel>
                        <Select
                            labelId="product-label"
                            id="product-select"
                            value={productCode}
                            error={productError}
                            label="Type"
                            onChange={(e) => handleProductChange(e.target.value) }>
                                { props.productList && props.productList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="number"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Amount')}
                                    onChange={e => handleAmountChange(e)}
                                    value={amount}
                                    error={amountError}
                                    helperText={amountError ? 'Please enter the amount' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    { bobType === BobFreshFoodType.relative && (
                        <FormControl sx={{minWidth: 200 }} size="small">
                            <TextField
                                        type="number"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Extra')}
                                        onChange={e => setExtra(parseInt(e.target.value))}
                                        value={extra}
                                        fullWidth
                                        size="small"
                            />
                        </FormControl>
                    )}
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={amountError || productError} onClick={(e) => props.createAction(bobType, productCode, amount ?? 0, extra) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default LoadingsCreateDialog;