import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export interface ICreateSupplier {
    open : boolean;
    cancelAction : () => void;
    createAction : (name : string) => void;
}

function SupplierCreateDialog(props : ICreateSupplier) {
    const { t } = useTranslation();
    
    const [ name, setName] = useState('');
    const [ nameError, setNameError] = useState(true);
    
    const handleName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setName(value ?? '');
        setNameError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return nameError;
    }, [nameError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Supplier</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new supplier.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Name')}
                                    onChange={e => handleName(e)}
                                    value={name}
                                    error={nameError}
                                    helperText={nameError ? 'Please enter a name' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(name)}>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default SupplierCreateDialog;