import { useCallback, useEffect } from 'react'
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../../hooks/useFetch';
import { useMemo } from 'react';
import { DynamoDbObject } from '@aviation/catering-common';
import { Passenger, SsrCode } from '@aviation/catering-flightservice-sdk';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

export interface IPaxListDialogGridProps {
    filterTerm?: string;
    airline?: string;
    flightNo?: string;
    flightDate?: string;
    dep?: string;
    dest?: string;
    exportApi?: (api : GridApiPremium) => void;
    
}

function PaxListDialogGrid(props : IPaxListDialogGridProps) {
    const apiRef = useGridApiRef();
    const { status, data = [] } = useFetch<Array<Passenger & DynamoDbObject>>(`/api/flights/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}/pax`, (response) => response);
    
    const columns: GridColDef[] = [
        { field: 'Lastname', headerName: 'Lastname', minWidth: 150 },
        { field: 'Firstname', headerName: 'Firstname', minWidth: 150 },
        { field: 'BookingNumber', headerName: 'Booking No.', minWidth: 120 },
        { field: 'SeatNo', headerName: 'Seat', minWidth: 70 },
        { field: 'PaxTypeId', headerName: 'Child', minWidth: 70,  type: 'boolean', valueGetter: (params) => params.row.PaxTypeId === 1},
        { field: 'Infant', headerName: 'Infant', minWidth: 70, type: 'boolean'},
        { field: 'SsrCodes', headerName: 'SSR\'s', minWidth: 250, valueGetter: (params) => (params.row.SsrCodes as SsrCode[])?.map(o => o.SsrCode)?.join(', ') ?? '' },
    ];

    useEffect(() => {
        const api = apiRef.current;
        if(props.exportApi !== undefined)
            props.exportApi!(api)

    }, [props.exportApi, apiRef])

    const filterRow = useCallback((pax : Passenger & DynamoDbObject) => {
        if((props.filterTerm?.trim()?.length ?? 0) === 0)
            return true;

        let result = false;
        result = result || (pax.Lastname?.toUpperCase().startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (pax.Firstname?.toUpperCase().startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (pax.BookingNumber?.toUpperCase().startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (pax.SeatNo?.toUpperCase().startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (pax.SsrCodes?.some(o => (o.SsrCode ?? '').toUpperCase() === props.filterTerm!.toUpperCase()) ?? false)
        return result;
    }, [props.filterTerm])

    const rows = useMemo(() => {
        if (!data ) return [];
        
        return data.filter(l => filterRow(l)).map(o => { return { ...o, id: o.RK }}).sort((a, b) => (a.Lastname ?? '') < (b.Lastname ?? '') ? -1 : 1);
    }, [data, filterRow])

    return (

        <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }} 
            disableRowSelectionOnClick
            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
            loading={status === Status.Fetching || status === Status.Idle}
        />
    );
}

export default PaxListDialogGrid;