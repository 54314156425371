import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export interface ICreateAirportDialog {
    open : boolean;
    airlineList : {label: string, value: string}[];
    catererList : {label: string, value: string}[];
    stationGroupList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (airline : string, airportCode : string, catererId : string, stationGroupId : string | undefined, outstation : boolean, priority? : number) => void;
}

function AirportCreateDialog(props : ICreateAirportDialog) {
    const { t } = useTranslation();
    
    const [ airlineCode, setAirlineCode] = useState('');
    const [ airportCode, setAirportCode] = useState('');
    const [ catererId, setCatererId] = useState('');
    const [ stationGroupId, setStationGroupId] = useState<string | undefined>(undefined);
    const [ outstation, setOutstation] = useState(false);
    const [ priority, setPriority] = useState<number | undefined>(undefined);

    const [ airlineCodeError, setAirlineCodeError] = useState(true);
    const [ airportCodeError, setAirportCodeError] = useState(true);
    const [ catererIdError, setCatererIdError] = useState(true);
    const [ stationGroupIdError, setStationGroupIdError] = useState(true);
    const [ priorityError, setPriorityError] = useState(false);

    const handleLtrCode = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setAirportCode(value);
        setAirportCodeError(value === undefined || value === '');
    }

    const handleAirlineCode = (value : string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }

    const handleCaterer = (value : string) => {
        setCatererId(value);
        setCatererIdError(value === undefined || value === '');
    }

    const handleStationGroup = (value : string) => {
        setStationGroupId(value);
        setStationGroupIdError(!outstation && (value === undefined || value === ''));
    }

    const handlePriority = (value : string) => {
        if(value === undefined || value === '') {
            setPriority(undefined);

            if(outstation)
                setPriorityError(true);
        }
        else {
            const prio = parseInt(value);
            setPriority(prio);
            setPriorityError(false);
        }
    }

    const handleOutstation = () => {
        const value = !outstation;
        setOutstation(value);
        
        if(value) {
            setStationGroupId(undefined);
            setStationGroupIdError(false);
            if(priority === undefined)
                setPriorityError(true);
        } else {
            setPriority(undefined);
            setPriorityError(false);

            if(stationGroupId === undefined)
                setStationGroupIdError(true);
        }
    }

    const hasErrors = useMemo(() => {
        return airportCodeError || stationGroupIdError || catererIdError;
    }, [airportCodeError, stationGroupIdError, catererIdError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Airport</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new airport.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={airlineCode}
                            error={airlineCodeError}
                            label="Type"
                            onChange={(e) => handleAirlineCode(e.target.value) }>
                                { props.airlineList && props.airlineList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('3LC')}
                                    onChange={e => handleLtrCode(e)}
                                    value={airportCode}
                                    error={airportCodeError}
                                    helperText={airportCodeError ? 'Please enter a 3LC' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="caterer-label">Default Caterer</InputLabel>
                        <Select
                            labelId="caterer-label"
                            id="caterer-select"
                            value={catererId}
                            error={catererIdError}
                            label="Type"
                            onChange={(e) => handleCaterer(e.target.value) }>
                                { props.catererList && props.catererList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel required control={<Checkbox value={outstation} onChange={() => handleOutstation()} />} label="Is outstation" />
                    </FormGroup>
                    { outstation === false && (
                        <FormControl sx={{minWidth: 200 }} size="small">
                            <InputLabel id="stationgroup-label">Station Group</InputLabel>
                            <Select
                                labelId="stationgroup-label"
                                id="stationgroup-select"
                                value={stationGroupId}
                                error={stationGroupIdError}
                                label="Type"
                                onChange={(e) => handleStationGroup(e.target.value)}>
                                    { props.stationGroupList && props.stationGroupList.map((o)=> (
                                        <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl> 
                    )}
                    { outstation === true && (
                        <FormControl sx={{minWidth: 200 }} size="small">
                            <TextField
                                        type="number"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Priority')}
                                        onChange={e => handlePriority(e.target.value)}
                                        value={priority}
                                        error={priorityError}
                                        helperText={priorityError ? 'Please provide the priority' : ''}
                                        fullWidth
                                        size="small"
                            />
                        </FormControl>
                    )}          
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, airportCode, catererId, stationGroupId, outstation, priority) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default AirportCreateDialog;