import { lighten, useTheme } from '@mui/system';
import { useElementSize } from 'usehooks-ts';
import { CustomTheme } from '../../theme/CustomTheme';
import { CSSProperties, useMemo } from "react";
import { Stack, Typography } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { v4 as uuidv4 } from 'uuid';

function generateValues() {
  return new Array(10).fill(undefined).map((_, i) => ({ x: i, y: Math.random() * 10 }));
}

export interface IDemoChartProps {
  height: CSSProperties["height"];
  title: string;
  subtitle?: string;
  color?: string;
}

function DemoChart({ height: containerHeight, title, subtitle, color: customColor }: IDemoChartProps) {
  const theme = useTheme<CustomTheme>();
  const values = useMemo(generateValues, []);
  const color = customColor || theme.palette.secondary.main;
  const lightColor = lighten(color, 0.7);
  const lighterColor = lighten(lightColor, 0.7);
  const [squareRef, { width, height }] = useElementSize()
  const id = useMemo(uuidv4, []);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        padding={2}
        whiteSpace="nowrap"
      >
        <Typography variant="subtitle1" component="h2">
          {title}
        </Typography>
        {subtitle ? <Typography variant="subtitle1" component="h3" fontWeight="normal" textOverflow="ellipsis" overflow="hidden">
          {subtitle}
        </Typography> : subtitle}
      </Stack>
      <div ref={squareRef} style={{ width: '100%', height: containerHeight }}>
        <AreaChart width={width} height={height} data={values} margin={{ top: 0, left: 0, bottom: 0, right: 0 }} style={{ borderBottomLeftRadius: theme.shape.borderRadius, borderBottomRightRadius: theme.shape.borderRadius }}>

          <defs>
            <linearGradient id={`CoolGradient${id}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={lightColor} />
              <stop offset="100%" stopColor={lighterColor} />
            </linearGradient>
          </defs>
          <Tooltip />
          <Area fill={`url(#CoolGradient${id})`} dot={false} type={'monotoneX'} dataKey="y" stroke={color} strokeWidth={3} />
          <XAxis dataKey="x" hide />
          <YAxis dataKey="y" hide />
        </AreaChart>
      </div>
    </>
  );
};

export default DemoChart;