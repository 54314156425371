import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { ServiceTypeFields, CatererFields } from '@aviation/catering-masterdata-sdk';
import { useState } from 'react';

function ServiceTypes() {
    const { clientCode, airline } = useParams();
    const { status, data = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}/${airline}`);
    const [ tabIndex, setTabIndex] = useState(0);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { status: catererStatus, data: catererData = [], error: catererError } = useFetch<CatererFields & DynamoDbObject>(`/api/masterdata/caterer/${clientCode}/${airline}`); //TODO: handle status

    

    const columns: GridColDef[] = [
        { field: 'Code', headerName: 'Code', minWidth: 50 },
        { field: 'Type', headerName: 'Type', minWidth: 100 },
        { field: 'CsvHeader', headerName: 'CSV Header', minWidth: 100 },
        { field: 'SortIndex', headerName: 'Sort idx', minWidth: 100 },
        { field: 'Priority', headerName: 'Priority', minWidth: 100 },
        { field: 'SsrCodes', headerName: 'Ssr Codes', minWidth: 150, valueGetter: (params) => (params.row.SsrCodes && params.row.SsrCodes.join(', ')) || ""},
        { field: 'Functions', headerName: 'Crew Functions', minWidth: 150, valueGetter: (params) => (params.row.Functions && params.row.Functions.join(', ')) || ""},
        { field: 'HaulTypes', headerName: 'Haultype Filter', minWidth: 150, valueGetter: (params) => (params.row.HaulTypes && params.row.HaulTypes.join(', ')) || ""},
        { field: 'AcRegs', headerName: 'AcReg Filter', minWidth: 150, valueGetter: (params) => (params.row.AcRegs && params.row.AcRegs.join(', ')) || ""},
    ];

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
      };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                            Service Types
                        </Typography>
                        
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}} />
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="SSR" />
                        <Tab label="Crew" />
                    </Tabs>
                </Paper>
                
                
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <DataGridPremium
                        autoHeight 
                        rows={data}
                        columns={columns}
                        // pageSize={10}
                        // rowsPerPageOptions={[10]}
                        pagination
                        getRowId={(itm) => `${itm.PK}#${itm.RK}`}
                        loading={status === Status.Fetching || status === Status.Idle}
                        // error={error}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ServiceTypes;