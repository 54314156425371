import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Paper, Button, Stack, Box, Typography, Tabs, Tab, } from '@mui/material';
// import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import BobForecastRoutes from './BoBForecastRoutes';
import BobForecastPackagePlans from './BoBForecastPackagePlans';

function BoBForecast() {
    const [ tabIndex, setTabIndex] = useState(0);
    const [ showCreatePackagePlan, setShowCreatePackagePlan] = useState(false);
    const [ showCreateRoute, setShowCreateRoute] = useState(false);
    const { clientCode } = useParams();
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };



    return (
        <Grid container spacing={3}>
            {/* <Grid item xs={12}>
                <PageHeader title={t('BoB Forecast')}>
                <Stack direction='row' spacing={2}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </Stack>
                </PageHeader>
            </Grid> */}
            <Grid item xs={12}>
                <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                            BoB Forecast
                        </Typography>
                        
                    </Box>
                    <Box flexGrow={0}>
                        <Stack direction='row' spacing={2}>
                            <Button variant="contained" onClick={() => tabIndex === 0 ? setShowCreatePackagePlan(true) : setShowCreateRoute(true)}>Create</Button>
                            {/* <Button variant="contained">Create</Button> */}
                        </Stack>
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}} />
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Packing Plans" />
                        <Tab label="Routes" />
                    </Tabs>
                </Paper>
            </Grid>
            { tabIndex === 0 ? <BobForecastPackagePlans open={true} clientCode={clientCode ?? 'BNX'} showCreateDialog={showCreatePackagePlan} cancelAction={() => setShowCreatePackagePlan(false)} createAction={(item) => setShowCreatePackagePlan(false)} /> : <BobForecastRoutes open={true} clientCode={clientCode ?? 'BNX'} showCreateDialog={showCreateRoute} cancelAction={() => setShowCreateRoute(false)} /> }
        </Grid>
    );
}

export default BoBForecast;