
import { Components } from '@mui/material';
import { CustomTheme } from '../CustomTheme';

// ----------------------------------------------------------------------

export default function Typography(theme: CustomTheme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  } as Components['MuiTypography'];
}
