import { useCallback, useState } from 'react'
import { Stack, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { AirlineFields, CatererFields, HaulTypeFields, LoadingPlanFields } from '@aviation/catering-masterdata-sdk';
import { CateringRouteFields } from '@aviation/catering-cateringrouteservice-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';

export interface ICreateRouteDialogPropertiesProps {
    clientCode: string
    airlineData: Array<AirlineFields & DynamoDbObject>;
    loadingPlanData: Array<LoadingPlanFields & DynamoDbObject>;
    haulTypesData: Array<HaulTypeFields & DynamoDbObject>;
    catererData: Array<CatererFields & DynamoDbObject>;
    route: CateringRouteFields & DynamoDbObject;
    validationExecuted: (item : CateringRouteFields & DynamoDbObject, valid : boolean) => void;
}

export function CreateRouteDialogProperties(props: ICreateRouteDialogPropertiesProps) {
    const [airline, setAirline] = useState<string | undefined>((props.route as any).airline);
    const [currentRoute, setCurrentRoute] = useState<CateringRouteFields & DynamoDbObject>(props.route);
    const [flightDate, setFlightDate] = useState<dayjs.Dayjs>(dayjs(props.route.FlightDate));

    const validate = useCallback(() => {
        const requiredFields : (keyof CateringRouteFields)[] = ['DefaultCatererId', 'LoadingPlanId', 'HaulType', 'IsDutyFree', 'FlightDate'];
        return requiredFields.reduce((a, b) => {
            return a && currentRoute[b] !== undefined;
        }, true);
    }, [currentRoute]);

    const updateField = useCallback((field: string, value: any) => {
        if(field === 'FlightDate' && value === 'Invalid Date')
            value = undefined;

        (currentRoute as any)[field] = value;
        const item = {...currentRoute};
        setCurrentRoute(item)

        props.validationExecuted(item, validate());
    }, [currentRoute, props, validate]);

    

    return (
        <Stack direction='column' spacing={2} style={{padding:20}}>
            <FormControl sx={{ minWidth: 200 }} size="small">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                <DatePicker
                    value={flightDate}
                    label="Flight Date"
                    //error={currentRoute.FlightDate === undefined}
                    onChange={(e) => { setFlightDate(e ?? dayjs()); updateField('FlightDate', e?.format('YYYY-MM-DD')); }}
                    slotProps={{ textField: { size: 'small' } }}
                />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="airline-label">Airline</InputLabel>
                <Select
                    labelId="airline-label"
                    id="airline-select"
                    value={airline}
                    label="Airline"
                    error={airline === undefined}
                    onChange={(e) => { setAirline(e.target.value); (props.route as any).airline = e.target.value }}
                >
                    {props.airlineData.map((a) => (
                        <MenuItem key={a.RK} value={a.RK}>{a.Description}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="caterer-label">Default Caterer</InputLabel>
                    <Select
                        labelId="caterer-label"
                        id="caterer-select"
                        value={currentRoute.DefaultCatererId ?? ''}
                        label="Default Caterer"
                        error={currentRoute.DefaultCatererId === undefined }
                        onChange={(e) => { updateField('DefaultCatererId', e.target.value) }}
                    >
                        {props.catererData.sort((a,b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1).map((type) => (
                            <MenuItem key={type.RK} value={type.RK}>{type.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            {airline && [
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="plan-type-label">Plan Type</InputLabel>
                    <Select
                        labelId="plan-type-label"
                        id="plan-type-select"
                        value={currentRoute.LoadingPlanId ?? ''}
                        label="Plan Type"
                        error={currentRoute.LoadingPlanId === undefined }
                        onChange={(e) => { updateField('LoadingPlanId', e.target.value) }}
                    >
                        {props.loadingPlanData.filter(type => type.RK.split('#')[0] === airline).sort((a,b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1).map((type) => (
                            <MenuItem key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>,
                <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="duty-free-label">Duty free</InputLabel>
                    <Select
                        labelId="duty-free-label"
                        id="duty-free-select"
                        value={currentRoute.IsDutyFree ?? false}
                        onChange={(e) => { updateField('IsDutyFree', e.target.value === 'true') }}
                        label="Duty free"
                        itemScope
                    >
                        <MenuItem key='false' value={'false'}>No duty free</MenuItem>
                        <MenuItem key='true' value={'true'}>Duty free</MenuItem>
                    </Select>
                </FormControl>,

                <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <InputLabel id="haul-type-label">Haul type</InputLabel>
                    <Select
                        labelId="haul-type-label"
                        id="haul-type-select"
                        value={currentRoute.HaulType ?? ''}
                        error={currentRoute.HaulType === undefined }
                        onChange={(e) => { updateField('HaulType', e.target.value) }}
                        label="Haul Type"
                        itemScope
                    >
                        {props.haulTypesData.filter(type => type.RK.split('#')[0] === airline).sort((a,b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1).map((type) => (
                            <MenuItem key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ]}
        </Stack>
    )
}

export default CreateRouteDialogProperties;