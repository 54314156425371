import { Components } from "@mui/material";
import { CustomTheme } from "../CustomTheme";

// ----------------------------------------------------------------------

export default function AppBar(theme: CustomTheme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.primary.main,
        },
        positionFixed: {
          zIndex: theme.zIndex.drawer + 1,
        },
        root: {
          boxShadow: 'none',
        },
      },
    } as Components['MuiAppBar'],
  };
}
