import { useState, useCallback } from 'react'
import { Stack, Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

export interface IBobForecastPackagePlanCopyDialog {
    open : boolean;
    
    cancelAction : () => void;
    copyAction : (validFrom : Dayjs | undefined, validTo : Dayjs | undefined) => void;
    updateAction : (validFrom : Dayjs | undefined, validTo : Dayjs | undefined) => void;
}

function BobForecastPackagePlanCopyDialog(props : IBobForecastPackagePlanCopyDialog) {
    const [validFrom, setValidFrom] = useState<dayjs.Dayjs | undefined>(undefined);
    const [validTo, setValidTo] = useState<dayjs.Dayjs | undefined>(undefined);
    
    
    const updateField = useCallback((field: string, value: any) => {
        if((field === 'ValidFrom' || field === 'ValidTo') && value === 'Invalid Date')
            value = undefined;

        if(value !== undefined) {
            value = dayjs(value);
        }

        switch(field) {
            case 'ValidFrom':
                setValidFrom(value);
            break;

            case 'ValidTo':
                setValidTo(value);
            break;

            default:

            break;
        }
    }, []);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Bulk Edit</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can set new valid from/ to dates on selected items or copy them with new from/ to dates by clicking on 'Copy'.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DatePicker
                            value={validFrom}
                            label="Valid from"
                            onChange={(e) => { setValidFrom(e ?? dayjs()); updateField('ValidFrom', e?.format('YYYY-MM-DD')); }}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DatePicker
                            value={validTo}
                            label="Valid to"
                            onChange={(e) => { setValidTo(e ?? dayjs()); updateField('ValidTo', e?.format('YYYY-MM-DD')); }}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button onClick={(e) => { props.copyAction(validFrom, validTo)} }>Copy</Button>
                <Button onClick={(e) => { props.updateAction(validFrom, validTo)} }>Save</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default BobForecastPackagePlanCopyDialog