import LinkBehavior from './LinkBehavior';
import { CustomTheme } from '../CustomTheme';
import { Components } from '@mui/material';

// ----------------------------------------------------------------------

export default function ListItem(theme: CustomTheme) {
  return {
    MuiListItemButton: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
          marginRight: 10,
          marginLeft: 10,
          paddingRight: 10,
          paddingLeft: 10,
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
            '& .MuiListItemIcon-root': {
              color:  theme.palette.primary.main,
            }
          }
        },
        selected: {
        }
      }
    } as Components['MuiListItemButton'],    
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      }
    } as Components['MuiListItemIcon'],
    MuiListSubheader: {
      styleOverrides: {
        root: {
          paddingRight: 10,
          paddingLeft: 10,
          fontSize: theme.typography.subtitle1.fontSize,
          color: theme.palette.primary.main
        }
      }
    } as Components['MuiListSubheader'],
  };
}
