// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider  } from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { CustomTheme, CustomThemeOptions } from './CustomTheme';

// ----------------------------------------------------------------------

declare module '@mui/material/styles' {
  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

const theme = createTheme({
  palette,
  shape: { borderRadius: 6 },
  typography,
  shadows: shadows(),
  customShadows: customShadows(),
})
theme.components = componentsOverride(theme);


export default function ThemeProvider({ children }: React.PropsWithChildren) {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
