import { API } from 'aws-amplify';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BobFreshFoodGrid from './BobFreshFoodGrid'
import { Paper, Button, Box } from '@mui/material';

import BoxHeader from '../../../common/Components/BoxHeader'
import { useFetch } from '../../../hooks/useFetch';
import { BobFreshFood, BobFreshFoodFields, BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';
import BobFreshFoodCreateDialog from './BobFreshFoodCreateDialog'
import { ulid } from 'ulidx';
import {toast} from "react-toastify";
import { Status } from '../../../hooks/useFetch';
import { SsrCodeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';

export interface IBobFreshFoodContainerProps {
    flightDate : string;
    airline : string;
    flightNo : string;
    dep : string;
    dest : string;
    routePk : string;
}
function BobFreshFoodContainer(props : IBobFreshFoodContainerProps) {
    const { status: foodStatus, data: foodData = [], error: foodError } = useFetch<{ [key: string]: any }>(`/api/bobfreshfood/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}`, (response) => response);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [data, setData] = useState<BobFreshFood | undefined>(undefined);
    const { clientCode } = useParams();
    const [ loading, setLoading] = useState(false);
    const { data: productData = [] } = useFetch<Array<SsrCodeFields & DynamoDbObject>>(`/api/masterdata/ssrcode/${clientCode}`);

    // Transform data coming from PaxMealService into rows to display tree like data 
    // in grid. Therefore we need a flat list.
    const aggregatedData = useMemo(() => {
        if (!data) return [];

        if(data.Entries === undefined)
            return [];

        const result = ((data.Entries ?? []) as BobFreshFoodFields[]);
        const transformed = result.map((o : BobFreshFoodFields) => { return {...o, id: o.EntryId ?? ''}})
        return transformed;
    }, [data]);

    const productList = useMemo(() => {
        return productData.filter(o => o.IsProduct === true).map(o => { return {label: (o.RK + ' - ' + o.Description ?? ''), value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [productData])

    useEffect(() => {
        if(foodData === undefined)
            return;

        setData(foodData as BobFreshFood);
    }, [foodData]);

    const saveItem = useCallback(async (item : BobFreshFoodFields) : Promise<boolean> =>  {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/bobfreshfood/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    }, [props.flightDate, props.airline, props.flightNo, props.dep, props.dest]);

    const createItem = useCallback(async (type : BobFreshFoodType, productCode : string, amount : number, extra : number) => {
        setOpenCreateDialog(false);
        setLoading(true);

        const paxCount = type === BobFreshFoodType.fixed ? (data?.Leg?.PaxCount ?? 0) : ((data as any)?.Route?.UniquePax ?? 0);
        
        const item : any = {
            Type: type,
            EntryId: ulid(),
            ProductCode: productCode,
            Amount: amount,
            Extra: type === BobFreshFoodType.fixed ? undefined : extra,
            PaxCount: paxCount,
            ProductCount: type === BobFreshFoodType.fixed ? amount : (Math.ceil((amount / 100.0) * paxCount) + extra),
            IsFixed: true,
            IsManual: true,
            GSI1PK: `${clientCode}#${props.flightDate.substring(0,7)}`,
            GSI1RK: props.flightDate
        }

        if(await saveItem(item)) {
            setLoading(false);
            if(data?.Entries !== undefined) {
                data.Entries = [...data.Entries, item];
                setData({...data});
            }
            toast.success(`Bob Fresh Food added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating Bob Fresh Food`);
        }

        
    }, [data, clientCode, props.flightDate, saveItem]);

    const updateItem = async(item : BobFreshFoodFields) : Promise<boolean> => {
        const result = await saveItem(item);

        if(result)
            toast.success(`Changes to Bob Fresh Food saved successfully.`);
        else
            toast.error(`An error occurred while saving Bob Fresh Food`);

        return result;
    }

    const deleteItem = async(item : BobFreshFoodFields) : Promise<boolean> => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.del('api', `/api/bobfreshfood/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}`, init);
            toast.success(`Bob Fresh Food deleted successfully.`);
            return true;
        } catch(e) {
            console.error(e);
            toast.error(`An error occurred while deleting Bob Fresh Food`);
            return false;
        }
    }

    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={3}>
                <BoxHeader title='Bob Fresh Food'>
                    <Button variant="contained" onClick={() => setOpenCreateDialog(true) }>Create Fresh Food</Button>
                </BoxHeader>
                { openCreateDialog === true ? (
                    <BobFreshFoodCreateDialog open={openCreateDialog} productList={productList}
                        cancelAction={() => {setOpenCreateDialog(false)}} createAction={createItem} />
                    ) : null 
                }
                <Box>
                    <BobFreshFoodGrid legInfo={data?.Leg ?? {}} productList={productList} aggregatedData={aggregatedData} loading={(foodStatus === Status.Fetching) || loading} error={foodError} updateAction={updateItem} deleteAction={deleteItem} />
                </Box>
                {/* <Box>
                    <Button variant="contained" onClick={() => save() }>
                        Save
                    </Button>
                </Box> */}
            </Paper>
        </Grid>
    )
}

export default BobFreshFoodContainer;