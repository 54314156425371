import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Box, } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { LoadingPlanPerDestinationEntry, LoadingPlanPerDestinationFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import { ulid } from 'ulidx';

export interface ILoadingPlanPerDestDetailsProps {
    open: boolean;
    parent: LoadingPlanPerDestinationFields & DynamoDbObject;
    loadingPlanList : {label: string, value: string}[];
    catererList : {label: string, value: string}[];
    haulTypeList : {label: string, value: string}[];
    cancelAction: () => void;
    updateAction: (type: BobFreshFoodType, productCode: string, amountFixed?: number, percentAm?: number, percentPm?: number, extra?: number) => void;
}

function LoadingPlanPerDestDetails(props: ILoadingPlanPerDestDetailsProps) {
    const apiRef = useGridApiRef();
    const { clientCode } = useParams();

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);

    const rowId = (row : any) => {
        return `${row.EntryId}`;
    }

    const rows = useMemo(() => {
        if (props.parent === undefined || props.parent?.Entries === undefined)
            return [];

        const result = props.parent.Entries.map((entry: any) => {
            return {
                id: rowId(entry),
                EntryId: entry.EntryId,
                LoadingPlanId: entry.LoadingPlanId,
                CatererId: entry.CatererId,
                HaulType: entry.HaulType,
                IsDutyFree: entry.IsDutyFree,
                PK: props.parent.PK,
                RK: props.parent.RK,
                isNew: entry.isNew ?? false
            }
        });
        return result;
        // eslint-disable-next-line
    }, [props.parent, props.parent.Entries])

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            const rowList = props.parent.Entries?.filter((o : any) => o.EntryId !== id) 
            props.parent.Entries = rowList;
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [props.parent]);

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: true }]);
            
            // Construct BobConfigurationItem without deleted item
            let item = {...props.parent};
            item.Entries = props.parent.Entries!.filter(o => o.EntryId !== deleteItem.EntryId);
           

            if(await updateConfiguration(item)) {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, _action: 'delete' }]);
                toast.success(`Loading Plan deleted successfully.`);
                props.parent.Entries = item.Entries;
                return true;
            } else {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: false }]);
                toast.error(`An error occurred while deleting loading plan`);
            }
        }
    };

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as LoadingPlanPerDestinationFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to loading plan saved successfully.`);
            else
                toast.error(`An error occurred while saving loading plan`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent]);

    const updateItem = async(item : any) => {
        let entry = {...props.parent.Entries!.find((o : any) => o.EntryId === item.EntryId) as any};

        if(entry === undefined) {
            entry = {
                EntryId: item.EntryId,
                LoadingPlanId: item.LoadingPlanId,
                CatererId: item.CatererId,
                HaulType: item.HaulType,
                IsDutyFree: item.IsDutyFree
            }
        } 

        entry.LoadingPlanId = item.LoadingPlanId;
        entry.CatererId = item.CatererId;
        entry.HaulType = item.HaulType;
        entry.IsDutyFree = item.IsDutyFree;
        entry.isNew = false;
        
        props.parent.Entries = [entry!, ...props.parent.Entries!.filter((o : any) => o.EntryId !== (item as any).EntryId)];
        return await updateConfiguration(props.parent);
    }

    const updateConfiguration = async (item : LoadingPlanPerDestinationFields) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/loadingplanperdestination/${clientCode}/${(props.parent as any).Al}/${props.parent.RK.split('#')[1]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        // { field: 'Type', headerName: 'Type', minWidth: 70, editable: true, type: 'singleSelect', valueOptions: typeList },
        { field: 'LoadingPlanId', headerName: 'Loading Plan', minWidth: 150, editable: true, type: 'singleSelect', valueOptions: props.loadingPlanList },
        { field: 'CatererId', headerName: 'Caterer', minWidth: 150, editable: true, type: 'singleSelect', valueOptions: props.catererList },
        { field: 'HaulType', headerName: 'Haul Type', minWidth: 60, editable: true, type: 'singleSelect', valueOptions: props.haulTypeList },
        { field: 'IsDutyFree', headerName: 'Duty Free', minWidth: 60, type: 'boolean', editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                if(row.Parent === true) return [
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                ]
                else
                    return ( 
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const createItem = () => {
        const id = ulid().toString();
        const row = { id: id, EntryId: id, CatererId: '', LoadingPlanId: '', HaulType: '', IsDutyFree: false, isNew: true } as LoadingPlanPerDestinationEntry;
        
        props.parent.Entries = [...props.parent.Entries!, row];
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'LoadingPlanId' },
        }));
    };

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Loading Plan ' + props.parent.RK.split('#')[1]}>
                    <Button variant="contained" onClick={createItem}>Create</Button>
                </BoxHeader>
            </Box>
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => true}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Configuration?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this loadingplan configuration? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default LoadingPlanPerDestDetails;