import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Box, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { BoBPackagePlan, BobConfigurationFields, BobPackagePlanAssignment } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import { ulid } from 'ulidx';
import dayjs from 'dayjs';
import { useFetch } from '../../hooks/useFetch';

export interface IBobForecastRoutePackagePlanProps {
    open: boolean;
    clientCode : string;
    parent: BobConfigurationFields & DynamoDbObject;
    cancelAction: () => void;
}

function BobForecastRoutePackagePlan(props: IBobForecastRoutePackagePlanProps) {
    const apiRef = useGridApiRef();
    const { clientCode } = useParams();
    
    const { data = [] } = useFetch<Array<BoBPackagePlan & DynamoDbObject>>(`/api/masterdata/bobpackageplan/${props.clientCode}`,(response) => response.Items);

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);

    const rowId = (row : any) => {
        return `${row.EntryId}`;
    }

    const planList = useMemo(() => {
        const list = data.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id, entries: o.Entries } }).sort((a,b) => a.label < b.label ? -1 : 1);
        const defaultItems = [{ label: '(None)', value: undefined, entries: []}];
        return defaultItems.concat(list as any[]);
    }, [data])

    const rows = useMemo(() => {
        if (props.parent === undefined || props.parent?.Plans === undefined)
            return [];

        const result = (props.parent.Plans?.map((entry: BobPackagePlanAssignment) => {
            return {
                ...entry,
                id: rowId(entry)
            }
        }) ?? []).sort((a,b) => (a.ValidFrom ?? '') < (b.ValidFrom ?? '') ? 1 : ((a.ValidTo ?? '') < (b.ValidTo ?? '') ? 1 : -1));
        return result as any[];
        // eslint-disable-next-line
    }, [props.parent, props.parent.Plans])

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            const rowList = props.parent.Plans!.filter((o : any) => o.EntryId !== id) 
            props.parent.Plans = rowList;
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [props.parent]);

    const getActivePackagePlans = (item : BobConfigurationFields | undefined) => {
        if(item?.Plans === undefined) 
            return '';

            
        const now = dayjs().utc().toISOString();

        const filtered = item.Plans.filter(o => {
            return (o.ValidFrom === undefined || o.ValidFrom === null || dayjs(o.ValidFrom) <= dayjs(now)) &&
                (o.ValidTo === undefined || o.ValidTo === null || dayjs(o.ValidTo) >= dayjs(now)); 
        })

        const set = new Set<string>();
        filtered.forEach(o => set.add(o.Name ?? '(Unkown)'));

        return Array.from(set).sort((a,b) => a < b ? -1 : 1).join(', ');
    }

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: true }]);
            
            // Construct BobConfigurationItem without deleted item
            let item = {...props.parent};
            item.Plans = props.parent.Plans!.filter(o => o.EntryId !== deleteItem.EntryId);
           
            // Update display value for assigned package plans
            const packingPlans = getActivePackagePlans(item);
            item.PackingPlan = packingPlans;

            if(await updateConfiguration(item)) {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, _action: 'delete' }]);
                toast.success(`Packing plan assignment deleted successfully.`);
                props.parent.Plans = item.Plans;
                return true;
            } else {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: false }]);
                toast.error(`An error occurred while deleting packing plan assignment`);
            }
        }
    };

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as BobConfigurationFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Packing plan assignment saved successfully.`);
            else
                toast.error(`An error occurred while saving packing plan assignment`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent, data]);

    const updateItem = async(item : any) => {
        let entry = {...props.parent.Plans!.find((o : any) => o.EntryId === item.EntryId) as any};

        if(entry === undefined) {
            entry = {
                EntryId: item.EntryId,
                Id: item.Id,
                Name: item.Name,
                ValidFrom: item.ValidFrom,
                ValidTo: item.ValidTo
            }
        } 

        // Get package plan
        const planData = data.find(o => o.Id === item.Id);

        entry.Id = item.Id;
        entry.Name = planData?.Name ?? '(Unkown)';
        entry.ValidFrom = item.ValidFrom;
        entry.ValidTo = item.ValidTo;
        entry.Entries = planData?.Entries ?? [];
        //props.parent.PackingPlan
        
        props.parent.Plans = [entry!, ...props.parent.Plans!.filter((o : any) => o.EntryId !== (item as any).EntryId)];

        // Update display value for assigned package plans
        const packingPlans = getActivePackagePlans(props.parent);
        props.parent.PackingPlan = packingPlans;

        return await updateConfiguration(props.parent);
    }

    const updateConfiguration = async (item : BobConfigurationFields) => {
        const init = {
            body: item,
            headers: {}
        };

        // Strip unnecessary properties
        init.body.Entries?.forEach(o => delete (o as any).isNew);
        init.body.Plans?.forEach(o => delete (o as any).isNew);

        try {
            await API.put('api', `/api/masterdata/bobconfiguration/${clientCode}/${(props.parent as any).Al}/${props.parent.RK.split('#')[1]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Id', headerName: 'Name', minWidth: 150, editable: true, type:'singleSelect', valueOptions: planList },
        { field: 'ValidFrom', headerName: 'Valid from', minWidth: 60, type: 'date', editable: true, valueFormatter: (params) => {
            return (params.value === undefined || params.value === null) ? undefined : dayjs.utc(params.value).format('DD.MM.YYYY') 
        }, valueSetter(params) {
            // Selection is in local time, we have to treat it in UTC, because all flight times are in UTC,
            // so we convert it manually into a string. otherwise we get the previous day because of UTC vs. GMT.
            if(params.value !== 'Invalid Date' && params.value !== null && params.value !== undefined) {
                let value = dayjs(params.value);
                return {...params.row, ValidFrom: value.format('YYYY-MM-DD')};
            } else
                return {...params.row, ValidFrom: undefined };
            
        } },
        { field: 'ValidTo', headerName: 'Valid to', minWidth: 60, type: 'date', editable: true, valueFormatter: (params) => (params.value === undefined || params.value === 'Invalid Date') ? undefined : dayjs.utc(params.value).format('DD.MM.YYYY'), valueSetter(params) {
            // Selection is in local time, we have to treat it in UTC, because all flight times are in UTC,
            // so we convert it manually into a string. otherwise we get the previous day because of UTC vs. GMT.
            if(params.value !== 'Invalid Date' && params.value !== null && params.value !== undefined) {
                let value = dayjs(params.value);
                return {...params.row, ValidTo: value.format('YYYY-MM-DD')};
            } else
                return {...params.row, ValidTo: undefined};
        } 
         },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                if(row.Parent === true) return [
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                ]
                else
                    return ( 
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const createItem = () => {
        const id = ulid().toString();
        
        const item : BobPackagePlanAssignment = {
            EntryId: id,
            Id: undefined,
            Name: '(Unkown)',
            ValidFrom: undefined,
            ValidTo: undefined,
            Entries: []
        }

        const row = { ...item, id: rowId(item), isNew: true};
        
        props.parent.Plans = [...props.parent.Plans ?? [], row];
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
        }));
    };

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Assigned Packing Plans'}>
                    <Button variant="contained" onClick={createItem}>Create</Button>
                </BoxHeader>
            </Box>
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => true}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Packing Plan?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this packing plan assignment? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default BobForecastRoutePackagePlan;