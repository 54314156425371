import Grid from '@mui/material/Grid';
import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import AuditLogGrid from './AuditLogGrid';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/de';
import { useLocation } from 'react-router-dom';

function AuditLog() {
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs().add(-2, 'day'), dayjs().add(1, 'day')]);
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        let pageState = { tabIndex: newValue };
        localStorage.setItem(`PageState_${location.pathname}`, JSON.stringify(pageState));
        setTabIndex(newValue);
      };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                            Audit Log
                        </Typography>
                        <Stack direction='row' spacing={2} style={{float:'right', marginTop:-20, marginBottom:10}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                            </LocalizationProvider>
                        </Stack>
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}}>
                        
                    </div>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Meal Order" />
                        <Tab label="Bob Fresh Food" />
                        <Tab label="Routes" />
                        <Tab label="Other" />
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <AuditLogGrid dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} tabIndex={tabIndex} />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default AuditLog;