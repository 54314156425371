import { useState, useMemo, useEffect } from 'react'
import { Stack, Button, Box, TextField, SvgIcon, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { UserFields } from '@aviation/catering-masterdata-sdk';
import { ulid } from 'ulidx';
import { ReactComponent as CopyIcon } from '../../icons/copy.svg';
import {toast} from "react-toastify";

export interface IResetPasswordDialog {
    open : boolean;
    user? : UserFields;
    cancelAction : () => void;
    createAction : (user : UserFields, password : string) => void;
}

function UserResetPassDialog(props : IResetPasswordDialog) {
    const { t } = useTranslation();
    
    const [ password, setPassword] = useState('');
    const [ passwordError, setPasswordError] = useState(true);
    
    const handlePassword = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setPassword(value);
        setPasswordError(value === undefined || value === '' || value.length < 8);
    }

    const hasErrors = useMemo(() => {
        return passwordError;
    }, [passwordError]);

    useEffect(() => {
        const randomId = ulid().toString();
        const symbols = ['!', '#', '-', '_', '?', '§', '$', '=', '+', '%'];
        const number = Math.round(Math.random() * 10);
        
        let pass = `${randomId.substring(10, 4).toLocaleLowerCase()}${randomId.substring(15, 19)}`;
        pass = `${pass.substring(0, number)}${symbols[number]}${pass.substring(number+1, pass.length - 1)}`;
        setPassword(pass);
        setPasswordError(false);
    }, [props.user])

    const copyText = () => {
        navigator.clipboard.writeText(password);
        toast.success('Password has been copied!');
    }

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a password with 8 characters at least and click on 'Reset'. {props.user?.FamilyName}, {props.user?.GivenName} won't get a notification automatically!
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <Stack direction="row">
                        <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Password')}
                                        onChange={e => handlePassword(e)}
                                        value={password}
                                        error={passwordError}
                                        helperText={passwordError ? 'Please enter 8 characters at least' : ''}
                                        fullWidth
                                        size="small"
                            />
                            <IconButton color="primary" onClick={() => { copyText() }}>
                                <SvgIcon component={CopyIcon} inheritViewBox />
                            </IconButton>
                        </Stack>
                        
                    </FormControl>  
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} color="error" onClick={(e) => props.createAction(props.user!, password)}>Reset</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default UserResetPassDialog;