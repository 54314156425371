
import { useMemo } from 'react';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';

import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../../common/Components/StyledBox'
import { CrewMealFields, CrewMealCrew } from '@aviation/catering-crewmealservice-sdk'

export interface ICrewInfoContainerProps {
    data : Array<CrewMealFields & DynamoDbObject>;
}
function CrewInfoContainer(props : ICrewInfoContainerProps) {
    const apiRef = useGridApiRef();

    const columns: GridColDef[] = [
        { field: 'LtrCode', headerName: '3LC', minWidth: 100 },
        { field: 'Surname', headerName: 'Surname', minWidth: 180, editable: false },
        { field: 'Firstname', headerName: 'Firstname', minWidth: 100, editable: false },
        { field: 'Function', headerName: 'Function', minWidth: 100, editable: false },
    ];

    const aggregatedData = useMemo(() => {
        if (!props.data) return [];

        const crewList : CrewMealCrew[] = [];

        props.data.forEach(o => {
            o.CrewInfo?.forEach(c => {
                if(!crewList.find(i => i.LtrCode === c.LtrCode && i.ClientCode === c.ClientCode)) {
                    (c as any).id = `${c.ClientCode}#${c.LtrCode}`
                    crewList.push(c);
                }
            })
        });
        
        return crewList.sort((a,b) => (a.LtrCode ?? '') < (b.LtrCode ?? '') ? -1 : 1)
    }, [props.data]);

    return (
        
            <StyledBox>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={aggregatedData}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                    isCellEditable={(params) => (params.row.Type ?? 0) === BobFreshFoodType.fixed && params.colDef.field === 'Extra' ? false : true }
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                />
        </StyledBox>
        
    )
}

export default CrewInfoContainer;