import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export interface ICreateCatererPerLegDialog {
    open : boolean;
    airlineList : {label: string, value: string}[];
    serviceTypeList : {label: string, value: string, airline : string}[];
    catererList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (airline : string, dep : string, dest : string, catererId : string, loadingTypes : string[]) => void;
}

function CatererPerLegCreateDialog(props : ICreateCatererPerLegDialog) {
    const { t } = useTranslation();
    
    const [ airlineCode, setAirlineCode] = useState('');
    const [ dep, setDep] = useState('');
    const [ dest, setDest] = useState('');
    const [ catererId, setCatererId] = useState<string | undefined>(undefined);
    const [ loadingTypes, setLoadingTypes] = useState<string[]>([]);
    
    const [ airlineCodeError, setAirlineCodeError] = useState(true);
    const [ depError, setDepError] = useState(true);
    const [ destError, setDestError] = useState(true);
    const [ catererIdError, setCatererIdError] = useState(true);
    const [ loadingTypesError, setLoadingTypesError] = useState(true);

    const handleDest = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setDest(value);
        setDestError(value === undefined || value === '');
    }

    const handleDep = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setDep(value);
        setDepError(value === undefined || value === '');
    }

    const handleAirlineCode = (value : string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }

    const handleCaterer = (value : string) => {
        setCatererId(value);
        setCatererIdError(value === undefined || value === '');
    }


    const hasErrors = useMemo(() => {
        return airlineCodeError || depError || destError || loadingTypesError;
    }, [airlineCodeError, depError, destError, loadingTypesError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Caterer Per Leg</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new airport.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={airlineCode}
                            error={airlineCodeError}
                            label="Type"
                            onChange={(e) => handleAirlineCode(e.target.value) }>
                                { props.airlineList && props.airlineList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Departure')}
                                    onChange={e => handleDep(e)}
                                    value={dep}
                                    error={depError}
                                    helperText={depError ? 'Please enter a departure station' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Destination')}
                                    onChange={e => handleDest(e)}
                                    value={dest}
                                    error={destError}
                                    helperText={destError ? 'Please enter a destination station' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="caterer-label">Caterer</InputLabel>
                        <Select
                            labelId="caterer-label"
                            id="caterer-select"
                            value={catererId}
                            error={catererIdError}
                            label="Type"
                            onChange={(e) => handleCaterer(e.target.value) }>
                                { props.catererList && props.catererList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>   
                    <FormControl sx={{minWidth:200}} size="small">
                        <InputLabel id="loadingTypes-label">Loading Types</InputLabel>
                    <Select
                        labelId="loadingTypes-label"
                        id="loadingTypes-checkbox"
                        multiple
                        fullWidth
                        error={loadingTypesError}
                        value={loadingTypes}
                        onChange={(e, c) => {
                            
                            }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {props.serviceTypeList.filter(o => {
                            return o.airline === airlineCode
                        }).map((serviceType) => (
                            <MenuItem key={serviceType.value} value={serviceType.value}>
                            <Checkbox checked={(loadingTypes ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    let list : string[] = [];
                                    list = list.concat(loadingTypes);
                                    const idx = list.indexOf(serviceType.value);

                                    if(idx !== -1) {
                                        list.splice(idx, 1);
                                        setLoadingTypes(list);
                                        setLoadingTypesError(list.length === 0);
                                    } else {
                                        list.push(serviceType.value);
                                        setLoadingTypes(list);
                                        setLoadingTypesError(list.length === 0);
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={serviceType.value} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>    
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, dep, dest, catererId ?? '', loadingTypes) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default CatererPerLegCreateDialog;