
import { Components } from '@mui/material';
import { CustomTheme } from '../CustomTheme';

// ----------------------------------------------------------------------

export default function Paper(theme: CustomTheme) {
  return {
    MuiPaper: {
    } as Components['MuiPaper'],
  };
}
