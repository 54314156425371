//
import { Components } from '@mui/material';
import { CustomTheme } from '../CustomTheme';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Card from './Card';
import Paper from './Paper';
import Input from './Input';
import Table from './Table';
import Button from './Button';
import Tooltip from './Tooltip';
import Backdrop from './Backdrop';
import Typography from './Typography';
import Autocomplete from './Autocomplete';
import Link from './Link';
import ListItem from './ListItem';
import Toolbar from './Toolbar';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: CustomTheme): Components {
  return Object.assign(
    AppBar(theme),
    Card(theme),
    Table(theme),
    Input(theme),
    ListItem(theme),
    Link(theme),
    Paper(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    Autocomplete(theme),
    Drawer(theme),
    Toolbar(theme),
  );
}
