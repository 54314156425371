import { useState } from 'react'
import { styled, Button, Stack, SvgIcon, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import PaxListDialogGrid from './PaxListDialogGrid';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <SvgIcon component={CrossIcon} inheritViewBox />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface IPaxListDialogProps {
    airline?: string;
    flightNo?: string;
    flightDate?: string;
    dep?: string;
    dest?: string;
    cancelAction: () => void;
}

export function PaxListDialog(props: IPaxListDialogProps) {
    const [filterTerm, setFilterTerm] = useState('');
    const [gridApi, setGridApi] = useState<GridApiPremium | undefined>(undefined);

    const handleClose = () => {
        props.cancelAction();
    }

    const onExport = () => {
        if(gridApi !== undefined)
            gridApi.exportDataAsCsv({
                fileName: `${props.flightDate}_${props.airline}${props.flightNo}_${props.dep}_${props.dest}-PassengerList`
            })
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                maxWidth='lg'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Stack>
                        <p>Passenger List</p>
                        <Stack direction='row' useFlexGap flexWrap="wrap" spacing={1}>
                            <TextField size='small' id="filterTerm" label="Filter" variant="outlined" onChange={(e) => setFilterTerm(e.target.value)} />
                            <Button variant="contained" onClick={(e) => onExport()}>Export</Button>
                        </Stack>

                    </Stack>

                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <PaxListDialogGrid filterTerm={filterTerm} airline={props.airline} flightNo={props.flightNo} flightDate={props.flightDate} dep={props.dep} dest={props.dest} exportApi={(api) => setGridApi(api)} />
                </DialogContent>
                <DialogActions>
                    {/* <FormGroup>
                        <FormControlLabel control={<Checkbox value={displayAllFlights} onChange={() => setDisplayAllFlights(!displayAllFlights)} />} label="All flights" />
                    </FormGroup> */}
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default PaxListDialog;