import { Paper, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import DemoChart from './DemoChart';
import { CustomTheme } from '../../theme/CustomTheme';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import PageHeader from '../../common/Components/PageHeader';
import CateringRouteGrid, { StatusFilter } from '../CateringRoutes/CateringRouteGrid';

function Dashboard() {
    const { t } = useTranslation();
    const theme = useTheme<CustomTheme>();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(), dayjs().add(20, 'day')]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Dashboard')}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                </PageHeader>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="IDPS" subtitle="Synchronized Flights" height={150} color={theme.palette.info.main} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="Newskies" subtitle="Synchronized Meals" height={150} color={theme.palette.info.main} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="Errors" height={150} color={theme.palette.error.main} />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <CateringRouteGrid showIncompleteRoutes={true} dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} statusFilter={StatusFilter.Incompleted} />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Dashboard;