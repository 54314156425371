import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export interface ILoadingPlanPerDestCreateDialog {
    open : boolean;
    airlineList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (airline : string, dest : string) => void;
}

function LoadingPlanPerDestCreateDialog(props : ILoadingPlanPerDestCreateDialog) {
    const { t } = useTranslation();
    
    const [ airlineCode, setAirlineCode] = useState('');
    const [ dest, setDest] = useState('');
    
    const [ airlineCodeError, setAirlineCodeError] = useState(true);
    const [ destError, setDestError] = useState(true);

    const hasErrors = useMemo(() => {
        return airlineCodeError || destError;
    }, [airlineCodeError, destError]);

    const handleAirlineCode = (value : string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }  

    const handleDest = (value : string | undefined) => {
        setDest(value ?? '');
        setDestError(value === undefined || value === '');
    }   

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create BoB Forecast</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are going to create a new route to maintain BoB forecast entries.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={airlineCode}
                            error={airlineCodeError}
                            label="Type"
                            onChange={(e) => handleAirlineCode(e.target.value) }>
                                { props.airlineList && props.airlineList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Destination')}
                                    onChange={e => handleDest(e.target.value)}
                                    value={dest}
                                    error={destError}
                                    helperText={destError ? 'Please destination station' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, dest) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default LoadingPlanPerDestCreateDialog;