import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import ThemeProvider from './theme/ThemeProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from './Pages/Routes'
import { LicenseInfo } from '@mui/x-license-pro';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
LicenseInfo.setLicenseKey('85041b1fa7330d524b1df6372a649e02Tz02MDY1NSxFPTE3MDg4NjIzODQwODksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider>
    <ToastContainer />
    <RouterProvider router={router} />
    
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
