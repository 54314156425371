import { API } from 'aws-amplify';
import { useEffect, useRef, useReducer, useCallback } from 'react';
import { useAuth } from './useAuth';

enum ActionTypes {
	Fetching = 'FETCHING',
	Fetched = 'FETCHED',
	Error = 'ERROR',
}

export enum Status {
	Idle = 'idle',
	Fetching = 'fetching',
	Fetched = 'fetched',
	Error = 'error',
}

type FetchState<T> = {
	status: Status;
	error?: string;
	data?: T;
};

type Action<T> = { type: ActionTypes.Fetching } | { type: ActionTypes.Fetched, payload: T } | { type: ActionTypes.Error, payload: string };

export const useFetch = <T,>(url: string, resolver: (response: any) => T = (response) => response.Items, refreshCounter = 0, disableCache = false): { status: Status, data?: T, error?: any } => {
	const cache = useRef<{ [key: string]: {data: T, counter: number} }>({});
	const auth = useAuth();

	const initialState: FetchState<T> = {
		status: Status.Idle,
	};

	const [state, dispatch] = useReducer((state: FetchState<T>, action: Action<T>): FetchState<T> => {
		switch (action.type) {
			case ActionTypes.Fetching:
				return { ...initialState, status: Status.Fetching };
			case ActionTypes.Fetched:
				return { ...initialState, status: Status.Fetched, data: action.payload, error: undefined };
			case ActionTypes.Error:
				return { ...initialState, status: Status.Error, error: action.payload, data: undefined };
			default:
				return state;
		}
	}, initialState);

	const fetchData = useCallback(async (isCancelled: () => boolean) => {
		dispatch({ type: ActionTypes.Fetching });
		if (cache.current[url] && cache.current[url].counter === refreshCounter && !disableCache) {
			const data = cache.current[url];
			dispatch({ type: ActionTypes.Fetched, payload: data.data });
		} else {
			try {
				if (isCancelled()) return;
				const response = await API.get('api', url, {});
				const data = resolver(response)
				console.log(`successfully fetched data from ${url}:`, data); //TODO: remove later
				cache.current[url] = {data: data, counter: refreshCounter};
				if (!isCancelled()) dispatch({ type: ActionTypes.Fetched, payload: data });
			} catch (error: any) {
				console.log(error.message, error)
				if (!isCancelled()) dispatch({ type: ActionTypes.Error, payload: error.message });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [url, refreshCounter])

	useEffect(() => {
		let cancelRequest = false;
		if (!url?.trim() || !auth.isAuthenticated || cancelRequest) return;

		fetchData(() => cancelRequest);

		return () => {
			cancelRequest = true;
		};
	}, [url, auth.isAuthenticated, fetchData, refreshCounter]);

	return state;
};



export const useFetchAsync = <T,>(url: string, resolver: (response: any) => T = (response) => response.Items): {state: { status: Status, data?: T, error?: any }, executor: () => void} => {
	const cache = useRef<{ [key: string]: T }>({});
	const auth = useAuth();

	const initialState: FetchState<T> = {
		status: Status.Idle,
	};

	const [state, dispatch] = useReducer((state: FetchState<T>, action: Action<T>): FetchState<T> => {
		switch (action.type) {
			case ActionTypes.Fetching:
				return { ...initialState, status: Status.Fetching };
			case ActionTypes.Fetched:
				return { ...initialState, status: Status.Fetched, data: action.payload, error: undefined };
			case ActionTypes.Error:
				return { ...initialState, status: Status.Error, error: action.payload, data: undefined };
			default:
				return state;
		}
	}, initialState);

	const fetchData = useCallback(async (isCancelled: () => boolean) => {
		dispatch({ type: ActionTypes.Fetching });
		if (cache.current[url]) {
			const data = cache.current[url];
			dispatch({ type: ActionTypes.Fetched, payload: data });
		} else {
			try {
				if (isCancelled()) return;
				const response = await API.get('api', url, {});
				const data = resolver(response)
				console.log(`successfully fetched data from ${url}:`, data); //TODO: remove later
				cache.current[url] = data;
				if (!isCancelled()) dispatch({ type: ActionTypes.Fetched, payload: data });
			} catch (error: any) {
				console.log(error.message, error)
				if (!isCancelled()) dispatch({ type: ActionTypes.Error, payload: error.message });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [url])

	//useEffect(() => {
		const executor = () => {
		let cancelRequest = false;
		if (!url?.trim() || !auth.isAuthenticated || cancelRequest) return;

		fetchData(() => cancelRequest);

		return () => {
			cancelRequest = true;
		};
	}
	//}, [url, auth.isAuthenticated, fetchData]);

	return { state, executor }
};