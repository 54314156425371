import { useState, useMemo, useCallback } from 'react';
import { API } from 'aws-amplify';
import { Paper, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, } from '@mui/material';
// import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { AirlineFields, CatererFields, HaulTypeFields, LoadingPlanFields, RouteOverrideFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { toast } from "react-toastify";
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ulid } from 'ulid';
import RouteOverrideDetails from './RouteOverrideDetails';
import RouteOverrideCreateEntryDialog from './RouteOverrideCreateEntryDialog';

function RouteOverride() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<RouteOverrideFields & DynamoDbObject>>(`/api/masterdata/routeoverride/${clientCode}/list`);
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`); 
    const { data: catererData = [] } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer/${clientCode}`);
    const { data: haulTypeData = [] } = useFetch<Array<HaulTypeFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}`);
    const { data: loadingPlanData = [] } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${clientCode}`);
    // const { data: aircraftTypesData = [] } = useFetch<Array<string>>(`/api/masterdata/aircrafttype/${clientCode}`, o => o);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<RouteOverrideFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDetailsAnchor, setShowDetailsAnchor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentParent, setCurrentParent] = useState<any>(undefined);

    const rowId = (row : any) => {
        return `${row.PK}#${row.RK}`;
    }
    
    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const catererList = useMemo(() => {
        return catererData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [catererData])

    const haulTypeList = useMemo(() => {
        return haulTypeData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [haulTypeData])

    const loadingPlanList = useMemo(() => {
        return loadingPlanData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [loadingPlanData])

    const rows = useMemo(() => {
        if (!data) return [];
        const result = (data as any[]).reduce((a, b) => [
            ...a,
            {
                ...b,
                id: rowId(b),
                Al: b.Al,
                Route: b.Route,
                HaulType: `${b.Al}#${b.HaulType}`,
                LoadingPlanId: `${b.Al}#${b.LoadingPlanId}`,
                IsDutyFree: b.IsDutyFree,
                CatererId: b.CatererId,
                OutstationCatererId: b.OutstationCatererId
            }
        ], []);

        return result;
    }, [data])

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                //TODO
                await API.del('api', `/api/masterdata/routeoverride/${clientCode}/${deleteItem.RK.split('#')[1]}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Route Override configuration deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting Route Override`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [data]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [data]);

    const processRowUpdate = useCallback((row: GridRowModel) => {
        return row;
        // const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        // updateItem(row as AirlineFields & DynamoDbObject).then(success => {
        //     apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

        //     if(success) 
        //         toast.success(`Changes to airline saved successfully.`);
        //     else
        //         toast.error(`An error occurred while saving airline`);
        // })
        
        // return updatedRow;
        // eslint-disable-next-line
    }, [data]);

    const createOverrideEntry = (airline : string, route : string, loadingPlanId: string, haultype : string, isDutyFree: boolean, catererId: string, outstationCatererId: string | undefined) => {
        setShowCreateDialog(false);
        setLoading(true);

        const id = ulid()
        const item : RouteOverrideFields & DynamoDbObject = {
            PK: `${clientCode}#RouteOverride`,
            RK: `Route#${id}`,
            Al: airline,
            Route: route,
            HaulType: haultype,
            LoadingPlanId: loadingPlanId,
            ForceRoute: true,
            IsDutyFree: isDutyFree,
            CatererId: catererId,
            OutstationCatererId: outstationCatererId
        }

        updateRouteOverride(item).then((result : boolean) => {
            const routeParts = route.split('-')
            const caterer: {
                [CatererId: string]: {
                    LoadingTypes: string[]
                }
            } = {}
            caterer[catererId] = {LoadingTypes:["DELU", "COM", "ECO", "CREW", "NOM"]}
            if (outstationCatererId)
                caterer[outstationCatererId] = {LoadingTypes:[]}
            const promises: Promise<boolean>[] = []
            for (var i =1; i < routeParts.length; i++) {
                promises.push(updateRouteOverrideLeg({
                    PK: `${clientCode}#RouteOverride`,
                    RK: `Leg#${id}#${ulid()}`,
                    Dep: routeParts[i-1],
                    Dest: routeParts[i],
                    LoadingStation: routeParts[0],
                    Caterer: caterer
                })
                )
            }
            Promise.all(promises).then(results => {
                setLoading(false);

                if(result && results.every(x=>x)) {
                    (item as any).id = rowId(item);
                    (item as any).id = rowId(item);
                    (item as any).Al = airline;
                    (item as any).Route = route;
                    (item as any).HaulType = `${airline}#${haultype}`;
                    (item as any).LoadingPlanId = `${airline}#${loadingPlanId}`;
                    (item as any).IsDutyFree = isDutyFree;
                    (item as any).CatererId = catererId;
                    (item as any).OutstationCatererId = outstationCatererId;
                    apiRef.current.updateRows([item]);
                    toast.success('Route Override created successfully.');
                }
                else
                    toast.error('An error occurred while creating Route Override');
            })
        });
    }

    
    const updateRouteOverrideLeg = async (item : any) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            //TODO
            await API.put('api', `/api/masterdata/routeoverride/${clientCode}/${item.RK.split('#')[1]}/legs/${item.RK.split('#')[2]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const updateRouteOverride = async (item : any) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            //TODO
            await API.put('api', `/api/masterdata/routeoverride/${clientCode}/${item.RK.split('#')[1]}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Al', headerName: 'Airline', minWidth: 150, editable: false, type: 'singleSelect', valueOptions: airlineList },
        { field: 'Route', headerName: 'Route', minWidth: 80, editable: false },
        { field: 'HaulType', headerName: 'Haultype', minWidth: 70, editable: false, type: 'singleSelect', valueOptions: haulTypeList},
        { field: 'LoadingPlanId', headerName: 'Loading Plan', minWidth: 70, editable: false, type: 'singleSelect', valueOptions: loadingPlanList},
        { field: 'IsDutyFree', headerName: 'Duty Free', minWidth: 80, editable: false, type: 'boolean' },
        { field: 'CatererId', headerName: 'Caterer', minWidth: 80, editable: false, type: 'singleSelect', valueOptions: catererList },
        { field: 'OutstationCatererId', headerName: 'Outstation Caterer', minWidth: 80, editable: false, type: 'singleSelect', valueOptions: catererList },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                if(!isInEditMode) return [
                    <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowDetailsAnchor(true); }}>
                        <SvgIcon component={EditIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                    
                ]
                else
                    return ( 
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const getTreeDataPath = (row : any) => {
        return row.id.split('.');
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Route Override')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                    <DataGridPremium
                        apiRef={apiRef}
                        treeData
                        autoHeight 
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        getTreeDataPath={getTreeDataPath}
                        // getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                        getRowClassName={(params) => { return params.row.Parent === false ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
                        // pagination
                        loading={status === Status.Fetching || status === Status.Idle || loading}
                        isCellEditable={(params) => true }

                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        
                        groupingColDef={{
                            headerName: ' ',
                            valueGetter: (params: GridValueGetterParams) => '',
                            width: 50,
                            resizable: false,
                            hideable: false,                
                        }}
                    />
                    </StyledBox>
                    { showCreateDialog && (<RouteOverrideCreateEntryDialog open={showCreateDialog} airlineList={airlineList} catererList={catererList} haulTypeList={haulTypeList} loadingPlanList={loadingPlanList} cancelAction={() => setShowCreateDialog(false)} createAction={createOverrideEntry}/>)}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Override?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this Route Override? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
            { currentParent && <RouteOverrideDetails open={showDetailsAnchor} parent={currentParent} catererList={catererList} cancelAction={() => {setShowDetailsAnchor(false);}} />}
        </Grid>
    );
}

export default RouteOverride;