import { useCallback, useMemo, useState } from 'react'
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails, GridRenderCellParams, GridValueGetterParams, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { SvgIcon, IconButton, Stack, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, FormControl, Select, InputLabel, MenuItem, ListItemText, Checkbox, OutlinedInput } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { AirlineFields, CatererFields, HaulTypeFields, LoadingPlanFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { CateringRouteFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../../common/Components/StyledBox';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as CheckmarkIcon } from '../../../icons/checkmark.svg';

export interface IDateValueProps {
    date?: Date;
}

function DateValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short" }, }) :
                    null
            }
        </>
    )
}

export interface ITimeValueProps {
    date?: Date;
}

function TimeValue({ date }: ITimeValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric' }, }, }) :
                    null
            }
        </>
    )
}

export interface ICreateRouteDialogSummaryProps {
    clientCode: string
    airlineData: Array<AirlineFields & DynamoDbObject>;
    loadingPlanData: Array<LoadingPlanFields & DynamoDbObject>;
    haulTypesData: Array<HaulTypeFields & DynamoDbObject>;
    catererData: Array<CatererFields & DynamoDbObject>;
    serviceTypeData: Array<ServiceTypeFields & DynamoDbObject>;
    route: CateringRouteFields & DynamoDbObject;
    validationExecuted: (item: CateringRouteFields & DynamoDbObject, valid: boolean) => void;
}

export function CreateRouteDialogSummary(props: ICreateRouteDialogSummaryProps) {
    const apiRef = useGridApiRef();
    const [airline] = useState<string | undefined>((props.route as any).airline);
    const [currentRoute, setCurrentRoute] = useState<CateringRouteFields & DynamoDbObject>(props.route);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);

    const validate = useCallback((item : any) => {
        const requiredFields: (keyof CateringRouteFields)[] = ['DefaultCatererId', 'LoadingPlanId', 'HaulType', 'IsDutyFree', 'FlightDate'];
        return requiredFields.reduce((a, b) => {
            return a && item[b] !== undefined;
        }, true) && validateLegs(item);
    }, []);

    const updateField = useCallback((field: string, value: any) => {
        if (field === 'FlightDate' && value === 'Invalid Date')
            value = undefined;

        (currentRoute as any)[field] = value;
        const item = { ...currentRoute };
        setCurrentRoute(item)

        props.validationExecuted(item, validate(item));
    }, [currentRoute, props, validate]);

    const rows = useMemo(() => {
        const result =  currentRoute.Legs?.map(o => { return { ...o, id: `${o.Al}${o.Nr}#${o.Dep}#${o.Dest}`, FlightNumber: `${o.Al}${o.Nr}`, Route: `${o.Dep}-${o.Dest}`, LoadingTypes: o.Caterer![0].LoadingTypes ?? [], CatererId: o.Caterer![0].CatererId } }) ?? [];
        return result;
    }, [currentRoute.Legs])

    const catererList = useMemo(() => {
        return props.catererData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [props.catererData]);

    const haulTypeList = useMemo(() => {
        return props.haulTypesData.filter(o => o.RK.split('#')[0] === (currentRoute as any).airline).map(o => { return {label: o.Name ?? '', value: o.RK.split('#')[1] } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [props.haulTypesData, currentRoute]);

    const serviceTypeList = useMemo(() => {
        let result : any[] = [];

        const list = new Set(props.serviceTypeData.filter(t => { return t.RK.split('#')[0] === (currentRoute as any).airline}).map(t => t.Type === 'CREW' ? t.Type : t.Code));
        result = result.concat(...Array.from(list).map(o => { return { label: o, value: o }}).sort((a,b) => (a.label ?? '') < (b.label ?? '') ? -1 : 1));
        return result;
    }, [props.serviceTypeData, currentRoute]);

    const loadingStationList = useMemo(() => {
        const list = new Set(currentRoute.Legs?.map(o => o.Dep).concat(currentRoute.Legs?.map(o => o.Dest)));
        return Array.from(list).sort((a, b) => (a ?? '') < (b ?? '') ? -1 : 1).map(o => { return {label: o, value: o}});
    }, [currentRoute.Legs])

    const preProcessEditCellProps = async (params: GridPreProcessEditCellProps) => {
        const errorMessage = (params.props.value?.toString() ?? '') === '' ? 'Please provide some value' : '';
        return { ...params.props, error: errorMessage };
    };

    const validateLegs = (item : any) => {
        let result = true;

        item.Legs.forEach((o : CateringRouteLeg) => {
            result = result && o.Caterer![0].CatererId !== undefined && (o.Caterer![0].LoadingTypes?.length ?? 0) > 0 && (o.LoadingStations?.length ?? 0) > 0;
        });
        return result;
    }

    const columns: GridColDef[] = [

        { field: 'DepDate', headerName: 'Date', minWidth: 80, width:80, renderCell: (params: GridRenderCellParams<Date>) => <DateValue date={new Date(params.value!)} /> },
        { field: 'Time', headerName: 'Time', minWidth: 60, width: 60, valueGetter: (params: GridValueGetterParams) => params.row.DepDate, renderCell: (params: GridRenderCellParams<Date>) => <TimeValue date={new Date(params.value!)} /> },
        { field: 'FlightNumber', headerName: 'Flight No.', minWidth: 80, width:80 },
        { field: 'Route', headerName: 'Route', minWidth: 90, width:90 },
        { field: 'AcReg', headerName: 'Ac. Reg.', minWidth: 70, width:70},
        { field: 'AcType', headerName: 'Ac. Type', minWidth: 100, width:100 },
        { field: 'LoadingStations', headerName: 'Loading', minWidth: 100, width:100, editable: true, type:'singleSelect', valueOptions: loadingStationList, preProcessEditCellProps},
        { field: 'CatererId', headerName: 'Supplier', minWidth: 120, width:120, editable: true, type:'singleSelect', valueOptions: catererList },
        // { field: 'LoadingTypes', headerName: 'Loading Type', minWidth: 200, editable: true },
        { field: 'LoadingTypes', headerName: 'Services', minWidth: 200, width: 200,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {serviceTypeList.map((serviceType) => (
                            <MenuItem key={serviceType.value} value={serviceType.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list : string[] = [].concat(params.row?.LoadingTypes ?? []) ?? []; //params.row.LoadingTypes;
                                    const idx = list.indexOf(serviceType.value);

                                    if(idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    } else {
                                        list.push(serviceType.value);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={serviceType.value} />
                            </MenuItem>
                        ))}
                        </Select>
                )
            }, editable: true },
        { field: 'HaulType', headerName: 'Haul Type', minWidth: 50, editable: true, type:'singleSelect', valueOptions: haulTypeList },
        {
            field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if (isInEditMode)
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(row.id)}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (!isLoading)
                    if (row.Parent === true) return [
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    ]
                    else
                        return (
                            <IconButton color="error" onClick={() => confirmDeletion(row)}>
                                <SvgIcon component={TrashIcon} inheritViewBox />
                            </IconButton>
                        )
                else if (isLoading)
                    return (
                        <CircularProgress size={19} />
                    )
            }
        }
    ];

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, IsLoading: false };

        let item = currentRoute.Legs?.find(o => `${o.Al}${o.Nr}#${o.Dep}#${o.Dest}` === row.id);

        if(item !== undefined) {
            const route = currentRoute;
            item!.Caterer![0].CatererId = row.CatererId;
            item!.Caterer![0].LoadingTypes = row.LoadingTypes;
            item!.LoadingStations = row.LoadingStations;
            route.Legs = [...currentRoute.Legs!];
            setCurrentRoute(route);

            apiRef.current.updateRows([{ id: row.id, IsLoading: false }]);
            props.validationExecuted(currentRoute, validate(route));
        }
        
        return updatedRow;
        // eslint-disable-next-line
    }, [currentRoute.Legs]);

    const handleSaveClick = (item: any) => () => {
        setRowModesModel({ ...rowModesModel, [item.id ?? '']: { mode: GridRowModes.View } });
    };

    const confirmDeletion = (item: any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

    };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
    }, [currentRoute.Legs]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
        // eslint-disable-next-line
    }, [currentRoute.Legs]);

    const handleDeleteItem = () => {
        setShowDeleteConfirmation(false);
        currentRoute.Legs = currentRoute?.Legs?.filter(o => !(o.Al === deleteItem.Al && o.Nr === deleteItem.Nr && o.DepDate === deleteItem.DepDate && o.Dep === deleteItem.Dep && o.Dest === deleteItem.Dest));

    }

    const cellIsValid = useCallback((row : any, field : string) => {
        switch(field) {
            case 'LoadingStations':
            case 'LoadingTypes':
                return !(row[field] === undefined || (row[field].length ?? 0) === 0);
            
        }
        return true;
    }, []);

    return (
        <Stack direction='column' spacing={2} style={{ padding: 20, paddingTop: 0 }}>
            <Stack spacing={5} direction="row" sx={{ marginBottom: 4, marginTop: 1, paddingTop: 0 }}>
                <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel id="plan-type-label">Plan Type</InputLabel>
                    <Select
                        labelId="plan-type-label"
                        id="plan-type-select"
                        value={currentRoute.LoadingPlanId ?? ''}
                        label="Plan Type"
                        error={currentRoute.LoadingPlanId === undefined}
                        onChange={(e) => { updateField('LoadingPlanId', e.target.value) }}
                    >
                        {props.loadingPlanData.filter(type => type.RK.split('#')[0] === airline).sort((a, b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1).map((type) => (
                            <MenuItem key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>,
                <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="duty-free-label">Duty free</InputLabel>
                    <Select
                        labelId="duty-free-label"
                        id="duty-free-select"
                        value={currentRoute.IsDutyFree ?? false}
                        onChange={(e) => { updateField('IsDutyFree', e.target.value === 'true') }}
                        label="Duty free"
                        itemScope
                    >
                        <MenuItem key='false' value={'false'}>No duty free</MenuItem>
                        <MenuItem key='true' value={'true'}>Duty free</MenuItem>
                    </Select>
                </FormControl>,

                <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <InputLabel id="haul-type-label">Haul type</InputLabel>
                    <Select
                        labelId="haul-type-label"
                        id="haul-type-select"
                        value={currentRoute.HaulType ?? ''}
                        error={currentRoute.HaulType === undefined}
                        onChange={(e) => { updateField('HaulType', e.target.value) }}
                        label="Haul Type"
                        itemScope
                    >
                        {props.haulTypesData.filter(type => type.RK.split('#')[0] === airline).sort((a, b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1).map((type) => (
                            <MenuItem key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <StyledBox>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode='row'
                    pagination
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : '' }}
                    getCellClassName={(params) => { return cellIsValid(params.row, params.field) ? '' : 'errorCell'}}
                    isCellEditable={() => true}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}

                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false) }>
                <DialogTitle id="alert-dialog-title">
                    {"Delete Flight?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirmation(false)} autoFocus>Cancel</Button>
                    <Button onClick={handleDeleteItem} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default CreateRouteDialogSummary;