import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { DataGridPremium, GridColDef, GridRowSelectionModel, useGridApiRef, GridRenderCellParams, GridValueGetterParams, GridRowId } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../../hooks/useFetch';
import { useMemo } from 'react';
import { DynamoDbObject } from '@aviation/catering-common';
import { Leg } from '@aviation/catering-flightservice-sdk';

export interface IDateValueProps {
    date?: Date;
}

function DateValue({ date }: IDateValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { dateStyle: "short" }, }) :
                    null
            }
        </>
    )
}

export interface ITimeValueProps {
    date?: Date;
}

function TimeValue({ date }: ITimeValueProps) {
    const { t } = useTranslation();
    return (
        <>
            {
                date ?
                    t('{{val, datetime}}', { val: date, formatParams: { val: { hour: 'numeric', minute: 'numeric', timeZoneName: "short" }, }, }) :
                    null
            }
        </>
    )
}

export interface IFindFlightDialogGridProps {
    dateFrom:string;
    dateTo:string;
    filterTerm?:string;
    airlineList?:string[];
    initialSelection?:Leg[];
    selectionChanged : (list : Leg[]) => void;
}

function FindFlightDialogGrid(props : IFindFlightDialogGridProps) {
    const apiRef = useGridApiRef();

    // Build query parameters based on airlines defined for this client
    const airlineFilter = useMemo(() => {
        if((props.airlineList?.length ?? 0) === 0)
            return '';

        return props.airlineList!.reduce((filter, airline) => {
            if((filter?.length ?? 0) === 0)
                return `?airline=${airline}`;
            
            return `${filter}&airline=${airline}`;
        }, '');
    }, [props.airlineList]);

    const { status, data = [] } = useFetch<Array<Leg & DynamoDbObject>>(`/api/flights/from/${props.dateFrom}/to/${props.dateTo ?? props.dateFrom}${airlineFilter}`, (response) => response);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    
    const columns: GridColDef[] = [
        { field: 'FlightNo', headerName: 'Flight No.', minWidth: 100 },
        // { field: 'DepDate', headerName: 'Dep. Date', minWidth: 170 },
        { field: 'DepDate', headerName: 'Date', minWidth: 100, renderCell: (params: GridRenderCellParams<Date>) => <DateValue date={new Date(params.value!)} /> },
        { field: 'Time', headerName: 'Time', minWidth: 140, valueGetter: (params: GridValueGetterParams) => params.row.DepDate, renderCell: (params: GridRenderCellParams<Date>) => <TimeValue date={new Date(params.value!)} /> },
        { field: 'AcReg', headerName: 'Aircraft', minWidth: 50 },
        { field: 'Dep', headerName: 'Dep.', minWidth: 100 },
        { field: 'Dest', headerName: 'Dest.', minWidth: 100 },
    ];

    const filterRow = useCallback((flight : Leg & DynamoDbObject) => {
        if((props.filterTerm?.trim()?.length ?? 0) === 0)
            return true;

        let result = false;
        result = result || `${flight.Al}${flight.No}`.startsWith(props.filterTerm!.toUpperCase());
        result = result || (flight.AcReg?.startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (flight.Dep?.startsWith(props.filterTerm!.toUpperCase()) ?? false);
        result = result || (flight.Dest?.startsWith(props.filterTerm!.toUpperCase()) ?? false);
        return result;
    }, [props.filterTerm])

    const rows = useMemo(() => {
        if (!data ) return [];
        
        return data.filter(l => filterRow(l)).map(o => { return { ...o, id: o.PK, FlightNo: `${o.Al}${o.No}` }}).sort((a, b) => (a.DepDate ?? new Date()) < (b.DepDate ?? new Date()) ? -1 : 1);
    }, [data, filterRow])

    useEffect(() => {
        if(props.initialSelection !== undefined) {
            const selected : GridRowId[] = props.initialSelection.map(o => `FLIGHT#${o.FlightDate ?? o.DepDate?.substring(0,10) ?? ''}#${o.Al}${o.No ?? (o as any).Nr}#${o.Dep}#${o.Dest}` );
            setRowSelectionModel(selected);
        }
    }, [props.initialSelection, data])

    const handleSelectionChange = (model : GridRowSelectionModel) => {
        setRowSelectionModel(model);

        const list : Leg[] = [];

        model.forEach(o => {
            const flight = apiRef.current.getRow(o) as Leg;
            list.push(flight);
        })

        // Prevent firing events if data is not loaded because we get empty selection model
        if(data !== undefined && data.length > 0)
            props.selectionChanged(list);
    }

    return (

        <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
            checkboxSelection 
            disableRowSelectionOnClick
            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
            loading={status === Status.Fetching || status === Status.Idle}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                handleSelectionChange(newRowSelectionModel);
              }}
            rowSelectionModel={rowSelectionModel}
        />
    );
}

export default FindFlightDialogGrid;