import { BobFreshFoodType } from "./BobFreshFoodType";

export class BobFreshFoodFields {
    Type?: BobFreshFoodType = BobFreshFoodType.fixed;
    EntryId?: string = undefined;

    // Fields to store current amounts and type of product
    Amount?: number = 0;                     // Fixed or relative amount that came from BobConfiguration
    Extra?: number = 0;                      // Absolute amount of extra counts
    ProductCode?: string = undefined;       // Which product to load

    // Fields to store original values
    PercentAm?: number = undefined;
    PercentPm?: number = undefined;
    AmountFixed?: number = undefined;
    AircraftType?: string = undefined;
    ValidFrom?: string = undefined;
    ValidTo?:string = undefined;

    // Store current number of pax
    PaxCount?: number = 0;

    // Store calculated amount of product
    ProductCount?: number = 0;

    UpdatedOn?: string = undefined;
    InsertedOn?: string = undefined;

    //BNX#01.2023#
    GSI1PK?: string = undefined
    //30.01.2023
    GSI1RK?: string = undefined
}