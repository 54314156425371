export const HOME = '/:clientCode/:airline';
export const SIGN_IN = 'sign-in';
export const DASHBOARD = '';
export const CATERING_ROUTES = 'catering-routes';
export const CATERING_STATION_GROUPS = 'caterer-station-groups';
export const LOADING_PLANS = 'loading-plans';
export const LOADING_PLAN_PER_DEST = 'loading-plan-per-dest';
export const BOB_FORECAST = 'bob-forecast';
export const USER = 'user';
export const SUPPLIER = 'supplier';
export const AIRPORTS = 'airports';
export const AIRLINES = 'airlines';
export const AIRCRAFTS = 'aircrafts';
export const AIRCRAFTGROUPS = 'aircraftgroups';
export const HAUL_TYPES = 'haul-types';
export const SERVICE_TYPES = 'service-types';
export const SSR_CODES = 'ssr-codes';
export const AUDIT_LOG = 'audit-log';
export const CATERER_PER_LEG = 'caterer-per-leg';
export const ROUTE_OVERRIDE = 'route_override';