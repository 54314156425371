
import { Paper, Typography } from '@mui/material';

import Grid from '@mui/material/Grid';
import { useFetch } from '../../../hooks/useFetch';
import { DynamoDbObject } from '@aviation/catering-common';
import { CrewMealFields } from '@aviation/catering-crewmealservice-sdk'
import CrewMealContainer from './CrewMealContainer'
import CrewInfoContainer from './CrewInfoContainer';

export interface ICrewContainerProps {
    cateringRouteId: string
}
function CrewContainer(props: ICrewContainerProps) {
    
    // eslint-disable-next-line
    const { data } = useFetch<Array<CrewMealFields & DynamoDbObject>>(`/api/crewmeals/route/${props.cateringRouteId}`, (response) => response);

    return (
            <Grid item xs={12} md={12} container spacing={3} alignItems='stretch'>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: '260px' }} elevation={3}>
                        <Grid item xs={12} sx={{ marginBottom: 2 }}>
                            <Typography variant="subtitle1" component="h2">
                                Crew Meals
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {data && (<CrewMealContainer data={data} cateringRouteId={props.cateringRouteId} />)}
                        </Grid>
                    </Paper>
                </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: '260px' }} elevation={3}>
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle1" component="h2">
                            Crew
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {data && (<CrewInfoContainer data={data} />)}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CrewContainer;