import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import { SvgIcon, IconButton, CircularProgress } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';

import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../../common/Components/StyledBox'
import { CrewMealFields, CrewMealEntry } from '@aviation/catering-crewmealservice-sdk'
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../../icons/checkmark.svg';
import { toast } from 'react-toastify';

export interface ICrewMealContainerProps {
    cateringRouteId : string;
    data : Array<CrewMealFields & DynamoDbObject>;
}
function CrewMealContainer(props : ICrewMealContainerProps) {
    const apiRef = useGridApiRef();
    
    // eslint-disable-next-line
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const columns: GridColDef[] = [
        { field: 'Leg', headerName: 'Leg', minWidth: 100, editable: false },
        { field: 'ServiceTypeName', headerName: 'Crew', minWidth: 70, editable: false },
        { field: 'Count', headerName: 'Count', minWidth: 70, editable: false },
        { field: 'ManualCount', headerName: 'Add', minWidth: 70, editable: true, type:'number' },
        { field: 'actions', type: 'actions', headerName: '', renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const rowId = (row : any) => {
        return row.id;
    }

    const aggregatedData = useMemo(() => {
        if (!props.data) return [];

        const result : any[] = (props.data as any[]).sort((a,b) => a.DepDate < b.DepDate ? -1 : 1).reduce((a, b) => [
            ...a,
            ...(Object.keys(b.Entries as any).map((entryKey : string) => {
                return {
                    PK: b.PK,
                    RK: b.RK,
                    id: `${b.PK}#${b.RK}.${entryKey}`,
                    Leg: `${b.Dep}-${b.Dest}`,
                    ServiceTypeName: b.Entries[entryKey].ServiceTypeName,
                    Count: b.Entries[entryKey].Count,
                    ManualCount: b.Entries[entryKey].ManualCount,
                    EntryKey: entryKey
                }
            }))
        ], [])

        return result;
    }, [props.data]);

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as CrewMealEntry & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to crew meal saved successfully.`);
            else
                toast.error(`An error occurred while saving crew meal`);
        })
        
        return updatedRow;
    };

    const updateItem = async(item : CrewMealEntry & DynamoDbObject) => {
        const origin = props.data.find(o => o.PK === item.PK && o.RK === item.RK);

        if(origin === undefined) {
            return false;
        }

        origin.Entries[(item as any).EntryKey].ManualCount = item.ManualCount

        const init = {
            body: origin,
            headers: {}
        };

        try {
            await API.put('api', `/api/crewmeals/leg/${origin.FlightDate}/${origin.Al}/${origin.No}/${origin.Dep}/${origin.Dest}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }
    
    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    return (
        
            <StyledBox>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={aggregatedData}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                    isCellEditable={(params) => (params.row.Type ?? 0) === BobFreshFoodType.fixed && params.colDef.field === 'Extra' ? false : true }
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}

                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                />
        </StyledBox>
        
    )
}

export default CrewMealContainer;