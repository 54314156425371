import Grid from '@mui/material/Grid';
import { Box, Button, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import CateringRouteGrid from './CateringRouteGrid';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { Status, useFetchAsync } from '../../hooks/useFetch';
import { useLocation, useParams } from 'react-router-dom';
import 'dayjs/locale/de';
import CreateRouteDialog from './Components/CreateRouteDialog';


function CateringRoutes() {
    const { clientCode } = useParams();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(), dayjs().add(5, 'day')]);
    const { state, executor } = useFetchAsync<string>(`/api/cateringroutes/query/${clientCode}/from/${dateRange[0]?.format("YYYY-MM-DD")}/to/${dateRange[1]?.format("YYYY-MM-DD")}/export`, (res) => res) // TODO: handle status
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [ tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    
    const downloader = useCallback(()=>{
        if (state.status === Status.Fetched)
            //@ts-ignore
            window.location = state.data!
    }, [state])
    downloader()

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        let pageState = { tabIndex: newValue };
        localStorage.setItem(`PageState_${location.pathname}`, JSON.stringify(pageState));
        setTabIndex(newValue);
      };

    useEffect(() => {
        let stateJson = localStorage.getItem(`PageState_${location.pathname}`);
        
        if(stateJson !== undefined && stateJson !== null) {
            let pageState = JSON.parse(stateJson);

            if(pageState !== undefined) {
                setTabIndex(pageState.tabIndex);
            }
        }
    }, [location.pathname]);
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {/* <PageHeader title={t('Catering Routes')}>
                    <Stack direction='row' spacing={2}>
                        <Button variant="contained" onClick={() => setShowCreateDialog(true) }>Create Route</Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider>
                    </Stack>
                </PageHeader> */}
                <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                            Catering Routes
                        </Typography>
                        <Stack direction='row' spacing={2} style={{float:'right', marginTop:-20}}>
                            <Button variant="contained" onClick={() => setShowCreateDialog(true) }>Create Route</Button>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                <DateRangePicker value={dateRange} format='DD.MM.YYYY' onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                            </LocalizationProvider>
                        </Stack>
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}}>
                        
                    </div>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Completed" />
                        <Tab label="Incompleted" />
                        <Tab label="Ignored" />
                        <Tab label="All Routes" />
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <CateringRouteGrid dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} showIncompleteRoutes={false} statusFilter={tabIndex}/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton loading={state.status === Status.Fetching} variant="contained" onClick={()=>executor()}>Download</LoadingButton>
            </Grid>
            { showCreateDialog && <CreateRouteDialog clientCode={clientCode ?? 'BNX'} cancelAction={() => { setShowCreateDialog(false)}} createAction={() => {}} />}
        </Grid>
    );
}

export default CateringRoutes;