import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { CatererFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as UserIcon } from '../../icons/user-expert.svg';
import { ReactComponent as CalendarWeekIcon } from '../../icons/calendar-week.svg';
import {toast} from "react-toastify";
import { ulid } from 'ulidx';
import SupplierCreateDialog from './SupplierCreateDialog';
import dayjs from 'dayjs';
import SupplierUserList from './SupplierUserList';
import SupplierSubscriptionList from './SupplierSubscriptionList';

function Supplier() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<CatererFields[]>(`/api/masterdata/caterer/${clientCode}`);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<CatererFields & DynamoDbObject | undefined>(undefined);
    const [currentParent, setCurrentParent] = useState<CatererFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [showSubscriptionList, setShowSubscriptionList] = useState(false);
    const [loading, setLoading] = useState(false);

    const rowId = (row : any) : string => {
        return `${row.RK}`;
    }

    const rows = useMemo(() => {
        return data.map(o => { return {...o, id: rowId(o), SendDailyOn: o.SendDailyOn !== undefined ? dayjs(o.SendDailyOn) : undefined }}).sort((a,b) => (a.Name ?? '') < (b.Name ?? '') ? -1 : 1);
    }, [data])

    const columns: GridColDef[] = [
        { field: 'Name', headerName: 'Name', minWidth: 200, editable: true },
        // { field: 'EnableSubscription', headerName: 'Subscription', minWidth: 100, type: 'boolean', editable: true },
        // { field: 'SendDailyOn', headerName: 'Daily on', minWidth: 150, editable: true, renderEditCell(params) {
        //     return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        //         <TimePicker value={params.row.SendDailyOn} format="HH:mm" onChange={(value, c) => {
        //             const id = params.id;
        //             const field = params.field; 
        //             apiRef.current.setEditCellValue({id, field, value});
        //          }} />
        //     </LocalizationProvider>)
        // }, renderCell: ({ row }: Partial<GridRowParams>) => { 
        //     let date = row.SendDailyOn as Dayjs;

        //     if(date !== undefined)
        //         return date.format('HH:mm');// `${date.hour()}:${date.minute()}`;
        //     else
        //         return '';
        // }},
        
        { field: 'actions', type: 'actions', headerName: '', minWidth: 140, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return [
                    <Tooltip title="Disabled users">
                        <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowUserList(true); }}>
                            <SvgIcon component={UserIcon} inheritViewBox />
                        </IconButton>
                    </Tooltip>,
                    <Tooltip title="Schedules">
                        <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowSubscriptionList(true); }}>
                            <SvgIcon component={CalendarWeekIcon} inheritViewBox />
                        </IconButton>
                    </Tooltip>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/caterer/${clientCode}/${deleteItem.RK}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Supplier deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting supplier`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async(item : CatererFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/caterer/${clientCode}/${item.RK}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (name : string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : CatererFields & DynamoDbObject = {
            Name: name,
            PK: `${clientCode}#Caterer`,
            RK: ulid().toString()
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
       
        if(await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Supplier added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating supplier`);
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as CatererFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to airport saved successfully.`);
            else
                toast.error(`An error occurred while saving supplier`);
        })
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <PageHeader title={t('Supplier')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            isCellEditable={() => true }
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    <SupplierCreateDialog open={showCreateDialog} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} />
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Supplier?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this supplier? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>

            { currentParent && <SupplierUserList open={showUserList} parent={currentParent} cancelAction={() => {setShowUserList(false);}} />}
            { currentParent && <SupplierSubscriptionList open={showSubscriptionList} parent={currentParent} clientCode={clientCode ?? ''} cancelAction={() => {setShowSubscriptionList(false);}} />}
        </Grid>
    );
}

export default Supplier;