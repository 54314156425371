import { useState, useMemo } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Stack, Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export interface ILoadingsCreateDialog {
    clientCode : string;
    airline : string;
    open : boolean;
    cancelAction : () => void;
    createAction : (ssrCode : string, serviceType : string) => void;
}

function LoadingsCreateDialog(props : ILoadingsCreateDialog) {
    const { data } = useFetch<{ [key: string]: any }>(`/api/masterdata/servicetype/${props.clientCode}/${props.airline}`, (response) => response);
    const { data: ssrCodeData } = useFetch<{ [key: string]: any }>(`/api/masterdata/ssrcode/${props.clientCode}`, (response) => response);
    const [ ssrCode, setSsrCode] = useState('');
    const [ serviceType, setServiceType] = useState('');

    const filteredSsrCodes = useMemo(() => {
        if(ssrCodeData !== undefined) {
            var list = (ssrCodeData.Items as any[]).filter((i) => { return i.IncludeInCsv === true }).map((o) => {
                return {
                    RK: o.RK,
                    Code: o.RK
                }
            });

            list.sort((a, b) => { return a.Code < b.Code ? -1 : 1});
            return list;
        } 
        return [];
    }, [ssrCodeData]);

    const filteredServiceTypes = useMemo(() => {
        if(data !== undefined) {
            return (data.Items as any[]).filter((o) => { return o.Type === 'SSR' });
        } 
        return [];
    }, [data]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Loading</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select a SSR code and a service type to create a new loading. 
                </DialogContentText>
                <Box>
                <Stack spacing={5} direction="row" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="ssrcode-label">SSR Code</InputLabel>
                        <Select
                                            labelId="ssrcode-label"
                                            id="ssrcode-select"
                                            value={ssrCode}
                                            label="SSR Code"
                                            onChange={(e) => { setSsrCode(e.target.value)}}
                                        >
                                            { filteredSsrCodes && (filteredSsrCodes as any[]).map((type)=> (
                                                <MenuItem  key={type.Code} value={type.Code}>{type.Code}</MenuItem>
                                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="servicetype-label">Service Type</InputLabel>
                        <Select
                                            labelId="servicetype-label"
                                            id="servicetype-select"
                                            value={serviceType}
                                            label="Plan Type"
                                            onChange={(e) => { setServiceType(e.target.value)}}
                                        >
                                            { filteredServiceTypes.map((type)=> (
                                                <MenuItem  key={type.Code} value={type.Code}>{type.Code}</MenuItem>
                                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button onClick={() => props.createAction(ssrCode, serviceType) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default LoadingsCreateDialog;