import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next';

export interface ICreateAirlineDialog {
    open : boolean;
    cancelAction : () => void;
    createAction : (airlineCode : string, airlineName : string, morningEnd : string) => void;
}

function AirlineCreateDialog(props : ICreateAirlineDialog) {
    const { t } = useTranslation();
    
    const [ airlineCode, setAirlineCode] = useState('');
    const [ airlineName, setAirlineName] = useState('');
    
    const [morningEnd, setMorningEnd] = useState<Dayjs | null>(null);

    const [ airlineCodeError, setAirlineCodeError] = useState(true);
    const [ airlineNameError, setAirlineNameError] = useState(true);
    const [ morningEndError, setMorningEndError] = useState(true);
    

    const handleAirlineCode = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setAirlineCode(value);
        setAirlineCodeError(value === undefined || value === '');
    }

    const handleAirlineName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setAirlineName(value ?? '');
        setAirlineNameError(value === undefined || value === '');
    }

    const handleMorningEnd = (e : Dayjs) => {
        const value = e !== undefined && e !== null ? e.format('HH:mm') : undefined;
        setMorningEnd(e);
        setMorningEndError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return airlineCodeError || airlineNameError || morningEndError;
    }, [airlineCodeError, airlineNameError, morningEndError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Airline</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new airline.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Airline Code')}
                                        onChange={e => handleAirlineCode(e)}
                                        value={airlineCode}
                                        error={airlineCodeError}
                                        helperText={airlineCodeError ? 'Please enter a code' : ''}
                                        fullWidth
                                        size="small"
                            />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Name')}
                                    onChange={e => handleAirlineName(e)}
                                    value={airlineName}
                                    error={airlineNameError}
                                    helperText={airlineNameError ? 'Please enter a name' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimeField
                                label="Morning End"
                                value={morningEnd}
                                onChange={(e : any) => handleMorningEnd(e)}
                                format="HH:mm"
                                
                                />
                        </LocalizationProvider>
                    </FormControl>  
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, airlineName, morningEnd?.format('HH:mm') ?? '09:00') }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default AirlineCreateDialog;