import { Chip } from "@mui/material";
import { useMemo } from "react";
import { WARNING_ROUTEIGNORED } from "../../../common/constants";

export interface IWarningChipProps {
    warnings? : string[];
}

function WarningChip(props : IWarningChipProps) {
    const hasWarning = useMemo(() => {
        return (props.warnings?.length ?? 0) > 0;
    }, [props.warnings]);

    const isComplete = useMemo(() => {
       return (props.warnings?.length ?? 0) === 0
    }, [props.warnings]);

    const isIgnored = useMemo(() => {
        return props.warnings?.some(o => o === WARNING_ROUTEIGNORED) ?? false
    }, [props.warnings]);

    return (
        <div>
            { hasWarning && !isIgnored && <Chip size='small' key='incomplete' label='Incomplete' color="error" /> }
            { isIgnored && <Chip size='small' label={'Ignored'} color="warning" /> }
            { isComplete && <Chip size='small' label={'Complete'} color="success" /> }
        </div>
        
    )
}

export default WarningChip;