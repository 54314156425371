import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { AirportFields, AirlineFields, CatererFields, CateringStationGroupFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import AirportCreateDialog from './AirportCreateDialog';
import {toast} from "react-toastify";

function Airports() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<AirportFields & DynamoDbObject>>(`/api/masterdata/airport/${clientCode}`);
    
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { status: catererStatus, data: catererData = [] } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer/${clientCode}`); //TODO: handle status
    const { data: cateringStationGroupsData } = useFetch<Array<CateringStationGroupFields & DynamoDbObject>>(`/api/masterdata/cateringstationgroup/${clientCode}`); //TODO: handle status
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`); //TODO: handle status
    
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<AirportFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const rowId = (row : any) : string => {
        return `${row.PK}#${row.RK}`;
    }
    
    const rows = useMemo(() => {
        return data.map(o => { return {...o, id: rowId(o), airline: o.RK.split('#')[0]}})
    }, [data]);

    const catererList = useMemo(() => {
        return catererData.map(o => { return {label: o.Name ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [catererData])

    const stationGroupList = useMemo(() => {
        return cateringStationGroupsData?.map(o => { return { label: o.Name ?? '', value: o.RK}}).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [cateringStationGroupsData])

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const columns: GridColDef[] = [
        { field: 'airline', headerName: 'Airline', minWidth: 150, editable: false, 
            type: 'singleSelect', valueOptions: airlineList },
        { field: 'RK', headerName: 'Airport', minWidth: 100, valueGetter: (params) => params.row.RK?.split('#')[1], editable: false },
        { field: 'DefaultCatererId', headerName: 'Default Caterer', minWidth: 150, editable: true, 
            type: 'singleSelect', valueOptions: catererList },
        { field: 'CateringStationGroupId', headerName: 'Station Group', minWidth: 150, editable: true, 
            type: 'singleSelect', valueOptions: stationGroupList },
        { field: 'IsOutstation', headerName: 'Outstation', minWidth: 120, editable: true, type: 'boolean',
            valueSetter: (params) => {
                if(params.value === true) 
                    return {...params.row, CateringStationGroupId: undefined, IsOutstation: params.value};
                else
                    return {...params.row, IsOutstation: params.value};
            }},
        { field: 'Priority', headerName: 'Prio', type: 'number', minWidth: 60, editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as AirportFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to airport saved successfully.`);
            else
                toast.error(`An error occurred while saving airport`);
        })
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };
    
    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/airport/${clientCode}/${deleteItem.RK.replace('#', '/')}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Airport deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting an airport`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async(item : AirportFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        const id = rowId(item);

        try {
            await API.put('api', `/api/masterdata/airport/${clientCode}/${item.RK.replace('#', '/')}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (airline : string, airportCode : string, catererId : string, stationGroupId : string | undefined, outstation : boolean, priority? : number) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : AirportFields & DynamoDbObject = {
            DefaultCatererId: catererId,
            CateringStationGroupId: stationGroupId,
            IsOutstation: outstation,
            Priority: priority,
            PK: `${clientCode}#Airport`,
            RK: `${airline}#${airportCode}`,
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
        (item as any).airline = airline;
       
        if(await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Airport added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating airport`);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Airports')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading || catererStatus === Status.Fetching}
                            isCellEditable={(params) => true }
                            
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    { catererList && stationGroupList && (
                        <AirportCreateDialog catererList={catererList} stationGroupList={stationGroupList} airlineList={airlineList} open={showCreateDialog} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem}/>
                    )}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Airport?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this airport? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Airports;