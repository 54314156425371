import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Box, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, ValueOptions, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { BoBPackagePlan, BobConfigurationFields, BobConfigurationFixedEntry } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { BobFreshFoodType } from '@aviation/catering-bobfreshfoodservice-sdk';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import { ulid } from 'ulidx';
import dayjs, { Dayjs } from 'dayjs';
import BobForecastPackagePlanCopyDialog from './BoBForecastPackagePlanCopyDialog';

export interface IBobForecastPackagePlanDetailsProps {
    open: boolean;
    parent: BoBPackagePlan & DynamoDbObject;
    productList: { label: string, value: string }[];
    aircraftTypeList: { label: string, value: string }[];
    aircraftGroupList: { label: string, value: string }[];
    cancelAction: () => void;
    updateAction: (type: BobFreshFoodType, productCode: string, amountFixed?: number, percentAm?: number, percentPm?: number, extra?: number) => void;
}

function BobForecastPackagePlanDetails(props: IBobForecastPackagePlanDetailsProps) {
    const apiRef = useGridApiRef();
    const { clientCode } = useParams();

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [copyDisabled, setCopyDisabled] = useState(true);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const rowId = (row: any) => {
        return `${row.EntryId}`;
    }

    const typeList = useMemo(() => {
        return [
            { label: 'fixed', value: 'fixed' },
            { label: 'relative', value: 'relative' },
        ] as ValueOptions[];
    }, []);

    const rows = useMemo(() => {
        if (props.parent === undefined || props.parent?.Entries === undefined)
            return [];

        const result = props.parent.Entries.map((entry: any) => {
            return {
                id: entry.EntryId,
                Type: entry.Type,
                EntryId: entry.EntryId,
                ProductCode: entry.ProductCode,
                PercentPm: entry.PercentPm,
                PercentAm: entry.PercentAm,
                PercentExtra: entry.PercentExtra,
                Count: entry.Count,
                AircraftType: entry.AircraftType?.split(';') ?? [],
                AcGrpId: entry.AcGrpId?.split(';') ?? [],
                ValidFrom: entry.ValidFrom !== undefined ? dayjs(entry.ValidFrom) : undefined,
                ValidTo: entry.ValidTo !== undefined ? dayjs(entry.ValidTo) : undefined,
                PK: props.parent.PK,
                RK: props.parent.RK,
                isNew: entry.isNew ?? false
            }
        });
        return result;
        // eslint-disable-next-line
    }, [props.parent, props.parent.Entries])

    const handleSaveClick = (item: any) => () => {
        setRowModesModel({ ...rowModesModel, [item.id ?? '']: { mode: GridRowModes.View } });
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item: any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row: any) => row.id === id);
        if (editedRow!.isNew) {
            const rowList = props.parent.Entries!.filter((o: any) => o.EntryId !== id)
            props.parent.Entries = rowList;
        }
    };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
    }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
        // eslint-disable-next-line
    }, [props.parent]);

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if (deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: true }]);

            // Construct BobConfigurationItem without deleted item
            let item = { ...props.parent };
            item.Entries = props.parent.Entries!.filter(o => o.EntryId !== deleteItem.EntryId);


            if (await updateConfiguration(item)) {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, _action: 'delete' }]);
                toast.success(`BoB Forecast deleted successfully.`);
                props.parent.Entries = item.Entries;
                return true;
            } else {
                apiRef.current.updateRows([{ id: deleteItem.EntryId, IsLoading: false }]);
                toast.error(`An error occurred while deleting BoB forecast`);
            }
        }
    };

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem(row as BobConfigurationFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if (success)
                toast.success(`Changes to BoB forecast saved successfully.`);
            else
                toast.error(`An error occurred while saving BoB forecast`);
        })

        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent]);

    const updateItem = async (item: any) => {
        let entry = { ...props.parent.Entries!.find((o: any) => o.EntryId === item.EntryId) as any };

        if (entry === undefined) {
            entry = {
                EntryId: item.EntryId,
                ProductCode: item.ProductCode,
                Count: item.Count,
                PercentAm: item.PercentAm,
                PercentPm: item.PercentPm,
                PercentExtra: item.PercentExtra,
                AircraftType: undefined,
                ValidFrom: item.ValidFrom,
                ValidTo: item.ValidTo,
            }
        }

        entry.Type = item.Type;
        entry.Count = item.Count;
        entry.ProductCode = item.ProductCode;
        entry.PercentAm = item.PercentAm;
        entry.PercentPm = item.PercentPm;
        entry.PercentExtra = item.PercentExtra;
        entry.AircraftType = (item.AircraftType as string[])?.join(';');
        entry.AcGrpId = (item.AcGrpId as string[])?.join(';');
        entry.ValidFrom = item.ValidFrom;
        entry.ValidTo = item.ValidTo;
        entry.isNew = false;

        props.parent.Entries = [entry!, ...props.parent.Entries!.filter((o: any) => o.EntryId !== (item as any).EntryId)];
        return await updateConfiguration(props.parent);
    }

    const updateConfiguration = async (item: BobConfigurationFields) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/bobpackageplan/${clientCode}/${(props.parent as any).Id}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const columns: GridColDef[] = [
        { field: 'Type', headerName: 'Type', minWidth: 70, editable: true, type: 'singleSelect', valueOptions: typeList },
        { field: 'ProductCode', headerName: 'Product', minWidth: 60, editable: true, type: 'singleSelect', valueOptions: props.productList },
        { field: 'PercentAm', headerName: '% AM', minWidth: 60, type: 'number', editable: true },
        { field: 'PercentPm', headerName: '% PM', minWidth: 60, type: 'number', editable: true },
        { field: 'PercentExtra', headerName: 'Extra', minWidth: 60, type: 'number', editable: true },
        { field: 'Count', headerName: 'Fix #', minWidth: 60, type: 'number', editable: true },
        {
            field: 'AircraftType', headerName: 'AC Type', minWidth: 150,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                    //MenuProps={MenuProps}
                    >
                        {props.aircraftTypeList.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                    if (e !== undefined) {
                                        const id = params.id;
                                        const field = params.field;

                                        const list: string[] = [].concat(params.row.AircraftType) ?? []; //params.row.LoadingTypes;
                                        const idx = list.indexOf(type.value);

                                        if (idx !== -1) {
                                            list.splice(idx, 1);
                                            apiRef.current.setEditCellValue({ id, field, value: list });
                                        } else {
                                            list.push(type.value);
                                            apiRef.current.setEditCellValue({ id, field, value: list });
                                        }

                                    }
                                }} />
                                <ListItemText primary={type.value} />
                            </MenuItem>
                        ))}
                    </Select>
                )
            }, editable: true
        },
        {
            field: 'AcGrpId', headerName: 'AC Group', minWidth: 150,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="ac-grp-checkbox-label"
                        id="ac-grp-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => {
                            let selectedLabels = props.aircraftGroupList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
                            //return (selected as any).join(', ')
                            return selectedLabels;
                        }}
                    //MenuProps={MenuProps}
                    >
                        {props.aircraftGroupList.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                    if (e !== undefined) {
                                        const id = params.id;
                                        const field = params.field;

                                        const list: string[] = [].concat(params.row.AcGrpId) ?? []; //params.row.LoadingTypes;
                                        const idx = list.indexOf(type.value);

                                        if (idx !== -1) {
                                            list.splice(idx, 1);
                                            apiRef.current.setEditCellValue({ id, field, value: list });
                                        } else {
                                            list.push(type.value);
                                            apiRef.current.setEditCellValue({ id, field, value: list });
                                        }

                                    }
                                }} />
                                <ListItemText primary={type.label} />
                            </MenuItem>
                        ))}
                    </Select>
                )
            }, editable: true, renderCell: (params) => {
                let selected = params.value ?? [];
                let selectedLabels = props.aircraftGroupList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
                return selectedLabels;
            }
        },
        { field: 'ValidFrom', headerName: 'Valid from', minWidth: 60, type: 'date', editable: true, valueFormatter: (params) => params.value === undefined ? undefined : dayjs(params.value).format('DD.MM.YYYY'), valueSetter(params) {
            // Selection is in local time, we have to treat it in UTC, because all flight times are in UTC,
            // so we convert it manually into a string. otherwise we get the previous day because of UTC vs. GMT.
            if(params.value !== 'Invalid Date' && params.value !== null && params.value !== undefined) {
                let value = dayjs(params.value);
                return {...params.row, ValidFrom: value.format('YYYY-MM-DD')};
            } else
                return {...params.row, ValidFrom: undefined };
        } },
        { field: 'ValidTo', headerName: 'Valid to', minWidth: 60, type: 'date', editable: true, valueFormatter: (params) => params.value === undefined ? undefined : dayjs(params.value).format('DD.MM.YYYY'), valueSetter(params) {
            // Selection is in local time, we have to treat it in UTC, because all flight times are in UTC,
            // so we convert it manually into a string. otherwise we get the previous day because of UTC vs. GMT.
            if(params.value !== 'Invalid Date' && params.value !== null && params.value !== undefined) {
                let value = dayjs(params.value);
                return {...params.row, ValidTo: value.format('YYYY-MM-DD')};
            } else
                return {...params.row, ValidTo: undefined};
        } },
        {
            field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if (isInEditMode)
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(row.id)}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (!isLoading)
                    if (row.Parent === true) return [
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    ]
                    else
                        return (
                            <IconButton color="error" onClick={() => confirmDeletion(row)}>
                                <SvgIcon component={TrashIcon} inheritViewBox />
                            </IconButton>
                        )
                else if (isLoading)
                    return (
                        <CircularProgress size={19} />
                    )
            }
        }
    ];

    const createItem = () => {
        const id = ulid().toString();
        const row = { id: id, EntryId: id, Type: 'fixed', ProductCode: '', PercentAm: undefined, PercentPm: undefined, Count: 0, isNew: true } as BobConfigurationFixedEntry;


        props.parent.Entries = [...props.parent.Entries!, row];
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'ProductCode' },
        }));
    };

    const handleRowSelectionModelChange = useCallback((rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const hasSelection = rowSelectionModel.length > 0;
        setCopyDisabled(!hasSelection);
        const selected = rowSelectionModel.map((i) => i.toString());
        setSelectedIds(selected);
    }, [])

    const copyItems = async (validFrom: Dayjs | undefined, validTo: Dayjs | undefined) => {
        setShowCopyDialog(false);
        let entries = [...props.parent.Entries ?? []];
        selectedIds.forEach(id => {
            let item = entries.find(o => rowId(o) === id);

            if (item !== undefined) {
                let newItem = { ...item };
                newItem.EntryId = ulid();
                newItem.ValidFrom = validFrom?.toISOString();
                newItem.ValidTo = validTo?.toISOString();
                entries.push(newItem);
            }
        })

        props.parent.Entries = entries;
        if(await updateConfiguration(props.parent)) {
            toast.success(`Entries copied successfully.`);
        } else
            toast.error(`An error occurred while copying entries`);
    }

    const bulkUpdateItems = async (validFrom: Dayjs | undefined, validTo: Dayjs | undefined) => {
        setShowCopyDialog(false);

        let entries = [...props.parent.Entries ?? []];
        selectedIds.forEach(id => {
            let item = entries.find(o => rowId(o) === id);

            if (item !== undefined) {
                item.ValidFrom = validFrom?.format('YYYY-MM-DD');
                item.ValidTo = validTo?.format('YYYY-MM-DD');
            }
        })

        props.parent.Entries = entries;
        if(await updateConfiguration(props.parent)) {
            toast.success(`Changes to entries saved successfully.`);
        } else
            toast.error(`An error occurred while saving multiple entries`);
    }

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction} style={{ maxWidth: '50%' }}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight: 20 }}>
                <BoxHeader title={'Packing Plan Items'}>
                    <Stack direction='row' spacing={1}>
                        <Button variant="contained" disabled={copyDisabled} onClick={(e) => setShowCopyDialog(true)}>Edit</Button>
                        <Button variant="contained" onClick={createItem}>Create</Button>
                    </Stack>

                </BoxHeader>
            </Box>
            <StyledBox style={{ paddingLeft: 20, paddingRight: 20 }}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : '' }}
                    // pagination
                    isCellEditable={(params) => true}
                    checkboxSelection={true}
                    rowSelection={true}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                <DialogTitle id="alert-dialog-title">
                    {"Delete Packing Plan?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete this BoB packing plan item? You can't undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                    <Button onClick={handleDeleteItem} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
            {showCopyDialog && (<BobForecastPackagePlanCopyDialog open={showCopyDialog} cancelAction={() => setShowCopyDialog(false)} copyAction={copyItems} updateAction={bulkUpdateItems} />)}
        </Drawer>
    )
}

export default BobForecastPackagePlanDetails;